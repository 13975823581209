import { PlanV2 } from '../../../entity/plan-v2';
import { ConvertedTwelveMonthFeeContent } from '../../PlanEditPage/components/ConvertedTwelveMonthFeeContent';
import { convertTwelveMonthFee } from '../../PlanEditPage/util/convertTwelveMonthFee';

function getPlanDetailInfo(isMoyoOnly: boolean, plan?: PlanV2) {
  if (isMoyoOnly) {
    const originalFee = plan?.meta.할인끝난후_금액;
    const discountFee = plan?.meta.fee;
    const discountPeriod = plan?.meta.할인적용_개월수;
    const isFreeDiscount = plan?.meta.할인적용_개월수 === 0;

    const convertedTwelveMonthFee = convertTwelveMonthFee({
      originalFee,
      discountFee,
      discountPeriod,
      isFreeDiscount,
    });

    return {
      subTitle:
        '자사몰에 있는 같은 스펙의 요금제보다 12개월 환산금액이 1,000원 이상 저렴해야 해요.',
      bottomContentArea: (
        <ConvertedTwelveMonthFeeContent
          variant="default"
          convertedTwelveMonthFee={convertedTwelveMonthFee}
        />
      ),
    };
  }
  return { subTitle: undefined, bottomContentArea: undefined };
}

/**
 * util이 많아지면 utils 폴더 하위에 섹션별로 파일을 나누어 관리되면 좋을 것 같습니다.
 * @description 요금제 상세 페이지에서 사용하는 props를 반환하는 함수를 반환합니다.
 */
export function createPlanDetailPageHelpers() {
  return {
    getPlanDetailInfo,
  };
}
