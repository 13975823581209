export const convertStringToRegisterType = (type?: string) => {
  switch (type) {
    case 'new':
      return '신규가입';
    case 'change':
      return '번호이동';
    default:
      return undefined;
  }
};

export const convertStringToMno = (mno?: string) => {
  switch (mno) {
    case 'SKT':
      return 'SKT';
    case 'KT':
      return 'KT';
    case 'LGU':
      return 'LG U+';
    case 'SKT-MVNO':
      return 'SKT';
    case 'KT-MVNO':
      return 'KT';
    case 'LGU-MVNO':
      return 'LG U+';
  }
};

export const convertStringToCertType = (type?: string) => {
  switch (type) {
    case 'naver':
      return '네이버';
    case 'kakao':
      return '카카오';
    case 'card':
      return '신용카드';
    case 'toss':
      return '토스';
    case 'kakaobank':
      return '카카오뱅크';
  }
};

export const convertStringToLicenseType = (type?: string) => {
  switch (type) {
    case 'registration':
      return '주민등록증';
    case 'driver':
      return '운전면허증';
  }
};

export const convertStringToPaymentNotiChannel = (type?: string) => {
  switch (type) {
    case 'sms':
      return '문자';
    case 'email':
      return '이메일';
    case 'mail':
      return '우편';
  }
};

export const convertStringToPaymentMethod = (type?: string) => {
  switch (type) {
    case 'account':
      return '계좌';
    case 'card':
      return '카드';
  }
};

export const convertStringToBillingMethod = (type?: string) => {
  switch (type) {
    case 'account':
      return '계좌 자동 이체';
    case 'card':
      return '카드';
    case 'giro':
      return '지로';
    case 'unknown':
      return '알 수 없음';
  }
};

export type Mvno = {
  label: string;
  value: string;
};

export const mvnoList: { [key: string]: Array<Mvno> } = {
  'SKT-MVNO': [
    { label: '아이즈모바일', value: '아이즈모바일' },
    { label: '모빙', value: '모빙' },
    { label: '이야기', value: '이야기' },
    { label: '스마텔', value: '스마텔' },
    { label: '스노우맨', value: '스노우맨' },
    { label: 'SK7모바일', value: 'SK7모바일' },
    { label: '이마트', value: '이마트' },
    { label: '티플러스', value: '티플러스' },
    { label: '안심모바일', value: '안심모바일' },
    { label: '프리티', value: '프리티' },
    { label: '헬로모바일', value: '헬로모바일' },
    { label: '에이모바일', value: '에이모바일' },
    { label: '조이텔', value: '조이텔' },
    { label: '토스모바일', value: '마이월드' },
    { label: '리브엠', value: '리브엠' },
    { label: 'KCT', value: 'KCT' },
    { label: '핀다이렉트', value: '핀다이렉트' },
  ],
  'LGU-MVNO': [
    { label: '프리티', value: '프리티' },
    { label: '토스모바일', value: '마이월드' },
    { label: '몬티스타텔레콤', value: '몬티스타텔레콤' },
    { label: '대성홀딩스', value: '대성홀딩스' },
    { label: 'U+유모바일', value: 'U+유모바일' },
    { label: '이마트', value: '이마트' },
    { label: '서경모바일', value: '서경모바일' },
    { label: '울산방송', value: '울산방송' },
    { label: '푸른방송', value: '푸른방송' },
    { label: '남인천방송', value: '남인천방송' },
    { label: '금강방송', value: '금강방송' },
    { label: 'KCTV', value: 'KCTV' },
    { label: 'KG모바일', value: 'KG모바일' },
    { label: '여유텔레콤', value: '여유텔레콤' },
    { label: '플래시모바일', value: '플래시모바일' },
    { label: '이지모바일', value: '이지모바일' },
    { label: '마블링', value: '마블링' },
    { label: '모빙', value: '모빙' },
    { label: '이야기', value: '이야기' },
    { label: '스마텔', value: '스마텔' },
    { label: '에이모바일', value: '에이모바일' },
    { label: '온국민폰', value: '온국민폰' },
    { label: 'KDDI', value: 'KDDI' },
    { label: '셀모바일', value: '셀모바일' },
    { label: '조이텔', value: '조이텔' },
    { label: '아이즈모바일', value: '아이즈모바일' },
    { label: '핀다이렉트', value: '핀다이렉트' },
    { label: '안심모바일', value: '안심모바일' },
    { label: '원텔레콤', value: '원텔레콤' },
    { label: '리브엠', value: '리브엠' },
    { label: '헬로모바일', value: '헬로모바일' },
    { label: '코인샷', value: '코인샷' },
    { label: '화인통신', value: '화인통신' },
    { label: '밸류컴', value: '밸류컴' },
    { label: '엔티온', value: '엔티온' },
    { label: '인스모바일', value: '인스모바일' },
    { label: '도시락모바일', value: '도시락모바일' },
    { label: '니즈모바일', value: '니즈모바일' },
    { label: '스노우맨', value: '스노우맨' },
    { label: '앤텔레콤', value: '앤텔레콤' },
    { label: '아시아모바일', value: '아시아모바일' },
    { label: '코나아이', value: '코나아이' },
    { label: '슈가모바일', value: '슈가모바일' },
    { label: '티플러스', value: '티플러스' },
    { label: '에르엘', value: '에르엘' },
    { label: 'Well', value: 'Well' },
    { label: '한패스모바일', value: '한패스모바일' },
    { label: '사람과연결', value: '사람과연결' },
    { label: 'KCT', value: 'KCT' },
    { label: '찬스모바일', value: '찬스모바일' },
  ],
  'KT-MVNO': [
    { label: '더블유', value: '더블유' },
    { label: '에이모바일', value: '에이모바일' },
    { label: '프리티', value: '프리티' },
    { label: '이지모바일', value: '이지모바일' },
    { label: '더블비', value: '더블비' },
    { label: '에스로밍', value: '에스로밍' },
    { label: 'Well', value: 'Well' },
    { label: '헬로모바일', value: '헬로모바일' },
    { label: '온세텔레콤', value: '온세텔레콤' },
    { label: '플래시모바일', value: '플래시모바일' },
    { label: '앤텔레콤', value: '앤텔레콤' },
    { label: '인스모바일', value: '인스모바일' },
    { label: '퍼스트모바일', value: '퍼스트모바일' },
    { label: '에르엘', value: '에르엘' },
    { label: '착한통신', value: '착한통신' },
    { label: '안심모바일', value: '안심모바일' },
    { label: '장성모바일', value: '장성모바일' },
    { label: '아이즈모바일', value: '아이즈모바일' },
    { label: '토스모바일', value: '마이월드' },
    { label: '제이씨티', value: '제이씨티' },
    { label: 'KT M모바일', value: 'KT M모바일' },
    { label: '모빙', value: '모빙' },
    { label: '쉐이크모바일', value: '쉐이크모바일' },
    { label: 'M2모바일', value: 'M2모바일' },
    { label: '아이플러스유', value: '아이플러스유' },
    { label: '핀다이렉트', value: '핀다이렉트' },
    { label: '파인디지털', value: '파인디지털' },
    { label: '드림모바일', value: '드림모바일' },
    { label: '티플러스', value: '티플러스' },
    { label: '이비카드', value: '이비카드' },
    { label: '여유텔레콤', value: '여유텔레콤' },
    { label: '이야기', value: '이야기' },
    { label: '이야기', value: '이야기' },
    { label: '오파스모바일', value: '오파스모바일' },
    { label: '니즈모바일', value: '니즈모바일' },
    { label: 'KT 스카이라이프', value: 'KT 스카이라이프' },
    { label: '아시아모바일', value: '아시아모바일' },
    { label: '밸류컴', value: '밸류컴' },
    { label: '스마텔', value: '스마텔' },
    { label: '리브엠', value: '리브엠' },
    { label: 'LG헬로비전KT', value: 'LG헬로비전KT' },
    { label: 'KCT', value: 'KCT' },
    { label: '고고팩토리', value: '고고모바일' },
  ],
};

type Bank = {
  inputLabel: string;
  value: string;
};

export const bankList: Array<Bank> = [
  { inputLabel: 'NH농협은행', value: 'NH농협' },
  { inputLabel: 'KB국민은행', value: 'KB국민' },
  { inputLabel: '기업은행', value: '기업' },
  { inputLabel: '우리은행', value: '우리' },
  { inputLabel: 'SC제일은행', value: 'SC제일' },
  { inputLabel: '우체국', value: '우체국' },
  { inputLabel: '하나은행', value: '하나' },
  { inputLabel: '신한은행', value: '신한' },
  { inputLabel: 'KDB산업은행', value: 'KDB산업' },
  { inputLabel: '케이뱅크', value: '케이뱅크' },
  { inputLabel: '카카오뱅크', value: '카카오뱅크' },
  { inputLabel: '수협은행', value: '수협' },
  { inputLabel: '씨티은행', value: '씨티' },
  { inputLabel: '대구은행', value: '대구' },
  { inputLabel: '부산은행', value: '부산' },
  { inputLabel: '광주은행', value: '광주' },
  { inputLabel: '제주은행', value: '제주' },
  { inputLabel: '전북은행', value: '전북' },
  { inputLabel: '경남은행', value: '경남' },
  { inputLabel: '새마을금고', value: '새마을' },
  { inputLabel: '신협', value: '신협' },
  { inputLabel: '저축은행', value: '저축은행' },
  { inputLabel: 'HSBC', value: 'HSBC' },
  { inputLabel: '도이치', value: '도이치' },
  { inputLabel: 'JP모간', value: 'JP모간' },
  { inputLabel: 'BOA', value: 'BOA' },
  { inputLabel: 'BNP파리바', value: 'BNP파리바' },
  { inputLabel: '중국공상', value: '중국공상' },
  { inputLabel: '산림조합', value: '산림조합' },
  { inputLabel: '중국건설', value: '중국건설' },
  { inputLabel: '토스뱅크', value: '토스뱅크' },
].sort((a, b): number => {
  return a.inputLabel > b.inputLabel ? 1 : -1;
});

export type MvnoUser = {
  value: string;
  name: string;
};
