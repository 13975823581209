import {
  Logout,
  LockReset,
  AdminPanelSettings,
  PersonOff,
  Password,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Button,
  ListItemIcon,
  Menu as MaterialMenu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import pbl from '../../pbl/pbl-service/pbl-service';
import { useRole } from '../../router/useRole';
import IssueOtpPage from '../../components/IssueOtpPage';

const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: inherit;
`;

const Menu = styled(MaterialMenu)(({ theme }) => ({
  '.MuiPaper-root': {
    filter: `drop-shadow(0px 2px 8px ${theme.palette.action.selected})`,
  },
}));

const MENU_PAPER_PROPS = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    marginLeft: 1,
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 22,
      left: -5,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

interface ProfileButtonProps {
  foldedNavigation?: boolean;
  newUpdateCount?: number;
}

const ProfileButton: FC<ProfileButtonProps> = ({
  foldedNavigation,
  newUpdateCount = 0,
}) => {
  const { user, logoutUser } = useAuth();
  const { isAdminAccount } = useRole();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onResetPassword = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'change_password',
        section: 'GNB',
      },
    });
    navigate('/change-password');
  };

  const onOpenMenuHandler = (event: MouseEvent<HTMLElement>) => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'account',
        section: 'GNB',
      },
    });
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenuHandler = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'signout',
        section: 'GNB',
      },
    });
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logoutUser();
      navigate('/login');
    }
  };

  const onClickOTP = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'otp',
        section: 'GNB',
      },
    });

    navigate('/otp');
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={onOpenMenuHandler}
        sx={{ minWidth: foldedNavigation ? '36px' : '64px' }}
      >
        <Badge
          badgeContent={foldedNavigation ? newUpdateCount : 0}
          color="secondary"
        >
          <Avatar
            sx={{
              width: 24,
              height: 24,
              marginRight: foldedNavigation ? 0 : 1,
            }}
          />
        </Badge>
        {!foldedNavigation && <Typography>{user?.username}</Typography>}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onCloseMenuHandler}
        onClick={onCloseMenuHandler}
        PaperProps={MENU_PAPER_PROPS}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {isAdminAccount && (
          <Link
            to="/settings/auth"
            onClick={() =>
              pbl('click', 'button', 'here', {
                object: {
                  id: 'authority',
                  section: 'GNB',
                },
              })
            }
          >
            <MenuItem>
              <ListItemIcon>
                <AdminPanelSettings fontSize="small" />
              </ListItemIcon>
              권한 설정
            </MenuItem>
          </Link>
        )}
        {isAdminAccount && (
          <Link
            to="/settings/account"
            onClick={() =>
              pbl('click', 'button', 'here', {
                object: {
                  id: 'account_create',
                  section: 'GNB',
                },
              })
            }
          >
            <MenuItem>
              <ListItemIcon>
                <AdminPanelSettings fontSize="small" />
              </ListItemIcon>
              계정 생성
            </MenuItem>
          </Link>
        )}
        {isAdminAccount && (
          <Link
            to="/settings/account/delete"
            onClick={() =>
              pbl('click', 'button', 'here', {
                object: {
                  id: 'account_delete',
                  section: 'GNB',
                },
              })
            }
          >
            <MenuItem>
              <ListItemIcon>
                <PersonOff fontSize="small" />
              </ListItemIcon>
              계정 삭제
            </MenuItem>
          </Link>
        )}
        <MenuItem onClick={onClickOTP}>
          <ListItemIcon>
            <Password fontSize="small" />
          </ListItemIcon>
          OTP 인증
        </MenuItem>
        <MenuItem onClick={onResetPassword}>
          <ListItemIcon>
            <LockReset fontSize="small" />
          </ListItemIcon>
          비밀번호 변경
        </MenuItem>
        <MenuItem onClick={onClickLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          로그아웃
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileButton;
