import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { BottomButtonRow } from '../styled';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { useModal } from '../../../hooks/useModal';
import DisplayPlanDialog from '../../PlanDetailPage/Dialog/DisplayPlanDialog';
import { useLoading } from '../../../context/LoadingContext';
import { ExposeConfirmDialog } from '../../PlanListPage/ExposeConfirmDialog';
import pbl from '../../../pbl/pbl-service/pbl-service';
import ImpressionLogTracker from '../../../pbl/impression-log-tracker/ImpressionLogTracker';

interface AddBottomButtonGroupProps {
  onOnlyRegister: () => void;
  onValidate: () => Promise<boolean>;
  onExpose: (date: DateTime | null) => void;
  onValidateBeforePlanAction?: () => Promise<boolean>;
}

export const AddBottomButtonGroup = ({
  onOnlyRegister,
  onValidate,
  onExpose,
  onValidateBeforePlanAction,
}: AddBottomButtonGroupProps) => {
  const { openModal, closeModal } = useModal();
  const { setLoading } = useLoading();
  const [openOnlyRegisterConfirm, setOpenOnlyRegisterConfirm] = useState(false);

  /** 요금제 등록만 하기 핸들러 */
  const handleOnlyRegister = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    if (!(await onValidate())) return;
    pbl('click', 'button', 'here', {
      object: {
        id: 'plan_expose_ready',
        name: '요금제 등록만 하기',
      },
    });
    setOpenOnlyRegisterConfirm(true);
  };

  const handleCancelOnlyRegister = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'plan_expose_ready_cancel',
        name: '취소',
      },
    });
    setOpenOnlyRegisterConfirm(false);
  };

  const handleReserve = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'plan_expose_reserve',
        name: '노출예약',
      },
    });
    openModal(
      <ExposeConfirmDialog
        open
        title="요금제 노출 예약"
        onClose={() => {
          pbl('click', 'modal_button', 'here', {
            object: {
              id: 'plan_expose_reserve_cancel',
              name: '취소',
            },
          });
          closeModal();
        }}
        objectId="plan_expose_reserve"
        onExpose={(date) => {
          closeModal();
          onExpose(date);
        }}
      />,
    );
  };

  /** 요금제 노출하기 핸들러 */
  const handleExpose = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    if (!(await onValidate())) return;
    pbl('click', 'button', 'here', {
      object: {
        id: 'plan_expose_live',
        name: '등록하고 노출하기',
      },
    });
    openModal(
      <DisplayPlanDialog
        open
        onReserve={handleReserve}
        onDisplay={async () => {
          try {
            setLoading(true);
            await onExpose(null);
            closeModal();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }}
        onClose={closeModal}
      />,
    );
    // setOpenExposeConfirm(true);

    // DisplayPlanDialog
  };

  const handleOnOnlyRegister = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    setOpenOnlyRegisterConfirm(false);
    // pbl onOnlyRegister에서 찍음
    onOnlyRegister();
  };

  return (
    <>
      <BottomButtonRow>
        <Button variant="outlined" size="large" onClick={handleOnlyRegister}>
          요금제 등록만 하기
        </Button>
        <Button variant="contained" size="large" onClick={handleExpose}>
          등록하고 노출하기
        </Button>
      </BottomButtonRow>

      {/* 요금제 등록만 하기 확인 다이얼로그 */}
      <Dialog
        open={openOnlyRegisterConfirm}
        onClose={handleCancelOnlyRegister}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ImpressionLogTracker
          logObjectType="modal"
          logOptions={{
            object: {
              id: 'plan_expose_ready',
            },
          }}
        >
          <>
            <DialogTitle id="alert-dialog-title">
              요금제를 등록할까요?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                스펙 정보는 요금제를 등록한 이후에는 수정할 수 없어
                <br />
                요. 입력한 값이 맞는지 꼼꼼히 확인해 주세요
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelOnlyRegister}>취소</Button>
              <Button
                onClick={handleOnOnlyRegister}
                color="primary"
                variant="contained"
              >
                등록하기
              </Button>
            </DialogActions>
          </>
        </ImpressionLogTracker>
      </Dialog>
    </>
  );
};
