import { FC, useEffect, useState } from 'react';
import { FormContent, Section, SectionHeader, SectionTitle } from '../styled';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ICreatePlanDto } from '../../../entity/plan';
import { PlanUnitPlanNoticeTemplateDto } from '../../../entity/planNotice';
import { planApiService } from '../../../api/plan';
import { MobilePlanOperatorId } from '../../../common/mobile-plan-operator-id';

interface Props {
  initialPlanUnitNoticeTemplateIds?: number[];
  mobilePlanOperatorId: MobilePlanOperatorId;
}

const PlanNoticeEditSection: FC<Props> = ({
  mobilePlanOperatorId,
  initialPlanUnitNoticeTemplateIds,
}) => {
  const { control, trigger } = useFormContext<ICreatePlanDto>();
  const [planUnitPlanNoticeTemplateList, setPlanUnitPlanNoticeTemplateList] =
    useState<PlanUnitPlanNoticeTemplateDto[]>();
  const canNotSelectTemplateList = !planUnitPlanNoticeTemplateList?.length;

  useEffect(() => {
    planApiService.getPlanUnitPlanNotices(mobilePlanOperatorId).then((res) => {
      setPlanUnitPlanNoticeTemplateList(res.data.result);
    });
  }, [mobilePlanOperatorId]);

  return (
    <Section>
      <SectionHeader mt={6}>
        <SectionTitle variant="h5">요금제 공지</SectionTitle>
        <Typography variant="body1">
          요금제 공지는 모요에서 검수한 내용만 게시 가능합니다. 새로운 공지가
          필요하다면, 모요 어드민에 요청해 주세요.
        </Typography>
      </SectionHeader>
      <FormContent>
        <FormControl>
          <Controller
            control={control}
            name={'planUnitNoticeTemplateIds'}
            rules={{
              validate: (value) => {
                if (!value) return true;
                if (value?.length > 3) {
                  return '요금제 공지는 최대 3개까지 선택할 수 있습니다.';
                }
              },
            }}
            defaultValue={initialPlanUnitNoticeTemplateIds ?? []}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel
                  id={`additional-call-label`}
                  shrink={canNotSelectTemplateList || undefined}
                >
                  요금제 공지 (선택)
                </InputLabel>
                <Select
                  labelId="additional-call-label"
                  {...field}
                  multiple
                  disabled={canNotSelectTemplateList}
                  onClose={() => trigger('planUnitNoticeTemplateIds')}
                  label="요금제 공지 (선택)"
                  error={!!error}
                  displayEmpty
                  notched={canNotSelectTemplateList || undefined}
                  renderValue={
                    canNotSelectTemplateList
                      ? () => '등록할 수 있는 공지가 없어요.'
                      : undefined
                  }
                >
                  {canNotSelectTemplateList && (
                    <MenuItem value="" disabled>
                      등록할 수 있는 공지가 없어요.
                    </MenuItem>
                  )}
                  {planUnitPlanNoticeTemplateList?.map(({ id, content }) => (
                    <MenuItem key={id} value={id}>
                      {content}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        </FormControl>
      </FormContent>
    </Section>
  );
};

export default PlanNoticeEditSection;
