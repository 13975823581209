import React, { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';

//icons
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';

import { Mvnos } from '../../entity/mvno';
import { ColumnList } from './PlanListColumn';
import { planApiService } from '../../api/plan';
import { useLoading } from '../../context/LoadingContext';
import { companyApiService } from '../../api/company';
import { PlanMetaAdmin, ScrapeReserve } from '../../entity/plan';
import { useDebounce } from '../../common/stream';
import Spinner from '../../modules/Spinner';

import pbl from '../../pbl/pbl-service/pbl-service';

import { useLocation, useNavigate } from 'react-router-dom';
import { isMoyoAdmin } from '../../common/moyoActivationCollection';
import { useOptions } from '../../context/OptionsContext';
import { useModal } from '../../hooks/useModal';
import { ExposureSettingModal } from './components/ExposureSettingModal';
import { PlanUpdateDialog } from './PlanUpdateDiaglog';
import PermissionRender from '../../shared/components/PermissionRender/PermissionRender';
import { useRole } from '../../router/useRole';
import ImpressionLogTracker from '../../pbl/impression-log-tracker/ImpressionLogTracker';
import { getPermissionsRenderPropsByMoyoOnly } from './utils/getPermissionsRenderPropsByMoyoOnly';

const emptyRow: PlanMetaAdmin[] = [];
const scrapes: ScrapeReserve[] = [];

interface PlanListPageProps {
  isMoyoOnly: boolean;
}

const PlanListPage = ({ isMoyoOnly }: PlanListPageProps) => {
  const navigate = useNavigate();
  const { isMoyoRole } = useRole();

  const { adminUser } = useOptions();

  const [plans, setPlans] = useState<PlanMetaAdmin[]>(emptyRow);
  const [filterText, setFilterText] = useState(''); // 요금제 이름
  const [filterIdText, setFilterIdText] = useState(''); // 요금제 ID
  const debouncedFilterText = useDebounce(filterText, 500);
  const debouncedFilterIdText = useDebounce(filterIdText, 500);
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [mvno, setMvno] = useState<string>('');
  const [mvnoFilter, setMvnoFilter] = useState<string>('전체');
  const [totalCount, setTotalCount] = useState<number>();
  const [state, setState] = useState('ALL');
  const [mno, setMno] = useState<string>('전체');
  const [display, setdisplay] = useState<boolean | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const planRegisterButtonText = isMoyoOnly ? '모요온리 등록' : '요금제 등록';
  const pageTitle = isMoyoOnly ? '모요온리 목록' : '요금제 목록';

  const { planList } = getPermissionsRenderPropsByMoyoOnly(isMoyoOnly);
  const { registerPlanIsRequireMoyoAdmin, registerPlanManagementMethodList } =
    planList;

  const { setLoading } = useLoading();

  const { openModal, closeModal } = useModal();

  const handleExposureSetting = () => {
    const selectedPlans = selectedIds
      .map((id) => {
        const plan = filteredPlansMap.get(id as number);
        if (!plan) return null;
        return plan;
      })
      .filter((i): i is PlanMetaAdmin => i !== null);

    openModal(
      <ExposureSettingModal
        open
        onSuccess={async () => {
          try {
            setLoading(true);
            await fetchPlans();
            closeModal();
          } catch (error) {
            throw error;
          } finally {
            setLoading(false);
          }
        }}
        onClose={closeModal}
        plans={selectedPlans}
      />,
    );
  };

  useEffect(() => {
    if (!initialized) return;
    pbl('pageview', 'none', 'plan_list', {
      eventProperties: {
        totalPlanCount: plans.length,
      },
    });
  }, [initialized]);

  useEffect(() => {
    if (!debouncedFilterText) return;
    pbl('search', 'keyword', 'here', {
      object: {
        id: 'search_plan_name',
        name: '요금제이름검색',
      },
      eventProperties: {
        searchPlanName: debouncedFilterText,
      },
    });
  }, [debouncedFilterText]);

  useEffect(() => {
    if (!debouncedFilterIdText) return;
    pbl('search', 'keyword', 'here', {
      object: {
        id: 'search_plan_id',
        name: '요금제ID검색',
      },
      eventProperties: {
        searchPlanId: debouncedFilterIdText,
      },
    });
  }, [debouncedFilterIdText]);

  const fetchPlans = async () => {
    setLoading(true);

    try {
      const companyInfoResponse = await companyApiService.getInfo();
      const companyInfo = companyInfoResponse.data.result;
      const authorizedPlans = companyInfo?.plans;
      if (!authorizedPlans) return;
      // mvno 에게만 스크래핑 요청 버튼 제공
      if (authorizedPlans.length === 1) {
        setMvno(authorizedPlans[0]);
      } else {
        setMvno('valuecomm');
      }

      let mnoParam: string | undefined = mno;
      if (mno === '전체') mnoParam = undefined;
      let mvnoNames;
      if (mvnoFilter === '전체') {
        mvnoNames = authorizedPlans.join(',');
      } else {
        mvnoNames = mvnoFilter;
      }

      const plans = await planApiService.getPlansByMvnoPagination({
        mvno: mvnoNames,
        searchText: debouncedFilterText,
        searchIdText: debouncedFilterIdText,
        state,
        mno: mnoParam,
        display,
        page: paginationModel.page,
        size: paginationModel.pageSize,
        isMoyoOnlyPlan: isMoyoOnly,
      });

      setTotalCount(plans.data.result?.totalSize);
      setInitialized(true);

      setLoading(false);
      if (!plans) return;
      if (plans.data.result) {
        setPlans(plans.data.result.list);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, [
    isMoyoOnly,
    mno,
    debouncedFilterText,
    debouncedFilterIdText,
    paginationModel,
    mvnoFilter,
    state,
    display,
  ]);

  const handleMvnoChange = (event: SelectChangeEvent) => {
    setMvnoFilter(event.target.value as string);
    pbl('click', 'filter', 'here', {
      object: {
        id: 'mvno_filter',
        section: 'top_button',
      },
      eventProperties: {
        mno: event.target.value,
      },
    });
  };

  const handleMnoChange = (event: SelectChangeEvent) => {
    setMno(event.target.value as string);
    pbl('click', 'filter', 'here', {
      object: {
        id: 'mno_filter',
        section: 'top_button',
      },
      eventProperties: {
        mno: event.target.value,
      },
    });
  };

  const handlePlanRegister = async () => {
    const registerUrl = isMoyoOnly ? '/plan-edit/moyo-only' : '/plan-edit';
    navigate(registerUrl);
  };

  const updatePlanList = async () => {
    setIsLoading(true);
    let response = await planApiService.requestScraping(mvno);
    if (response.status === 200) {
      alert(`${mvno} 최신정보로 업데이트가 완료되었습니다`);
    } else {
      alert(`${mvno} 최신정보로 업데이트가 실패하였습니다!`);
    }
    setIsLoading(false);
  };

  //TODO 예약 업데이트 기능 제거 문의 후 제거
  // const reserveUpdatePlanList = async (reserveDate: DateTime) => {
  //   try {
  //     setLoading(true);
  //     await planApiService.requestScrapingReserve(mvno, reserveDate);
  //   } catch (error) {
  //     throw error;
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleUpdateList = async () => {
    if (mvno === '') return;

    openModal(
      <PlanUpdateDialog
        isOpen
        onUpdate={async () => {
          try {
            setLoading(true);
            await updatePlanList();
            closeModal();
          } catch (error) {
            throw error;
          } finally {
            setLoading(false);
          }
        }}
        onClose={closeModal}
      />,
    );
  };

  const filteredPlansMap = useMemo(() => {
    return new Map(plans.map((plan) => [plan.id, plan]));
  }, [plans]);

  const planColumnList = useMemo(() => {
    if (isMoyoAdmin(adminUser)) {
      return ColumnList({ isMoyoOnly });
    } else {
      return ColumnList({ isMoyoOnly }).filter(
        (column) =>
          column.field !== 'mvno' && column.field !== 'planManagementType',
      );
    }
  }, [adminUser, isMoyoOnly]);

  const sortingMvnos = Mvnos.sort((prev, next) => {
    if (prev.label.toLowerCase() > next.label.toLowerCase()) return 1;
    else if (prev.label.toLowerCase() < next.label.toLowerCase()) return -1;
    else return 0;
  });

  const handleVisibilityChange = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    let objectId;
    if (newValue === 'all') {
      setdisplay(undefined);
      objectId = 'all_status';
    } else if (newValue === 'visible') {
      setdisplay(true);
      objectId = 'activate';
    } else if (newValue === 'invisible') {
      setdisplay(false);
      objectId = 'inactivate';
    }
    pbl('click', 'button', 'here', {
      object: {
        id: objectId,
        section: 'top_button',
      },
    });
  };

  return (
    <>
      <Spinner show={isLoading}></Spinner>
      <Container sx={{ display: 'grid', mt: 5 }} maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            fontWeight={'regular'}
            style={{ padding: '16px 0' }}
          >
            {pageTitle}
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <PermissionRender
                isForceHidden={isMoyoOnly}
                moyoAdmin
                permission={{
                  requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
                }}
                planManagementMethodList={['API', 'SCRAPING']}
              >
                <ImpressionLogTracker
                  logObjectType="button"
                  logOptions={{
                    object: {
                      id: 'update_plan_information_now',
                      section: 'action_menu',
                      name: '정보 업데이트',
                    },
                  }}
                  trackingOneTime
                  allowClickEvent
                >
                  <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={handleUpdateList}
                  >
                    <CachedIcon style={{ marginRight: '8px' }} />
                    정보 업데이트
                  </Button>
                </ImpressionLogTracker>
              </PermissionRender>
              <PermissionRender
                moyoAdmin={registerPlanIsRequireMoyoAdmin}
                permission={{
                  requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
                }}
                planManagementMethodList={registerPlanManagementMethodList}
              >
                <ImpressionLogTracker
                  logObjectType="button"
                  logOptions={{
                    object: {
                      id: 'create_plan',
                      section: 'action_menu',
                      name: '요금제 등록',
                    },
                  }}
                  trackingOneTime
                  allowClickEvent
                >
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handlePlanRegister}
                  >
                    {planRegisterButtonText}
                  </Button>
                </ImpressionLogTracker>
              </PermissionRender>
            </div>
          </Box>
        </Box>

        {/*요금제 찾기 필터*/}
        <Grid container mt={1} mb={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Grid container spacing={2}>
              <Grid item>
                <FormControl>
                  <ToggleButtonGroup
                    size="small"
                    value={
                      display === undefined
                        ? 'all'
                        : display
                        ? 'visible'
                        : 'invisible'
                    }
                    exclusive
                    onChange={handleVisibilityChange}
                    sx={{
                      '& .MuiToggleButton-root.Mui-selected': {
                        backgroundColor: 'primary.default',
                        color: 'primary.black',
                      },
                    }}
                  >
                    <ToggleButton value="all" style={{ whiteSpace: 'nowrap' }}>
                      모든 상태
                    </ToggleButton>
                    <ToggleButton value="visible">노출중</ToggleButton>
                    <ToggleButton value="invisible">미노출</ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControl style={{ width: '120px' }}>
                  <InputLabel id="mno-label" size="small">
                    통신망
                  </InputLabel>
                  <Select
                    labelId="mno-label"
                    id="mno-select"
                    size="small"
                    value={mno}
                    label="통신망"
                    onChange={handleMnoChange}
                  >
                    {/* TODO - 해당 통신망 필터링 추가 */}
                    <MenuItem value={'전체'}>전체</MenuItem>
                    <MenuItem value={'LGU'}>LG망</MenuItem>
                    <MenuItem value={'KT'}>KT망</MenuItem>
                    <MenuItem value={'SKT'}>SKT망</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {mvno === 'valuecomm' && (
                <Grid item>
                  <FormControl style={{ width: '160px' }}>
                    <InputLabel size="small">통신사</InputLabel>
                    <Select
                      value={mvnoFilter}
                      size="small"
                      label="Mvno"
                      onChange={handleMvnoChange}
                    >
                      <MenuItem value={'전체'}>전체</MenuItem>
                      {sortingMvnos.map((group, index) => (
                        <MenuItem key={index} value={group.value}>
                          {group.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {isMoyoAdmin(adminUser) && (
                <>
                  <Grid item xs={2.5}>
                    <TextField
                      value={filterText}
                      onChange={(e) => {
                        setFilterText(e.target.value);
                      }}
                      size="small"
                      placeholder="요금제 이름 검색"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2.5}>
                    <TextField
                      value={filterIdText}
                      onChange={(e) => {
                        setFilterIdText(e.target.value);
                      }}
                      size="small"
                      placeholder="요금제 ID 검색"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {isMoyoRole && (
              <Button
                variant="outlined"
                color="inherit"
                style={{ whiteSpace: 'nowrap', height: '40px' }}
                size="medium"
                onClick={handleExposureSetting}
                disabled={selectedIds.length === 0}
              >
                선택 요금제 노출설정
              </Button>
            )}
          </Box>
        </Grid>

        {/* 요금제 목록 테이블 */}
        <DataGrid
          columns={planColumnList}
          rows={plans}
          onRowSelectionModelChange={
            isMoyoRole
              ? (id) => {
                  setSelectedIds(id);
                }
              : undefined
          }
          checkboxSelection={isMoyoRole}
          rowSelectionModel={selectedIds}
          rowCount={totalCount}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            pbl('click', 'pagination', 'here', {
              object: {
                id: 'search_plan_name',
                section: 'bottom_button',
              },
              eventProperties: {
                page: model.page,
                pageSize: model.pageSize,
                beforePage: paginationModel.page,
                beforePageSize: paginationModel.pageSize,
              },
            });
            setPaginationModel(model);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
        />
      </Container>
    </>
  );
};

export default PlanListPage;
