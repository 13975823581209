import { AxiosResponse } from 'axios';
import { authApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import { UserDto } from '../entity/user';
import { Permission, Role } from '../types/AuthType';
import { getDeviceId } from '../utils/device-id';

export interface UserMe {
  id: number;
  name: string;
  adminUserId: string;
  partnerCompanyId: number;
  roles: Role[];
  permissions: Permission[];
}

export const LOGIN_PATH = '/api/partners-admin/users/login';

class AuthApiService {
  login(
    userId: string,
    password: string,
    code: string,
  ): Promise<AxiosResponse<ApiResponse<UserDto>>> {
    return authApi.post(
      LOGIN_PATH,
      // NOTE: 하나의 브라우저에서 여러 탭간에 공유되는 고유한 sessionId를 관리하기 위해 deviceId(정확히는 browser unique id)를 포함해서 전달한다.
      { userId, password, deviceId: getDeviceId(), code },
      { withCredentials: true },
    );
  }

  async changePassword(userId: string, password: string, newPassword: string) {
    return authApi.put(
      '/api/partners-admin/users/password',
      {
        userId,
        password,
        newPassword,
      },
      { withCredentials: true },
    );
  }

  me() {
    return authApi.get<ApiResponse<UserMe>>('/api/partners-admin/users/me');
  }

  getMembers() {
    return authApi.get<ApiResponse<{ members: UserMe[] }>>(
      '/api/partners-admin/users',
    );
  }

  /**
   * 유저 계정생성
   * @param name
   * @param password
   * @param userId
   */
  createMember(name: string, password: string, userId: string) {
    return authApi.post('/api/partners-admin/users/register', {
      name,
      password,
      userId,
    });
  }

  updatePermission(adminUserId: string, permissions: Permission[]) {
    return authApi.put('/api/partners-admin/users/update', {
      adminUserId,
      permissions,
    });
  }

  deleteMember(id: string) {
    return authApi.delete(`/api/partners-admin/users/${id}`);
  }

  async issueOtp() {
    return authApi.post<ApiResponse<{ otpAuthUrl: string }>>(
      '/api/partners-admin/users/issue-otp',
    );
  }
}

export const authApiService = new AuthApiService();
