import { MobilePlanOperatorId } from '../common/mobile-plan-operator-id';

export type Mvno =
  | 'KCTV'
  | 'KG모바일'
  | 'ktmmobile'
  | 'KTskylife'
  | 'sk7mobile'
  | 'U+유모바일'
  | '고고모바일'
  | '드림모바일'
  | 'liivm'
  | '마이월드'
  | 'valuecomm'
  | 'marvelring'
  | 'mobing'
  | '사람과연결'
  | '서경모바일'
  | 'sugarmobile'
  | 'snowman'
  | 'smartel'
  | '아이즈모바일'
  | '에스원안심모바일'
  | 'amobile'
  | 'yeoyou'
  | '위너스텔'
  | '이야기모바일'
  | '이지모바일'
  | 'insmobile'
  | '에르엘'
  | '쉐이크모바일'
  | 'tplus'
  | '프리티'
  | '핀다이렉트'
  | '헬로모바일'
  | 'SKT'
  | 'lgu'
  | 'KT'
  | '오파스모바일'
  | '한패스모바일'
  | '모나'
  | '찬스모바일';

export const mvnoByAuthorizedMvno: Record<string, Mvno | string> = {
  all: 'ALL',
  none: 'None',
  freet: '프리티',
  sk7mobile: 'sk7mobile',
  smartel: 'smartel',
  mobing: 'mobing',
  ktmmobile: 'ktmmobile',
  iplusu: 'iplusu',
  uplussave: 'U+',
  myworld: '마이월드',
  liivm: 'liivm',
  hellomobile: '헬로모바일',
  amobile: 'amobile',
  kTskylife: 'KTskylife',
  eyes: '아이즈모바일',
  tplus: 'tplus',
  seogyoung: '서경모바일',
  eyagi: '이야기모바일',
  sone: '에스원안심모바일',
  yeoyou: 'yeoyou',
  snowman: 'snowman',
  egmobile: '이지모바일',
  kctv: 'KCTV',
  dream: '드림모바일',
  winnerstel: '위너스텔',
  아시아모바일: '아시아모바일',
  m2mobile: 'm2mobile',
  ntelecom: 'ntelecom',
  KG모바일: 'KG모바일',
  ins: 'insmobile',
  valuecomm: 'valuecomm',
  sugarmobile: 'sugarmobile',
  LGU: 'lgu',
  SKT: 'SKT',
  KT: 'KT',
  marvelring: 'marvelring',
  사람과연결: '사람과연결',
  에르엘: '에르엘',
  쉐이크모바일: '쉐이크모바일',
  고고모바일: '고고모바일',
  핀다이렉트: '핀다이렉트',
  코나아이: '코나아이',
  오파스모바일: '오파스모바일',
  한패스모바일: '한패스모바일',
  모나: '모나',
  찬스모바일: '찬스모바일',
};

export const Mvnos: {
  label: string;
  value: Mvno;
  operatorId: MobilePlanOperatorId;
}[] = [
  { label: 'KCTV', value: 'KCTV', operatorId: MobilePlanOperatorId.KCTV알뜰폰 },
  {
    label: 'KG모바일',
    value: 'KG모바일',
    operatorId: MobilePlanOperatorId.KG모바일,
  },
  {
    label: 'KT엠모바일',
    value: 'ktmmobile',
    operatorId: MobilePlanOperatorId.KT엠모바일,
  },
  {
    label: 'KT스카이라이프',
    value: 'KTskylife',
    operatorId: MobilePlanOperatorId.KT스카이라이프,
  },
  {
    label: 'SK7모바일',
    value: 'sk7mobile',
    operatorId: MobilePlanOperatorId.SK7모바일,
  },
  {
    label: 'U+유모바일',
    value: 'U+유모바일',
    operatorId: MobilePlanOperatorId.U_PLUS_유모바일,
  },
  {
    label: '고고모바일',
    value: '고고모바일',
    operatorId: MobilePlanOperatorId.고고모바일,
  },
  {
    label: '드림라인 모바일',
    value: '드림모바일',
    operatorId: MobilePlanOperatorId.드림라인_모바일,
  },
  { label: '리브엠', value: 'liivm', operatorId: MobilePlanOperatorId.리브엠 },
  {
    label: '마이월드',
    value: '마이월드',
    operatorId: MobilePlanOperatorId.마이월드,
  },
  {
    label: '밸류컴',
    value: 'valuecomm',
    operatorId: MobilePlanOperatorId.밸류컴,
  },
  {
    label: '마블링',
    value: 'marvelring',
    operatorId: MobilePlanOperatorId.마블링,
  },
  { label: '모빙', value: 'mobing', operatorId: MobilePlanOperatorId.모빙 },
  {
    label: '사람과연결',
    value: '사람과연결',
    operatorId: MobilePlanOperatorId.사람과연결,
  },
  {
    label: '서경모바일',
    value: '서경모바일',
    operatorId: MobilePlanOperatorId.서경모바일,
  },
  {
    label: '슈가모바일',
    value: 'sugarmobile',
    operatorId: MobilePlanOperatorId.슈가모바일,
  },
  {
    label: '스노우맨',
    value: 'snowman',
    operatorId: MobilePlanOperatorId.스노우맨,
  },
  {
    label: '스마텔',
    value: 'smartel',
    operatorId: MobilePlanOperatorId.스마텔,
  },
  {
    label: '아이즈모바일',
    value: '아이즈모바일',
    operatorId: MobilePlanOperatorId.아이즈모바일,
  },
  {
    label: '에스원안심모바일',
    value: '에스원안심모바일',
    operatorId: MobilePlanOperatorId.에스원안심모바일,
  },
  {
    label: '에이모바일',
    value: 'amobile',
    operatorId: MobilePlanOperatorId.에이모바일,
  },
  {
    label: '여유알뜰모바일',
    value: 'yeoyou',
    operatorId: MobilePlanOperatorId.여유알뜰모바일,
  },
  {
    label: '위너스텔',
    value: '위너스텔',
    operatorId: MobilePlanOperatorId.위너스텔,
  },
  {
    label: '이야기모바일',
    value: '이야기모바일',
    operatorId: MobilePlanOperatorId.이야기모바일,
  },
  {
    label: '이지모바일',
    value: '이지모바일',
    operatorId: MobilePlanOperatorId.이지모바일,
  },
  {
    label: '인스모바일',
    value: 'insmobile',
    operatorId: MobilePlanOperatorId.인스모바일,
  },
  { label: '에르엘', value: '에르엘', operatorId: MobilePlanOperatorId.에르엘 },
  {
    label: '쉐이크모바일',
    value: '쉐이크모바일',
    operatorId: MobilePlanOperatorId.쉐이크모바일,
  },
  {
    label: '티플러스',
    value: 'tplus',
    operatorId: MobilePlanOperatorId.티플러스,
  },
  { label: '프리티', value: '프리티', operatorId: MobilePlanOperatorId.프리티 },
  {
    label: '핀다이렉트',
    value: '핀다이렉트',
    operatorId: MobilePlanOperatorId.핀다이렉트,
  },
  {
    label: 'LG헬로모바일',
    value: '헬로모바일',
    operatorId: MobilePlanOperatorId.LG헬로모바일,
  },
  {
    label: '오파스모바일',
    value: '오파스모바일',
    operatorId: MobilePlanOperatorId.오파스모바일,
  },
  {
    label: '한패스모바일',
    value: '한패스모바일',
    operatorId: MobilePlanOperatorId.한패스모바일,
  },
  { label: 'SKT', value: 'SKT', operatorId: MobilePlanOperatorId.SKT },
  { label: 'LG U+', value: 'lgu', operatorId: MobilePlanOperatorId.LG_U_PLUS },
  { label: 'KT', value: 'KT', operatorId: MobilePlanOperatorId.KT },
  { label: '모나', value: '모나', operatorId: MobilePlanOperatorId.모나 },
  {
    label: '찬스모바일',
    value: '찬스모바일',
    operatorId: MobilePlanOperatorId.찬스모바일,
  },
];
