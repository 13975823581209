import React, { FC, ReactNode, useEffect, useState } from 'react';
import { usePortal, usePortalController } from './usePortal';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ThemeControlProvider } from '../context/ThemeControlContext';

interface AlertProps {
  title: string;
  content: ReactNode;
  okText?: string;
  onImpress?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert: FC<AlertProps> = ({ title, content, okText, onImpress }) => {
  const { close, dismiss } = usePortalController<boolean>();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (open) {
      const timeoutId = setTimeout(() => {
        onImpress?.();
      }, 300);
      return () => {
        clearTimeout(timeoutId);
        closeHandler();
      };
    }
  }, [open]);

  const onOkDialogHandler = () => {
    closeHandler(true);
  };

  const closeHandler = (result?: boolean) => {
    setOpen(false);
    setTimeout(() => {
      close(result);
      dismiss();
    }, 500);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ whiteSpace: 'break-spaces', minWidth: 372 }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onOkDialogHandler}>
          {okText ?? '확인'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useAlert = () => {
  const portal = usePortal<boolean>();

  const open = (
    confirmProps: Omit<AlertProps, 'onImpress'>,
    onImpress?: AlertProps['onImpress'],
  ) => {
    return portal.open(
      <ThemeControlProvider>
        <Alert {...confirmProps} onImpress={onImpress} />
      </ThemeControlProvider>,
    );
  };

  return { open };
};

export default useAlert;
