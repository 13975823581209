export function convertTwelveMonthFee({
  originalFee,
  discountFee,
  discountPeriod,
  isFreeDiscount,
}: {
  originalFee?: number | null;
  discountFee?: number | null;
  discountPeriod?: number | null;
  isFreeDiscount: boolean;
}) {
  //기간 할인일 때 값이 모두 입력되어야 계산 시작
  if (!isFreeDiscount) {
    if (!originalFee || !discountFee || !discountPeriod) return;
  }

  if (isFreeDiscount && discountFee) {
    // 평생할인일 때
    return discountFee * 12;
  }

  if (discountFee && originalFee && discountPeriod) {
    // 할인 기간이 12개월 미만일 때
    if (discountPeriod < 12) {
      return discountFee * discountPeriod + originalFee * (12 - discountPeriod);
    }
    // 할인 기간이 12개월 이상일 때
    return discountFee * 12;
  }

  return null;
}
