import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { planApiService } from '../../api/plan';
import { useEffect, useState } from 'react';
import PlanNoticeEdit from './PlanNoticeEdit';
import { PlanV2 } from '../../entity/plan-v2';

export default function PlanNoticeEditPage() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [planDetail, setPlanDetail] = useState<PlanV2>();

  const planId = Number(searchParams.get('planId'));

  useEffect(() => {
    if (!planId) return;
    void (async () => {
      const planDetailResponse = await planApiService.getPlanDetail(
        Number(planId),
      );
      const _planDetail = planDetailResponse.data.result;
      if (!_planDetail) {
        alert('요금제 정보를 조회하는데 실패했습니다.');
        return;
      }

      setPlanDetail(_planDetail);
    })();
  }, []);

  if (Number.isNaN(planId)) {
    throw new Error('planId is not a number');
  }

  if (!user || !planId || !planDetail) return <></>;

  return (
    <PlanNoticeEdit
      planId={planId}
      planManagementType={planDetail.meta.planManagementType}
      mobilePlanOperatorId={planDetail.mobilePlanOperatorDto.id}
      planUnitNoticeTemplateIds={planDetail.planNotices.planUnitPlanNoticeList.map(
        (notice) => notice.templateId,
      )}
    />
  );
}
