import { flattenObject } from './object_util';

export const objectDiff = (
  before: Record<string, any>,
  after: Record<string, any>,
) => {
  const diff: Record<string, any> = {};
  for (const key in before) {
    if (typeof before[key] === 'object' && typeof after[key] === 'object') {
      const nestedDiff = objectDiff(before[key], after[key]);
      if (Object.keys(nestedDiff).length > 0) {
        diff[key] = nestedDiff;
      }
    } else if (before[key] !== after[key] && !(!before[key] && !after[key])) {
      // 둘다 null or undefined일 때는 다르다고 판단하면 안됨
      diff[key] = after[key];
    }
  }
  return diff;
};

export const flattenDiff = (
  before: Record<string, any>,
  after: Record<string, any>,
) => {
  const diff = objectDiff(before, after);
  return flattenObject(diff);
};
