import { ICreatePlanDto } from '../../../entity/plan';
import { HTTPS_PROTOCOL_PREFIX } from '../../MvnoInfoPage/constants';
import { planNameFormatter } from './format/plan-name-formtter';

export const planInfoPrefixService = {
  removePrefix: (createPlanDto: ICreatePlanDto) => {
    createPlanDto.signupUrl = createPlanDto.signupUrl
      ?.replace(HTTPS_PROTOCOL_PREFIX, '')
      .trim();

    createPlanDto.mobileSignupUrl = createPlanDto.mobileSignupUrl
      ?.replace(HTTPS_PROTOCOL_PREFIX, '')
      .trim();

    createPlanDto.name = createPlanDto.isMoyoOnly
      ? planNameFormatter.removeMoyoOnlyPrefix(createPlanDto.name)
      : createPlanDto.name;
    return createPlanDto;
  },
  appendPrefix: (createPlanDto: ICreatePlanDto) => {
    createPlanDto.signupUrl = `${HTTPS_PROTOCOL_PREFIX}${createPlanDto.signupUrl}`;
    createPlanDto.mobileSignupUrl = `${HTTPS_PROTOCOL_PREFIX}${createPlanDto.mobileSignupUrl}`;
    createPlanDto.name = createPlanDto.isMoyoOnly
      ? planNameFormatter.appendMoyoOnlyPrefix(createPlanDto.name)
      : createPlanDto.name;
    return createPlanDto;
  },
};
