import { Typography } from '@mui/material';

import { BasicInfoSection } from './components/BasicInfoSection';
import { Header, PageContainer, SectionDivider } from './styled';
import { PlanSpecificSection } from './components/PlanSpecificSection';
import { PlanInfoSection } from './components/PlanInfoSection';
import { AdditionalInfoSection } from './components/AdditionalInfoSection';

import { useForm, FormProvider, UseFormSetError } from 'react-hook-form';
import { ICreatePlanDto, IMobilePlan } from '../../entity/plan';
import { planApiService } from '../../api/plan';

import { DateTime } from 'luxon';
import { getISOSimpleFormat } from '../../utils/dateFormatter';

import { AddBottomButtonGroup } from './components/AddBottomButtonGroup';
import { EditBottomButtonGroup } from './components/EditBottomButtonGroup';
import { useEffect } from 'react';
import pbl from '../../pbl/pbl-service/pbl-service';
import PlanNoticeEditSection from './components/PlanNoticeEdit';
import Join from '../../shared/join';
import { MobilePlanOperatorId } from '../../common/mobile-plan-operator-id';
import { PlanManagementMethodType } from '../MvnoInfoPage/types/mobile-plan-operator-admin-types';
import { useLoading } from '../../context/LoadingContext';
import { formatPlanData } from './util/formatPlanData';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '../../entity/api';
import { useRole } from '../../router/useRole';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/UserContext';
import { PlanInfoProps } from './types';

export type onCompleteResponse = AxiosResponse<ApiResponse<IMobilePlan>>;

interface PlanEditProps {
  isEdit: boolean;
  planId?: number;
  displayPlan: boolean;
  mobilePlanOperatorId: MobilePlanOperatorId;
  createPlanDto: ICreatePlanDto;
  pageTitle: string;
  planInfo: PlanInfoProps;
  planManagementType?: PlanManagementMethodType;
  isMoyoOnly: boolean;
  onValidateBeforePlanAction?: (
    data: ICreatePlanDto,
    setError: UseFormSetError<ICreatePlanDto>,
  ) => Promise<boolean>;
  onComplete: (response: onCompleteResponse) => void;
}

/**
 *
 * @param onValidateBeforePlanAction 요금제 등록/수정 전 검증하는 함수를 주입 받습니다. response는 boolean 이어야 합니다.
 */
export default function PlanEdit({
  isEdit,
  isMoyoOnly,
  planId,
  displayPlan,
  mobilePlanOperatorId,
  createPlanDto,
  pageTitle,
  planInfo,
  planManagementType,
  onValidateBeforePlanAction,
  onComplete: handleComplete,
}: PlanEditProps) {
  const { setLoading } = useLoading();
  const { isMoyoRole } = useRole();
  const { user } = useAuth();
  const navigate = useNavigate();

  const methods = useForm<ICreatePlanDto>({
    defaultValues: createPlanDto,
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const isManualPlanManagementType = planManagementType === 'MANUAL';

  const redirectIfNotOwnPlan = () => {
    alert('자사몰 요금제가 아닙니다. 리스트로 이동됩니다');
    navigate('/plans');
  };

  const {
    handleSubmit,
    trigger,
    getValues,
    setError,
    formState: { isDirty, isValid },
  } = methods;

  useEffect(() => {
    if (isEdit) trigger();
  }, []);

  useEffect(() => {
    if (
      !isMoyoRole &&
      user?.company?.mobilePlanOperatorId &&
      createPlanDto?.mobilePlanOperatorId &&
      user.company.mobilePlanOperatorId !== createPlanDto.mobilePlanOperatorId
    ) {
      redirectIfNotOwnPlan();
    }
  }, [user, createPlanDto, isMoyoRole, navigate]);

  const handlePlanAction = async (
    action: (data: ICreatePlanDto) => Promise<onCompleteResponse>,
    date?: DateTime | null,
  ) => {
    handleSubmit(async (data: ICreatePlanDto) => {
      try {
        setLoading(true);
        const formattedData = formatPlanData(data, date);

        const response = await action(formattedData);
        handleComplete(response);
      } catch (error) {
        alert(error);
        throw error;
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleConfirmOnlyRegister = async () => {
    await handlePlanAction((data) => {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'plan_expose_ready',
          name: '등록하기',
        },
        eventProperties: {
          ...data,
        },
      });
      return planApiService.createPlan(data);
    });
  };

  const handleConfirmExpose = async (date: DateTime | null) => {
    const reserveDate = date ?? DateTime.now().startOf('minute');

    await handlePlanAction((data) => {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: date ? 'plan_expose_reserve_completed' : 'plan_expose_live',
          name: date ? '노출예약' : '지금노출',
        },
        eventProperties: {
          ...data,
          reserveDate: getISOSimpleFormat(reserveDate),
        },
      });
      return planApiService.createPlan(data);
    }, reserveDate);
  };

  const handleEditPlan = async () => {
    await handlePlanAction((data) => {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'edit_plan_immediately',
          name: '적용하기',
        },
        eventProperties: {
          planId,
          ...data,
        },
      });
      return planApiService.putPlan(Number(planId), data);
    });
  };

  const handleReserveEditPlan = async (date: DateTime | null) => {
    await handlePlanAction((data) => {
      pbl('click', 'modal_button', 'here', {
        object: {
          id: 'edit_plan_reservation',
          name: '예약하기',
        },
        eventProperties: {
          planId,
          ...data,
        },
      });
      return planApiService.putPlan(Number(planId), data);
    }, date);
  };

  //모요 온리 요금제 등록/수정 전 공통 검증 함수
  const handleValidateBeforePlanAction = async () => {
    if (!onValidateBeforePlanAction) return true;
    const data = getValues();
    const formattedData = formatPlanData(data);
    const isValidateSuccess = await onValidateBeforePlanAction(
      formattedData,
      setError,
    );
    return isValidateSuccess;
  };

  return (
    <PageContainer>
      <Header>
        <Typography variant="h4">{pageTitle}</Typography>
      </Header>

      <FormProvider {...methods}>
        {/* TODO - 각 form에 required 추가 */}
        <form noValidate>
          <Join
            separator={
              <SectionDivider
                orientation="horizontal"
                flexItem
                style={{ marginTop: '40px' }}
              />
            }
          >
            {/* 기본 정보 */}
            <BasicInfoSection isMoyoOnly={isMoyoOnly} />
            {/* 요금제 스펙  */}
            <PlanSpecificSection isEdit={isEdit} />
            {/* 요금 정보  */}
            <PlanInfoSection
              isEdit={isEdit}
              validators={planInfo.validators}
              subTitle={planInfo?.subTitle}
              bottomContentArea={planInfo?.content}
              initialAgreementPeriod={createPlanDto.agreementPeriod}
              initialDiscountFee={createPlanDto.discountFee}
              initialDiscountPeriod={createPlanDto.discountPeriod}
              initialOriginalFee={createPlanDto.originalFee}
            />

            {/* 부가 정보 */}
            <AdditionalInfoSection />

            {/* 요금제 공지 */}
            <PlanNoticeEditSection
              initialPlanUnitNoticeTemplateIds={
                createPlanDto.planUnitNoticeTemplateIds
              }
              mobilePlanOperatorId={mobilePlanOperatorId}
            />
          </Join>

          {/* 하단 버튼 */}
          {isEdit ? (
            <EditBottomButtonGroup
              onReserve={(date: DateTime | null) => {
                handleReserveEditPlan(date);
              }}
              onValidateBeforePlanAction={handleValidateBeforePlanAction}
              onValidate={trigger}
              onOk={handleEditPlan}
              isShowReserveButton={isManualPlanManagementType}
              displayPlan={displayPlan}
              isDirty={isDirty}
              isValid={isValid}
            />
          ) : (
            <AddBottomButtonGroup
              onOnlyRegister={handleConfirmOnlyRegister}
              onValidateBeforePlanAction={handleValidateBeforePlanAction}
              onValidate={trigger}
              onExpose={(date: DateTime | null) => {
                handleConfirmExpose(date);
              }}
            />
          )}
        </form>
      </FormProvider>
    </PageContainer>
  );
}
