import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { CookieConstants } from './constants';

export interface JwtPayload {
  id: number;
  name: string;
  adminUserId: string;
  partnerCompanyId: number;
  role: string;
  authorities: string;
  permissions: string;
  iat: number;
  exp: number;
  otpEnabled: boolean;
}

const cookies = new Cookies();

export const setCookie = (
  name: string,
  value: string,
  options?: CookieSetOptions,
) => {
  return cookies.set(name, value, { ...options });
};

export const setToken = (value: string, options?: CookieSetOptions) => {
  return setCookie(CookieConstants.ACCESS_TOKEN, value, { ...options });
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const getToken = () => {
  return getCookie(CookieConstants.ACCESS_TOKEN);
};

export const removeToken = () => {
  return cookies.remove(CookieConstants.ACCESS_TOKEN);
};

export const getJWTInfo = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload) as JwtPayload;
  } catch (error) {
    console.error('Error parsing JWT:', error);
    throw new Error('Invalid token');
  }
};
