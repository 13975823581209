export function getPlanDetailUrlByMoyoOnly({
  planId,
  isMoyoOnly,
}: {
  planId: number;
  isMoyoOnly: boolean;
}) {
  const baseUrl = '/plan-edit';
  const url = isMoyoOnly ? `${baseUrl}/moyo-only` : baseUrl;
  return `${url}?planId=${planId}`;
}
