import { ConvertedTwelveMonthFeeContentFormAdapter } from '../components/ConvertedTwelveMonthFeeContentFormAdapter';
import { usePlanActions } from '../hooks/usePlanActions';
import {
  onValidateDiscountFee,
  onValidateDiscountPeriod,
  onValidateOriginalFee,
} from '../logic/validate/plan-price';
import { onCompleteResponse } from '../PlanEdit';
import { PlanInfoOnValidateProps, PlanInfoProps } from '../types';

/**
 *
 * @param isMoyoOnly 요금제의 모요온리 여부
 * @description PlanEdit의 props를 반환하는 hook이며 isMoyoOnly에 의해 필요한 데이터를 반환합니다.
 */
export function usePlanActionsAndPropsBySection(isMoyoOnly: boolean) {
  const { similarPlan, handleComplete, handleValidateMoyoOnlyFee } =
    usePlanActions();

  const planInfo: PlanInfoProps = {
    subTitle: isMoyoOnly
      ? '자사몰에 있는 같은 스펙의 요금제보다 12개월 환산금액이 1,000원 이상 저렴해야 해요.'
      : undefined,
    content: isMoyoOnly ? (
      <ConvertedTwelveMonthFeeContentFormAdapter similarPlan={similarPlan} />
    ) : undefined,
    validators: {
      onValidateOriginalFee: (props: PlanInfoOnValidateProps) =>
        onValidateOriginalFee({ ...props, similarPlan }),
      onValidateDiscountFee: (props: PlanInfoOnValidateProps) =>
        onValidateDiscountFee({ ...props, similarPlan }),
      onValidateDiscountPeriod: (props: PlanInfoOnValidateProps) =>
        onValidateDiscountPeriod({ ...props, similarPlan }),
    },
  };

  return {
    handleComplete: (response: onCompleteResponse) =>
      handleComplete(response, isMoyoOnly),
    handleValidateMoyoOnlyFee: isMoyoOnly
      ? handleValidateMoyoOnlyFee
      : undefined,
    planInfo,
    similarPlan,
  };
}
