import { EventSourcePolyfill, MessageEvent } from 'event-source-polyfill';
import React, { useEffect, useRef } from 'react';
import { env } from '../utils/env';
import { useAuth } from '../context/UserContext';
import { getToken } from '../common/cookie';

interface Payload {
  eventType: 'CONNECTED' | 'LOGOUT';
  sessionId: string;
}

export default function ServerSentEventListener({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, logoutUser } = useAuth();
  const connected = useRef(false);

  useEffect(() => {
    if (!user || connected.current) return;

    const eventSource = new EventSourcePolyfill(
      `${env('REACT_APP_AUTH_API_URL')}/api/partners-admin/users/session`,
      {
        withCredentials: true,
        heartbeatTimeout: 1000 * 60 * 2, // 2 minutes
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    const handleMessage = (e: MessageEvent) => {
      const data = JSON.parse(e.data) as Payload;

      if (data.eventType === 'CONNECTED') {
        connected.current = true;
        console.log('[SSE] CONNECTED');
      }

      if (data.eventType === 'LOGOUT') {
        alert(
          '다른 디바이스에서 로그인이 확인되어 로그아웃됩니다. \n안전한 서비스를 이용을 위해 한 번에 하나의 기기에서만 로그인할 수 있어요.',
        );
        logoutUser();
        return;
      }
    };

    eventSource.addEventListener('message', handleMessage);
    eventSource.onerror = (e) => {
      console.error('SSE Error', e);
    };

    return () => {
      eventSource.removeEventListener('message', handleMessage);
      eventSource.close();
      console.log('[SSE] Closed');
      connected.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
}
