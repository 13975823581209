import { colors } from '@mui/material';

// ConvertedTwelveFeeContainer의 style object
export const CONVERTED_TWELVE_FEE_CONTAINER_STYLES = {
  error: {
    backgroundColor: colors.red[700],
    color: colors.common.white,
  },
  success: {
    backgroundColor: colors.green[800],
    color: colors.common.white,
  },
  default: {
    backgroundColor: colors.grey[50],
    color: colors.common.black,
  },
};
