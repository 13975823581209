import { useEffect, useState } from 'react';
import { BootstrapDialog, BootstrapDialogTitle } from './Dialog';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import { DateTime } from 'luxon';
import { Box } from '@mui/system';
import { useLoading } from '../../../context/LoadingContext';
import { planApiService } from '../../../api/plan';
import pbl from '../../../pbl/pbl-service/pbl-service';

type DeleteReserveDialogProps = {
  open: boolean;
  planIdList: number[];
  onSuccess: (messsage: string) => void;
  onClose: () => void;
};
const DeleteReserveDialog = ({
  open,
  planIdList,
  onSuccess,
  onClose,
}: DeleteReserveDialogProps) => {
  const now = DateTime.now().plus({ days: 1 }).setZone('Asia/Seoul');

  const [value, setValue] = useState<DateTime | null>(
    DateTime.now()
      .plus({ days: 1 })
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );

  const { setLoading } = useLoading();

  const onDelete = async () => {
    setLoading(true);

    const now = DateTime.now().setZone('Asia/Seoul');

    const isNow =
      value?.hasSame(now, 'year') &&
      value?.hasSame(now, 'month') &&
      value?.hasSame(now, 'day') &&
      value?.hasSame(now, 'hour') &&
      value?.hasSame(now, 'minute');

    await Promise.all(
      planIdList.map((id) => {
        if (isNow) {
          return planApiService.deletePlan(id);
        } else {
          return planApiService.reserveDeletePlan(id, value!!);
        }
      }),
    );
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'display_deleted_setting_completed',
        name: '종료 예약',
      },
      eventProperties: {
        displayTime: value?.toFormat("yyyy-MM-dd'T'HH:mm:ss"),
        isNow,
      },
    });

    setTimeout(() => {
      onClose();
      onSuccess(
        isNow ? '요금제 노출을 종료했어요' : '요금제 노출 종료가 예약되었어요',
      );
      setLoading(false);
    }, 1000);
  };

  const handleChange = (newValue: DateTime | null) => {
    setValue(newValue);
  };

  const onCloseHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'display_deleted_setting_cancel',
        name: '취소',
      },
    });
    onClose();
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onCloseHandler}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        요금제 노출 종료 예약
      </BootstrapDialogTitle>
      <DialogContent>
        <Box mt={1} width={'100%'}>
          <DateTimePicker
            minutesStep={10}
            label="노출 종료 일시"
            value={value}
            onChange={handleChange}
            disablePast
            slotProps={{ textField: { variant: 'outlined', fullWidth: true } }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'flex-end', padding: `8px`, marginTop: 16 }}
      >
        <Button color={'inherit'} variant={'text'} onClick={onCloseHandler}>
          취소
        </Button>
        <Button color={'error'} variant={'contained'} onClick={onDelete}>
          종료 예약
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DeleteReserveDialog;
