import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { BottomButtonRow } from '../styled';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { DateTime } from 'luxon';
import pbl from '../../../pbl/pbl-service/pbl-service';
import ImpressionLogTracker from '../../../pbl/impression-log-tracker/ImpressionLogTracker';

interface EditBottomButtonGroupProps {
  onReserve: (data: DateTime | null) => void;
  onValidate: () => Promise<boolean>;
  onValidateBeforePlanAction?: () => Promise<boolean>;
  onOk: () => void;
  displayPlan?: boolean;
  isDirty: boolean;
  isValid: boolean;
  isShowReserveButton: boolean;
  immediatelyButtonText?: string;
}

export const EditBottomButtonGroup = ({
  onReserve,
  onValidate,
  onOk,
  onValidateBeforePlanAction,
  displayPlan,
  isDirty,
  isValid,
  isShowReserveButton,
  immediatelyButtonText = '즉시 반영',
}: EditBottomButtonGroupProps) => {
  const [openReserveModal, setOpenReserveModal] = useState(false);
  const [openOkModal, setOpenOkModal] = useState(false);
  const [reserveDate, setReserveDate] = useState<DateTime | null>(
    DateTime.now().startOf('day').plus({ day: 1 }),
  );

  const handleOnOk = () => {
    setOpenOkModal(false);
    onOk();
  };

  const handleReserve = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    if (!(await onValidate())) return;
    pbl('click', 'button', 'here', {
      object: {
        id: 'edit_plan_reservation',
        section: 'edit',
      },
    });
    setOpenReserveModal(true);
  };

  const handleImmediately = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    if (!(await onValidate())) return;
    pbl('click', 'button', 'here', {
      object: {
        id: 'edit_plan_immediately',
        section: 'edit',
      },
    });
    setOpenOkModal(true);
  };

  const handleCancelReserve = async () => {
    if (onValidateBeforePlanAction && !(await onValidateBeforePlanAction()))
      return;
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'edit_plan_reservation_cancel',
        name: '취소',
      },
    });
    setOpenReserveModal(false);
  };

  const handleCloseOk = () => {
    setOpenOkModal(false);
  };

  return (
    <>
      <BottomButtonRow>
        {/* 현재 노출상태인 요금제만 수정예약 가능 */}
        {displayPlan && isShowReserveButton && (
          <Button
            variant="outlined"
            size="large"
            disabled={!isDirty || !isValid}
            onClick={handleReserve}
          >
            수정 예약
          </Button>
        )}

        <Button
          variant="contained"
          size="large"
          disabled={!isDirty || !isValid}
          onClick={handleImmediately}
        >
          {immediatelyButtonText}
        </Button>
      </BottomButtonRow>

      {/* 수정 예약 다이얼로그 */}
      <Dialog
        open={openReserveModal}
        onClose={handleCancelReserve}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ImpressionLogTracker
          logObjectType="modal"
          logOptions={{
            object: {
              id: 'edit_plan_reservation',
              section: 'edit',
            },
          }}
        >
          <>
            <DialogTitle id="alert-dialog-title" style={{ width: '410px' }}>
              수정 예약
            </DialogTitle>
            <DialogContent style={{ padding: '16px 24px' }}>
              <DateTimePicker
                sx={{ width: '100%' }}
                label="반영 일시"
                value={reserveDate}
                minutesStep={10}
                onChange={(newValue) => setReserveDate(newValue)}
                disablePast
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelReserve}>취소</Button>
              <Button
                onClick={() => {
                  setOpenReserveModal(false);
                  onReserve(reserveDate);
                }}
                color="primary"
                variant="contained"
              >
                예약하기
              </Button>
            </DialogActions>
          </>
        </ImpressionLogTracker>
      </Dialog>

      {/* 즉시 수정 다이얼로그 */}

      <Dialog
        open={openOkModal}
        onClose={handleCloseOk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ImpressionLogTracker
          logObjectType="modal"
          logOptions={{
            object: {
              id: 'edit_plan_immediately',
              section: 'edit',
            },
          }}
        >
          <>
            <DialogTitle id="alert-dialog-title">
              수정 내용을 적용할까요?
            </DialogTitle>
            <DialogContent style={{ width: '372px' }}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  wordBreak: 'keep-all',
                }}
              >
                {displayPlan
                  ? '적용한 즉시 고객들이 보는 요금제 정보가 업데이트 됩니다.'
                  : '적용하면 요금제 정보가 업데이트 됩니다.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOk}>취소</Button>
              <Button onClick={handleOnOk} color="primary" variant="contained">
                적용하기
              </Button>
            </DialogActions>
          </>
        </ImpressionLogTracker>
      </Dialog>
    </>
  );
};
