import axios, { AxiosError, AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { ApiResponse, ApiResponseV2 } from '../entity/api';
import { getToken } from './cookie';
import { AuthError } from './error';
import { env } from '../utils/env';
import { LOGIN_PATH } from '../api/auth';

const requestInterceptorWithAuth = (config: any) => {
  const token = getToken();
  if (!token) return config;
  const AuthorizedToken = `Bearer ${token}`;
  config.headers['Authorization'] = AuthorizedToken;
  return config;
};

const handleResponseInterceptorError = (error: AxiosError<ApiResponse>) => {
  const response = {
    resultType: error.response?.data.resultType,
    status: error.response?.status,
  };

  // NOTE: 로그인 실패 시 에러 처리를 위한 예외 처리
  if (error.config?.url === LOGIN_PATH) {
    return Promise.reject(error);
  }

  // NOTE: 401을 받지만 특정 에러코드인 경우 외부에서 처리하기 위해 그냥 에러를 던짐.
  if (error.response?.data.errorCode === 'INVALID_PASSWORD_PATTERN') {
    return Promise.reject(error);
  }

  try {
    switch (response.status) {
      case 401:
        throw new AuthError(response.resultType);
      default:
        return error;
    }
  } catch (error) {
    handleAuthError(error);
  }
};

export const authApi = axios.create({
  baseURL: new URL('/', env('REACT_APP_AUTH_API_URL')).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
authApi.interceptors.request.use(requestInterceptorWithAuth);
authApi.interceptors.response.use(null, handleResponseInterceptorError);

export const orderApi = axios.create({
  baseURL: new URL('/api-admin/order', env('REACT_APP_ORDER_API_URL')).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
orderApi.interceptors.request.use(requestInterceptorWithAuth);
orderApi.interceptors.response.use(null, handleResponseInterceptorError);

export const internetOrderApi = axios.create({
  baseURL: new URL('/api-admin/order/internet', env('REACT_APP_ORDER_API_URL'))
    .href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
internetOrderApi.interceptors.request.use(requestInterceptorWithAuth);
internetOrderApi.interceptors.response.use(
  null,
  handleResponseInterceptorError,
);

export const s3api = axios.create({
  baseURL: new URL('/api-admin/aws/s3', env('REACT_APP_API_URL')).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
s3api.interceptors.request.use(requestInterceptorWithAuth);
s3api.interceptors.response.use(null, handleResponseInterceptorError);

export const moyoApi = axios.create({
  baseURL: new URL('/api-admin', env('REACT_APP_API_URL')).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
moyoApi.interceptors.request.use(requestInterceptorWithAuth);
moyoApi.interceptors.response.use(null, handleResponseInterceptorError);

export const companyOrderApi = axios.create({
  baseURL: new URL('/api-admin/order/company', env('REACT_APP_ORDER_API_URL'))
    .href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
companyOrderApi.interceptors.request.use(requestInterceptorWithAuth);
companyOrderApi.interceptors.response.use(null, handleResponseInterceptorError);

export const adminApi = axios.create({
  baseURL: new URL('/api-admin', env('REACT_APP_API_URL')).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
adminApi.interceptors.request.use(requestInterceptorWithAuth);
adminApi.interceptors.response.use(null, handleResponseInterceptorError);

export const activationApi = axios.create({
  baseURL: new URL(
    '/api-admin/self-activation',
    env('REACT_APP_ACTIVATION_API_URL'),
  ).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
activationApi.interceptors.request.use(requestInterceptorWithAuth);
activationApi.interceptors.response.use(null, handleResponseInterceptorError);

export const moyoOnlyApi = axios.create({
  baseURL: new URL(
    'api-admin/mobile-plan-meta/moyo-only/',
    env('REACT_APP_API_URL'),
  ).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});
moyoOnlyApi.interceptors.request.use(requestInterceptorWithAuth);
moyoOnlyApi.interceptors.response.use(null, handleResponseInterceptorError);

//사용하지 않는 API
// export const scrapApi = axios.create({
//   baseURL: new URL(
//     '/api/v1/internal-admin/order/mvno/',
//     env('REACT_APP_ORDER_API_URL'),
//   ).href,
//   headers: {
//     'Content-type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   },
// });
// scrapApi.interceptors.request.use(requestInterceptorWithAuth);

export const internetApi = axios.create({
  baseURL: new URL('/api-admin/v1', env('REACT_APP_INTERNET_API_URL')).href,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': '*',
  },
  withCredentials: true,
});

internetApi.interceptors.request.use(requestInterceptorWithAuth);
internetApi.interceptors.response.use(null, handleResponseInterceptorError);

const onAuthFailed = (navigate: NavigateFunction) => {
  if (window && window.location) {
    navigate(window.location.pathname, {
      replace: true,
      state: {
        authErrorMessage: '인증에 실패했습니다. 로그인해주세요.',
      },
    });
  }
};

export async function handleApi<T>(
  apiCall: () => Promise<AxiosResponse<ApiResponse<T>>>,
  navigate: NavigateFunction,
  onError?: (error: string) => void,
): Promise<T | undefined> {
  return apiCall()
    .then((res: AxiosResponse<ApiResponse>) => {
      const response = res.data;

      switch (response.resultType) {
        case 'SUCCESS':
          return response.result;
        case 'UNAUTHORIZED':
          onAuthFailed(navigate);
          break;
        default:
          throw new Error(response.error);
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        onAuthFailed(navigate);
        return;
      }

      if (onError) {
        onError(error.message);
      }
    });
}

export async function handleApiV2<T>(
  apiCall: () => Promise<AxiosResponse<ApiResponseV2<T>>>,
  navigate: NavigateFunction,
  onError?: (error: string) => void,
): Promise<T | undefined> {
  return apiCall()
    .then((res: AxiosResponse<ApiResponseV2>) => {
      const response = res.data;

      switch (response.result) {
        case 'SUCCESS':
          return response.data;
        default:
          throw new Error(response.error?.message);
      }
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 401) {
        onAuthFailed(navigate);
        return;
      }

      if (onError) {
        onError(error.message);
      }
    });
}

const handleAuthError = (error: unknown) => {
  if (error instanceof AuthError) {
    console.error(error.message);
    error.handler();
  }
};
