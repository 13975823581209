import { PlanMetaHistory } from '../../../entity/plan';
import { BaseSelectPlanPopup } from './BaseSelectPlanPopup';
import { useOrderPlanUpdate } from '../hooks/useOrderPlanUpdate';

type SelectPlanPopupProps = {
  orderId: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  mvno: string;
  originalPlanName: string;
  changePlan?: PlanMetaHistory;
};

export function SelectPlanPopup({
  orderId,
  open,
  onClose,
  onSubmit,
  mvno,
  changePlan,
}: SelectPlanPopupProps) {
  const { planUpdateMutation } = useOrderPlanUpdate(onSubmit ?? (() => {}));

  const updateOrderPlan = async (planId: number, planName: string) => {
    try {
      await planUpdateMutation.mutateAsync({
        orderId,
        planId,
        planName,
      });

      alert('요금제 변경이 완료되었습니다.');
    } catch (err) {
      console.log(err);
      alert('문제가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <BaseSelectPlanPopup
      orderId={orderId}
      open={open}
      onClose={onCloseHandler}
      onSubmit={updateOrderPlan}
      mvno={mvno}
      changePlan={changePlan}
    />
  );
}
