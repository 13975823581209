import { Box, Stack, Switch, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BasicInfoDto } from '../../types/mobile-plan-operator-admin-types';
import { validate } from '../../utils/validate';
import { HTTPS_PROTOCOL_PREFIX } from '../../constants';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const MvnoInfoBasicEdit: FC<BasicInfoDto> = (data) => {
  const [isPcUrlSame, setIsPcUrlSame] = useState(
    data.pcHomepageUrl === data.mobileHomepageUrl,
  );
  const {
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<BasicInfoDto>();

  const handleChangeIsSamePcLink = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsPcUrlSame(event.target.checked);
  };

  useEffect(() => {
    if (isPcUrlSame) {
      setValue('mobileHomepageUrl', getValues('pcHomepageUrl'));
    }
  }, [watch('pcHomepageUrl'), isPcUrlSame]);

  return (
    <>
      <MvnoInfoItemBody gap={7}>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name="businessName"
            defaultValue={data.businessName}
            rules={{
              required: '법인명을 입력해주세요.',
            }}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                error={!!errors.businessName?.message}
                helperText={errors.businessName?.message}
                {...field}
                fullWidth
                label="법인명"
              />
            )}
          />
          <Controller
            control={control}
            name="businessRegistrationNumber"
            defaultValue={data.businessRegistrationNumber}
            rules={{
              required: '사업자등록번호를 입력해주세요.',
            }}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                error={!!errors.businessRegistrationNumber?.message}
                helperText={errors.businessRegistrationNumber?.message}
                {...field}
                fullWidth
                label="사업자등록번호"
              />
            )}
          />
        </MvnoInfoItemRow>
        <Box>
          <Controller
            control={control}
            name="address"
            rules={{
              required: '회사 주소를 입력해주세요.',
            }}
            defaultValue={data.address}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                error={!!errors.address?.message}
                helperText={errors.address?.message}
                {...field}
                fullWidth
                label="회사 주소"
              />
            )}
          />
        </Box>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name="pcHomepageUrl"
            defaultValue={data.pcHomepageUrl}
            render={() => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                {...register('pcHomepageUrl', {
                  validate: {
                    isUrl: (value) =>
                      !value
                        ? '통신사 홈페이지를 입력해주세요.'
                        : validate.validateUrlURLFormat(value),
                  },
                  onBlur: (event) => {
                    setValue('pcHomepageUrl', event.target.value, {
                      shouldValidate: true,
                    });
                  },
                })}
                helperText={errors.pcHomepageUrl?.message}
                error={!!errors.pcHomepageUrl?.message}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      {HTTPS_PROTOCOL_PREFIX}
                    </Typography>
                  ),
                }}
                fullWidth
                placeholder="예: moyoplan.com"
                label="통신사 홈페이지 (PC)"
              />
            )}
          />
          <Controller
            control={control}
            name="mobileHomepageUrl"
            defaultValue={data.mobileHomepageUrl}
            render={() => (
              <TextField
                {...register('mobileHomepageUrl', {
                  validate: {
                    isUrl: (value) =>
                      !value
                        ? '통신사 홈페이지를 입력해주세요.'
                        : validate.validateUrlURLFormat(value),
                  },
                  onBlur: (event) => {
                    setValue('mobileHomepageUrl', event.target.value, {
                      shouldValidate: true,
                    });
                  },
                })}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      {HTTPS_PROTOCOL_PREFIX}
                    </Typography>
                  ),
                }}
                helperText={errors.mobileHomepageUrl?.message}
                error={!!errors.mobileHomepageUrl?.message}
                fullWidth
                disabled={isPcUrlSame}
                placeholder="예: moyoplan.com"
                label="통신사 홈페이지 (모바일)"
              />
            )}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
      <Box sx={{ marginTop: '10px' }}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
          <Typography>PC 링크와 동일</Typography>
          <Switch
            defaultChecked={isPcUrlSame}
            value={isPcUrlSame}
            onChange={handleChangeIsSamePcLink}
          />
        </Stack>
      </Box>
    </>
  );
};

export default MvnoInfoBasicEdit;
