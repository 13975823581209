import { useAuth } from '../context/UserContext';
import { Permission } from '../types/AuthType';
import {
  useNavigationPermission,
  NavigationPermission,
} from './useNavigationPermission';

export function useCheckPermission() {
  const { permissions } = useAuth();
  const { navigationPermissions } = useNavigationPermission();

  const checkPermission = (
    requiredPermissions: Permission[],
    navigationPermissionsAll: NavigationPermission[],
    navigationPermissionsAny: NavigationPermission[],
  ) => {
    if (requiredPermissions.length > 0) {
      if (
        !requiredPermissions.every((permission) =>
          permissions.includes(permission),
        )
      ) {
        return false;
      }
    }

    if (navigationPermissionsAny.length > 0) {
      if (
        !navigationPermissionsAny.some((permission) =>
          navigationPermissions.includes(permission),
        )
      ) {
        return false;
      }
    }

    if (navigationPermissionsAll.length > 0) {
      if (
        !navigationPermissionsAll.every((permission) =>
          navigationPermissions.includes(permission),
        )
      ) {
        return false;
      }
    }

    return true;
  };

  return { checkPermission };
}
