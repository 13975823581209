import { amountStringToInt } from '../../../../common/string_util';
import { PlanMetaV2 } from '../../../../entity/plan-v2';
import { OnValidateProps } from '../../types';
import { convertTwelveMonthFee } from '../../util/convertTwelveMonthFee';

export function onValidateOriginalFee(props: OnValidateProps) {
  const {
    originalFee,
    discountFee,
    discountPeriod,
    isFreeDiscount,
    maxAmount,
    similarPlan,
  } = props;

  if (isFreeDiscount) return true;
  if (!originalFee) return '할인 종료 후 요금 필수입니다';

  const numericOriginalFee = amountStringToInt(originalFee);
  const numericDiscountFee = amountStringToInt(discountFee || '');

  if (maxAmount) {
    if (numericOriginalFee.toString().length > maxAmount)
      return '100만원 이하만 입력 가능해요.';
  }

  if (!isFreeDiscount && numericDiscountFee > numericOriginalFee) {
    return '할인 시 요금이 종료 후 요금보다 비싸면 안돼요';
  }

  if (
    similarPlan &&
    !onIsTwelveFeeValidate({
      originalFee,
      discountFee,
      discountPeriod,
      isFreeDiscount,
      similarPlan,
    })
  ) {
    return false;
  }

  return true;
}

export function onValidateDiscountFee(props: OnValidateProps) {
  const {
    originalFee,
    discountFee,
    discountPeriod,
    isFreeDiscount,
    maxAmount,
    similarPlan,
  } = props;
  if (discountFee == null) return '할인 시 요금은 필수입니다';
  const numericDiscountFee = amountStringToInt(discountFee);

  if (maxAmount) {
    if (!isFreeDiscount && numericDiscountFee > maxAmount) {
      return '100만원 이하만 입력 가능해요.';
    }
  }

  if (!isFreeDiscount && originalFee && numericDiscountFee > originalFee) {
    return '할인 시 요금이 종료 후 요금보다 비싸면 안돼요';
  }

  if (
    similarPlan &&
    !onIsTwelveFeeValidate({
      originalFee,
      discountFee,
      discountPeriod,
      isFreeDiscount,
      similarPlan,
    })
  ) {
    return false;
  }

  return true;
}

export function onValidateDiscountPeriod(props: OnValidateProps) {
  const {
    originalFee,
    discountFee,
    discountPeriod,
    isFreeDiscount,
    similarPlan,
  } = props;

  const numericDiscountPeriod = amountStringToInt(discountPeriod || '');

  if (!isFreeDiscount) {
    if (numericDiscountPeriod < 1) {
      return '할인 기간을 1개월 이상으로 설정해 주세요.';
    }

    if (numericDiscountPeriod > 120) {
      return '할인 기간은 120개월까지만 지원해요.';
    }
  }

  if (
    similarPlan &&
    !onIsTwelveFeeValidate({
      originalFee,
      discountFee,
      discountPeriod,
      isFreeDiscount,
      similarPlan,
    })
  ) {
    return false;
  }

  return true;
}

export function onIsTwelveFeeValidate(props: OnValidateProps) {
  const {
    originalFee,
    discountFee,
    discountPeriod,
    isFreeDiscount,
    similarPlan,
  } = props;
  const calculatedTwelveMonthFee = convertTwelveMonthFee({
    originalFee,
    discountFee,
    discountPeriod,
    isFreeDiscount,
  });

  if (similarPlan?.twMonthFee && calculatedTwelveMonthFee) {
    return similarPlan.twMonthFee >= calculatedTwelveMonthFee + 1000;
  }
}
