import { Box } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import SwitchEditableMvnoInfoEdit from './SwitchEditableMvnoInfoEdit';
import SwitchEditableMvnoInfoDetail from './SwitchEditableMvnoInfoDetail';
import { DefaultValues, FieldValues } from 'react-hook-form';
import { MvnoInfoPutFunction } from '../api/mvno-info-api-service';
import pbl from '../../../pbl/pbl-service/pbl-service';

interface Props<T> {
  title: string;
  subTitle?: string;
  put?: MvnoInfoPutFunction<T>;
  defaultValues?: DefaultValues<T>;
  detail: ReactNode;
  edit: ReactNode;
  enableEdit?: boolean;
  logObjectId?: string;
}

const SwitchEditableMvnoInfo = <T extends FieldValues>({
  title,
  subTitle,
  detail,
  edit,
  enableEdit,
  put,
  defaultValues,
  logObjectId,
}: Props<T>) => {
  const [isEdit, setIsEdit] = useState(false);

  const onClickEdit = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: logObjectId,
      },
    });
    setIsEdit(true);
  };
  const onCancelEdit = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: `${logObjectId}_cancel`,
      },
    });
    setIsEdit(false);
  };

  const onEditComplete = () => {
    setIsEdit(false);
  };

  return (
    <Box
      sx={{
        py: 2,
      }}
    >
      {isEdit ? (
        <SwitchEditableMvnoInfoEdit<T>
          title={title}
          subTitle={subTitle}
          put={put}
          defaultValues={defaultValues}
          onCancelEdit={onCancelEdit}
          onEditComplete={onEditComplete}
        >
          {edit}
        </SwitchEditableMvnoInfoEdit>
      ) : (
        <SwitchEditableMvnoInfoDetail
          title={title}
          subTitle={subTitle}
          onClickEdit={onClickEdit}
          enableEdit={enableEdit}
        >
          {detail}
        </SwitchEditableMvnoInfoDetail>
      )}
    </Box>
  );
};

export default SwitchEditableMvnoInfo;
