import { AxiosResponse } from 'axios';
import { orderApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import {
  MobilePlanOrder,
  MobilePlanOrderListDto,
  OrderHandleAssignDto,
  OrderStatusOptions,
} from '../entity/order';
import qs from 'query-string';
import { AdminUser } from '../entity/admin';
import { DeliveryInfo } from '../entity/delivery';
import OrderChangeBill, {
  OrderChangeBillListDto,
} from '../entity/orderChangeBill';

class OrderApiService {
  getUser(): Promise<AxiosResponse<ApiResponse<AdminUser>>> {
    return orderApi.get('user');
  }

  getList(
    data: any,
    page: number,
    size: number,
    sortField?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrderListDto>>> {
    let queryParams: any = {
      page,
      size,
    };

    if (sortField && sortDirection) {
      queryParams = {
        ...queryParams,
        sort: `${sortField},${sortDirection}`,
      };
    }

    return orderApi.post(`list?${qs.stringify(queryParams)}`, data);
  }

  getOrderChangeBillList(
    data: any,
    page: number,
    size: number,
    sortField?: string,
    sortDirection?: string,
  ): Promise<AxiosResponse<ApiResponse<OrderChangeBillListDto>>> {
    let queryParams: any = {
      page,
      size,
    };

    if (sortField && sortDirection) {
      queryParams = {
        ...queryParams,
        sort: `${sortField},${sortDirection}`,
      };
    }

    return orderApi.post(`/bill/list?${qs.stringify(queryParams)}`, data);
  }

  getOrderChangeBill(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<OrderChangeBill>>> {
    return orderApi.get(`/bill/${id}`);
  }

  confirmChangeBill(id: string): Promise<AxiosResponse<ApiResponse<boolean>>> {
    return orderApi.post(`/bill/confirm/${id}`, {});
  }

  exportToExcel(data: any): Promise<AxiosResponse<any>> {
    return orderApi.post(`export/excel`, data, {
      responseType: 'arraybuffer',
    });
  }

  exportDelayExcel(data: any): Promise<AxiosResponse<any>> {
    return orderApi.post(`list/delay/export/excel`, data, {
      responseType: 'arraybuffer',
    });
  }

  exportExcelForUinplus(data: any): Promise<AxiosResponse<any>> {
    return orderApi.post(`/export/excel/uinplus`, data, {
      responseType: 'arraybuffer',
    });
  }

  uploadDeliveryInfoExcel(
    formData: FormData,
  ): Promise<AxiosResponse<ApiResponse<boolean>>> {
    return orderApi.post('upload/excel/delivery', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  uploadUsimInfoExcel(
    formData: FormData,
  ): Promise<AxiosResponse<ApiResponse<boolean>>> {
    return orderApi.post('upload/excel/usim', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  uploadUsimOrDeliveryInfoExcel(
    formData: FormData,
    uploadTypes: string,
  ): Promise<AxiosResponse<ApiResponse<boolean>>> {
    return orderApi.post('upload/excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        uploadTypes,
      },
    });
  }

  getOrder(
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.get(`/detail/${orderId}`);
  }

  getOrderStatusOptions(): Promise<
    AxiosResponse<ApiResponse<OrderStatusOptions>>
  > {
    return orderApi.get('/status');
  }

  updateOrderStatus(
    orderIds: Array<string>,
    status?: string,
    statusDetail?: string,
    memo?: string,
    sendAlarmTalk: boolean = true,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrderListDto>>> {
    return orderApi.put('/status', {
      ids: orderIds,
      status,
      statusDetail,
      memo,
      sendAlarmTalk,
    });
  }

  getAlarmTalkTemplate(
    orderId: string,
    status?: string,
  ): Promise<AxiosResponse<ApiResponse<string>>> {
    return orderApi.get(`/status/${orderId}`, {
      params: {
        status,
      },
    });
  }

  updateOrder(
    orderId: string,
    planId: number,
    planName: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put(`/${orderId}/plan-change`, {
      planId,
      planName,
    });
  }

  updateSelfAssign({
    orderIds,
    allowOverwrite,
  }: OrderHandleAssignDto): Promise<
    AxiosResponse<ApiResponse<OrderHandleAssignDto>>
  > {
    return orderApi.post('/handler-assignment', {
      orderIds,
      allowOverwrite,
    });
  }

  confirmChangePlan(
    orderId: number,
    userId: number,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put('/plan-change', {
      orderId: orderId,
      userId: userId,
    });
  }

  editOrder(
    orderId: string,
    data: MobilePlanOrder,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put(`/detail/${orderId}`, data);
  }

  updateOrderPhoneNumber(
    orderId: string,
    orderPhoneNumber: string,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.put('/orderPhoneNumber', {
      id: orderId,
      orderPhoneNumber,
    });
  }

  /**
   * 주문 정보 복구
   * - 고객 요청으로 취소되었다가 다시 주문이 진행되는 경우 파기된 개인정보 복구가 필요함
   * @param orderId
   */
  restoreOrder(
    orderId: number,
  ): Promise<AxiosResponse<ApiResponse<MobilePlanOrder>>> {
    return orderApi.post('/restore', {
      orderId,
    });
  }
  getDelivery(
    orderId: string,
  ): Promise<AxiosResponse<ApiResponse<DeliveryInfo>>> {
    return orderApi.get('/delivery/order/' + orderId);
  }
}

export const orderApiService = new OrderApiService();
