import { PlanManagementMethodType } from '../../MvnoInfoPage/types/mobile-plan-operator-admin-types';

type PlanManagementMethodList = PlanManagementMethodType[] | undefined;

/**
 *
 * @description 모요온리 여부에 따라서 PermissionRender Props에 들어갈 권한 반환하는 함수
 */
export function getPermissionsRenderPropsByMoyoOnly(isMoyoOnly: boolean) {
  const registerPlanManagementMethodList: PlanManagementMethodList = isMoyoOnly
    ? undefined
    : ['MANUAL'];
  const registerPlanIsRequireMoyoAdmin = !isMoyoOnly;

  // 요금제 수정은 수동 관리만 가능
  const editPlanPlanManagementMethodList: PlanManagementMethodList = isMoyoOnly
    ? undefined
    : ['MANUAL'];

  // 요금제 종료는 수동 관리와 스크랩핑만 가능
  const endPlanManagementMethodList: PlanManagementMethodList = isMoyoOnly
    ? undefined
    : ['SCRAPING', 'MANUAL'];

  const notificationPlanManagementMethodList: PlanManagementMethodList = [
    'API',
  ];

  return {
    planList: {
      registerPlanManagementMethodList,
      registerPlanIsRequireMoyoAdmin,
    },
    planDetail: {
      editPlanPlanManagementMethodList,
      endPlanManagementMethodList,
      notificationPlanManagementMethodList,
    },
  };
}
