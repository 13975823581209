import React, { FC } from 'react';
import { PblObjectTypes } from '../pbl-types/pbl-object-types';
import { IBehaviorLogOptions } from '../pbl-service/create-pbl-service';
import pbl from '../pbl-service/pbl-service';
import ImpressionTracker from './ImpressionTracker';

interface Props {
  children: React.ReactElement;
  logObjectType: PblObjectTypes;
  logOptions?: IBehaviorLogOptions;
  allowClickEvent?: boolean;
  debounce?: number;
  trackingOneTime?: boolean;
  trackerOffset?: string;
  trackingThreshold?: number;
}

const ImpressionLogTracker: FC<Props> = ({
  logObjectType,
  logOptions,
  allowClickEvent,
  ...rest
}: Props) => {
  const onImpressionEventHandler = () => {
    pbl('impression', logObjectType, 'here', logOptions);
  };

  const onClickEventHandler = () => {
    if (allowClickEvent) {
      pbl('click', logObjectType, 'here', logOptions);
    }
  };

  return (
    <ImpressionTracker
      onImpressionEvent={onImpressionEventHandler}
      onClickEvent={onClickEventHandler}
      {...rest}
    />
  );
};

export default ImpressionLogTracker;
