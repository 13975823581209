import { DateTime } from 'luxon';
import { ICreatePlanDto } from '../../../entity/plan';
import { getISOSimpleFormat } from '../../../utils/dateFormatter';
import { planInfoPrefixService } from '../logic/plan-info-prefix-service';

export const formatPlanData = (
  data: ICreatePlanDto,
  date?: DateTime | null,
) => {
  const prefixedData = planInfoPrefixService.appendPrefix(data);

  return {
    ...prefixedData,
    basicData: prefixedData.basicData
      ? Number(prefixedData.basicData.toString().replace(/,/g, ''))
      : 0,
    dailyData: prefixedData.dailyData
      ? Number(prefixedData.dailyData.toString().replace(/,/g, ''))
      : 0,
    discountFee: prefixedData.discountFee
      ? Number(prefixedData.discountFee.toString().replace(/,/g, ''))
      : 0,
    discountPeriod: prefixedData.discountPeriod,
    originalFee: prefixedData.originalFee
      ? Number(prefixedData.originalFee.toString().replace(/,/g, ''))
      : 0,
    reserveTime: date ? getISOSimpleFormat(date) : '',
  };
};
