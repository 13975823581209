import { Box, Divider, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import MvnoInfoItem from '../Common/MvnoInfoItem';
import {
  CustomerCenterInfo,
  CustomerServiceContact,
  OperatingHoursInfo,
} from '../../types/mobile-plan-operator-admin-types';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { useThemeControl } from '../../../../context/ThemeControlContext';
import dayjs from 'dayjs';
import { MvnoInfoItemBody } from '../Common/MvnoInfoItemFrame';
import Join from '../../../../shared/join';

const TextInfoWithIcon: FC<{ icon: ReactNode; text: string }> = ({
  icon,
  text,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      {icon}
      <Typography variant="caption" color="text.primary">
        {text}
      </Typography>
    </Box>
  );
};

/**
 *
 * @param time 'HH:mm' or 'HH:mm:ss' or 'HH:mm:ss.SSS'
 */
const formatHHmm = (time: string) => {
  return dayjs(`${dayjs().format('YYYY-MM-DD')} ${time}`).format('HH:mm');
};

const MvnoInfoCustomerCenterDetailSection: FC<{
  mno: Mno;
  customerServiceContact?: CustomerServiceContact;
  operatingHoursInfo?: OperatingHoursInfo;
}> = ({ mno, customerServiceContact, operatingHoursInfo }) => {
  const title = convertStringToMno(mno);
  const 운영시간 = operatingHoursInfo
    ? `${formatHHmm(operatingHoursInfo.openTime)} - ${formatHHmm(
        operatingHoursInfo.closeTime,
      )}`
    : '';
  const 점심시간 =
    operatingHoursInfo?.lunchStartTime && operatingHoursInfo?.lunchEndTime
      ? `${formatHHmm(operatingHoursInfo.lunchStartTime)} - ${formatHHmm(
          operatingHoursInfo.lunchEndTime,
        )}`
      : '';

  const processedClosedDays = operatingHoursInfo?.closedDays;

  return (
    <MvnoInfoItemBody gap={3}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
        <Join
          separator={<Divider orientation="vertical" sx={{ height: '12px' }} />}
        >
          {customerServiceContact?.phoneNumber && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <PhoneIcon />
              {customerServiceContact.phoneNumber ? (
                <Typography variant="body1">
                  {customerServiceContact.phoneNumber}
                </Typography>
              ) : (
                <Typography color={'error'} variant="body1">
                  입력 필요
                </Typography>
              )}
            </Box>
          )}
          {/* <TextInfoWithIcon icon={<MessageIcon />} text="#{채널톡 url}" />
          <TextInfoWithIcon icon={<MailIcon />} text={'#{help@moyoplan.com}'} /> */}
        </Join>
      </Box>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <MvnoInfoItem
          fallback={{
            text: '입력 필요',
            color: 'error',
          }}
          title="운영시간"
          content={운영시간}
        />
        <MvnoInfoItem
          fallback={{
            text: '없음',
          }}
          title="점심시간"
          content={점심시간}
        />
        <MvnoInfoItem
          fallback={{
            text: '없음',
          }}
          title="휴무일"
          content={processedClosedDays}
        />
      </Box>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoCustomerCenterDetail: FC<{
  customerCenterInfo: CustomerCenterInfo;
}> = ({ customerCenterInfo }) => {
  return (
    <MvnoInfoItemBody gap={6}>
      {Object.values(['LGU', 'KT', 'SKT']).map((mno) => {
        const customerServiceContact =
          customerCenterInfo.customerServiceContactList[mno as Mno];
        const operatingHoursInfo =
          customerCenterInfo.operatingHoursInfoList[mno as Mno];

        if (!customerServiceContact && !operatingHoursInfo) {
          return <></>;
        }
        return (
          <MvnoInfoCustomerCenterDetailSection
            key={mno}
            mno={mno as Mno}
            customerServiceContact={customerServiceContact}
            operatingHoursInfo={operatingHoursInfo}
          />
        );
      })}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoCustomerCenterDetail;

const PhoneIcon = () => {
  const themeControl = useThemeControl();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.8375 3.59687L12.1734 1.93438C12.0561 1.81647 11.9165 1.72293 11.7629 1.65911C11.6092 1.59529 11.4445 1.56246 11.2781 1.5625C10.9391 1.5625 10.6203 1.69531 10.3813 1.93438L8.59063 3.725C8.47273 3.84238 8.37918 3.98191 8.31536 4.13555C8.25154 4.2892 8.21871 4.45394 8.21875 4.62031C8.21875 4.95938 8.35156 5.27813 8.59063 5.51719L9.9 6.82656C9.59351 7.50213 9.16738 8.1167 8.64219 8.64062C8.11831 9.16709 7.50379 9.59477 6.82813 9.90312L5.51875 8.59375C5.40137 8.47585 5.26184 8.3823 5.1082 8.31849C4.95455 8.25467 4.78981 8.22184 4.62344 8.22188C4.28438 8.22188 3.96563 8.35469 3.72656 8.59375L1.93438 10.3828C1.81633 10.5004 1.7227 10.6402 1.65888 10.7941C1.59506 10.948 1.56231 11.1131 1.5625 11.2797C1.5625 11.6188 1.69531 11.9375 1.93438 12.1766L3.59531 13.8375C3.97656 14.2203 4.50313 14.4375 5.04375 14.4375C5.15781 14.4375 5.26719 14.4281 5.375 14.4094C7.48125 14.0625 9.57031 12.9422 11.2563 11.2578C12.9406 9.575 14.0594 7.4875 14.4109 5.375C14.5172 4.72969 14.3031 4.06562 13.8375 3.59687V3.59687Z"
        fill={themeControl.theme.palette.action.active}
      />
    </svg>
  );
};

const MessageIcon = () => {
  const themeControl = useThemeControl();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.4423 5.28751C14.0918 4.45083 13.5818 3.69035 12.9408 3.04845C12.3017 2.40543 11.5425 1.8942 10.7064 1.54376C9.84985 1.18303 8.92954 0.998121 8.00014 1.00001H7.96889C7.02358 1.0047 6.10951 1.1922 5.24858 1.56095C4.41962 1.91498 3.66759 2.42712 3.03451 3.06876C2.39972 3.70922 1.89556 4.46705 1.55014 5.30001C1.19157 6.16621 1.00988 7.09537 1.01576 8.03283C1.02045 9.1172 1.27983 10.1938 1.7642 11.1563V13.5313C1.7642 13.9281 2.08608 14.25 2.48139 14.25H4.85326C5.82025 14.738 6.88734 14.9947 7.97045 15H8.00326C8.93764 15 9.84233 14.8188 10.6955 14.4641C11.5273 14.1178 12.2837 13.6125 12.922 12.9766C13.5658 12.3375 14.072 11.5906 14.4267 10.7578C14.7939 9.89533 14.9814 8.97814 14.9861 8.03126C14.9892 7.0797 14.8048 6.15626 14.4423 5.28751ZM4.88139 8.75001C4.46889 8.75001 4.13295 8.41408 4.13295 8.00001C4.13295 7.58595 4.46889 7.25001 4.88139 7.25001C5.29389 7.25001 5.62983 7.58595 5.62983 8.00001C5.62983 8.41408 5.29545 8.75001 4.88139 8.75001ZM8.00014 8.75001C7.58764 8.75001 7.2517 8.41408 7.2517 8.00001C7.2517 7.58595 7.58764 7.25001 8.00014 7.25001C8.41264 7.25001 8.74858 7.58595 8.74858 8.00001C8.74858 8.41408 8.41264 8.75001 8.00014 8.75001ZM11.1189 8.75001C10.7064 8.75001 10.3705 8.41408 10.3705 8.00001C10.3705 7.58595 10.7064 7.25001 11.1189 7.25001C11.5314 7.25001 11.8673 7.58595 11.8673 8.00001C11.8673 8.41408 11.5314 8.75001 11.1189 8.75001Z"
        fill={themeControl.theme.palette.action.active}
      />
    </svg>
  );
};

const MailIcon = () => {
  const themeControl = useThemeControl();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.333 2.6665H2.66634C1.93301 2.6665 1.33967 3.2665 1.33967 3.99984L1.33301 11.9998C1.33301 12.7332 1.93301 13.3332 2.66634 13.3332H13.333C14.0663 13.3332 14.6663 12.7332 14.6663 11.9998V3.99984C14.6663 3.2665 14.0663 2.6665 13.333 2.6665ZM13.333 5.33317L7.99967 8.6665L2.66634 5.33317V3.99984L7.99967 7.33317L13.333 3.99984V5.33317Z"
        fill={themeControl.theme.palette.action.active}
      />
    </svg>
  );
};
