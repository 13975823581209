import { nanoid } from 'nanoid';
import { LocalStorageConstants } from '../common/constants';

export function getDeviceId() {
  try {
    let deviceId = localStorage.getItem(
      LocalStorageConstants.USER_BROWSER_UNIQUE_ID,
    );

    if (!deviceId) {
      deviceId = nanoid();
      localStorage.setItem(
        LocalStorageConstants.USER_BROWSER_UNIQUE_ID,
        deviceId,
      );
    }

    return deviceId;
  } catch (error) {
    return '';
  }
}
