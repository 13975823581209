import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useEffect } from 'react';
import pbl from '../../pbl/pbl-service/pbl-service';

interface PlanUpdateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const PlanUpdateDialog: FC<PlanUpdateDialogProps> = ({
  isOpen,
  onClose,
  onUpdate,
}) => {
  useEffect(() => {
    pbl('impression', 'modal', 'here', {
      object: {
        id: 'update_plan_information',
        section: 'action_menu',
      },
    });
  }, [isOpen]);

  const onUpdateHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'update_plan_information',
        section: 'action_menu',
        name: '바로 업데이트',
      },
    });
    onUpdate();
  };

  const onCloseHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'update_plan_information_cancel',
        section: 'action_menu',
        name: '취소',
      },
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>요금제 정보 업데이트</DialogTitle>
      <DialogContent style={{ width: '372px' }}>
        <DialogContentText>요금제 정보를 최신화할게요</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onCloseHandler}>
          취소
        </Button>
        <Button variant="contained" onClick={onUpdateHandler}>
          바로 업데이트
        </Button>
      </DialogActions>
    </Dialog>
  );
};
