import React, { FC, Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  children: React.ReactElement;
  onImpressionEvent?: () => void;
  onClickEvent?: () => void;
  debounce?: number;
  trackingOneTime?: boolean;
  trackerOffset?: string;
  trackingThreshold?: number;
}

const ImpressionTracker: FC<Props> = ({
  children,
  onImpressionEvent,
  onClickEvent,
  debounce = 500,
  trackingOneTime = false,
  trackerOffset = '0px 0px 0px 0px',
  trackingThreshold = 1,
}) => {
  let recordedTimeout: NodeJS.Timeout;
  const _children = children.key ? children : <div>{children}</div>;

  useEffect(() => {
    return () => {
      if (recordedTimeout) {
        clearTimeout(recordedTimeout);
      }
    };
  }, []);

  const handleChange = (
    _: boolean,
    intersectionObserverEntry: IntersectionObserverEntry,
  ) => {
    const { target, intersectionRatio, isIntersecting } =
      intersectionObserverEntry;
    if (isIntersecting && intersectionRatio >= trackingThreshold) {
      recordedTimeout = setTimeout(() => {
        if (!(target instanceof HTMLElement)) return;
        if (!onImpressionEvent) return;
        onImpressionEvent();
      }, debounce);
      return;
    }

    if (recordedTimeout) {
      clearTimeout(recordedTimeout);
    }
  };

  const { ref } = useInView({
    triggerOnce: trackingOneTime,
    rootMargin: trackerOffset,
    threshold: trackingThreshold,
    onChange: handleChange,
  });

  const handleClick = () => {
    if (!onClickEvent) return;
    onClickEvent();
    _children.props.onClick && _children.props.onClick();
  };

  return (
    <Fragment>
      {React.cloneElement(_children, {
        onClick: handleClick,
        ref,
      })}
    </Fragment>
  );
};

export default ImpressionTracker;
