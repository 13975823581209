import { useMemo } from 'react';
import { comma } from '../../../common/string_util';
import { ConvertedTwelveFeeContainer } from '../styled';
import { Typography } from '@mui/material';
import { PlanMetaV2 } from '../../../entity/plan-v2';

export const ConvertedTwelveMonthFeeContent = ({
  variant,
  similarPlan,
  convertedTwelveMonthFee,
}: {
  variant: 'error' | 'success' | 'default';
  similarPlan?: PlanMetaV2 | null;
  convertedTwelveMonthFee: number | null | undefined;
}) => {
  const formattedConvertedTwelveMonthFee = useMemo(() => {
    return convertedTwelveMonthFee
      ? `${comma(convertedTwelveMonthFee)}원`
      : undefined;
  }, [convertedTwelveMonthFee]);

  return (
    <ConvertedTwelveFeeContainer variant={variant}>
      <Typography variant="body1">
        12개월 환산 금액: {formattedConvertedTwelveMonthFee}
      </Typography>
      {similarPlan && (
        <FailedFeeValidateDescription
          network={similarPlan?.net}
          planName={similarPlan?.name}
          twelveMonthFee={similarPlan?.twMonthFee}
        />
      )}
    </ConvertedTwelveFeeContainer>
  );
};

const FailedFeeValidateDescription = ({
  network,
  planName,
  twelveMonthFee,
}: {
  network?: string;
  planName?: string;
  twelveMonthFee?: number;
}) => {
  return (
    <Typography variant="body2">
      아래 요금제보다 12개월 환산 금액이 1,000원 이상 저렴해야 해요.
      <li>기준 요금제: {`[${network}] ${planName}`}</li>
      <li>기준 요금: {`${comma(twelveMonthFee)}`}원</li>
    </Typography>
  );
};
