import styled from '@emotion/styled';
import {
  Box,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from '@mui/material';
import { useAuth } from '../../context/UserContext';
import { CheckCircle, Cancel, ContentPaste } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import { authApiService } from '../../api/auth';
import { useState } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default function IssueOtpPage() {
  const { user, logoutUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [otpAuthUrl, setOtpAuthUrl] = useState('');

  async function issueOtp() {
    try {
      const { data } = await authApiService.issueOtp();
      setOtpAuthUrl(data?.result?.otpAuthUrl ?? '');
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function copyToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      setIsSnackbarOpen(true);
    } catch (error) {
      console.error(error);
    }
  }

  function onDialogClose() {
    setIsOpen(false);
    alert('다시 로그인해주세요.');
    logoutUser();
  }

  return (
    <Container>
      <Box gap={4} display="flex" flexDirection="column">
        <Typography variant="h5" fontWeight={700}>
          OTP 인증
        </Typography>
        <Divider />
        <Box display="flex" gap={1}>
          <Typography>OTP 활성화 여부:</Typography>
          {user?.otpEnabled ? (
            <CheckCircle color="success" />
          ) : (
            <Cancel color="error" />
          )}
        </Box>
        <Box>
          <Alert severity="warning">
            {user?.otpEnabled
              ? '이 계정은 OTP가 활성화 되어있어요. OTP가 활성화된 상태에서 새로 링크를 발급받으면 기존 링크는 무효화되니 주의해주세요.'
              : 'OTP가 비활성화되어 있어요. 아래 버튼을 눌러 OTP를 활성화해주세요.'}
          </Alert>
        </Box>
        <Button variant="contained" onClick={issueOtp}>
          OTP 링크 발급하기
        </Button>
      </Box>
      <Dialog open={isOpen} onClose={onDialogClose}>
        <DialogTitle>OTP 링크 발급하기</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>{otpAuthUrl}</DialogContentText>
          <Button
            variant="outlined"
            startIcon={<ContentPaste />}
            onClick={() => copyToClipboard(otpAuthUrl)}
          >
            복사
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose}>확인</Button>
        </DialogActions>
      </Dialog>
      {/* snackbars */}
      <Snackbar
        open={isSnackbarOpen}
        message="클립보드에 복사되었습니다."
        onClose={() => setIsSnackbarOpen(false)}
      />
    </Container>
  );
}
