import { FC } from 'react';
import { MvnoInfoItemBody } from '../../MvnoInfoPage/Section/Common/MvnoInfoItemFrame';
import { Typography } from '@mui/material';
import MvnoInfoItem from '../../MvnoInfoPage/Section/Common/MvnoInfoItem';
import styled from '@emotion/styled';
import { PlanUnitPlanNoticeDto } from '../../../entity/plan-v2';

const _Ul = styled.ul`
  margin: 0px;
  padding: 0 0 0 16px;
  li:not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface Props {
  planUnitPlanNoticeList: PlanUnitPlanNoticeDto[];
}

const PlanNotice: FC<Props> = ({ planUnitPlanNoticeList }) => {
  return (
    <MvnoInfoItemBody gap={4}>
      <Typography variant="h6">요금제 공지</Typography>
      <MvnoInfoItem
        title="요금제 공지"
        fallback={{
          isActive: planUnitPlanNoticeList.length === 0,
          text: '없음',
        }}
        content={
          <_Ul>
            {planUnitPlanNoticeList.map((notice) => (
              <li key={notice.templateId}>{notice.content}</li>
            ))}
          </_Ul>
        }
      />
    </MvnoInfoItemBody>
  );
};

export default PlanNotice;
