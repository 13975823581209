import { FC } from 'react';
import MvnoInfoItem from '../Common/MvnoInfoItem';
import { BasicInfoDto } from '../../types/mobile-plan-operator-admin-types';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';

const MvnoInfoBasicDetail: FC<BasicInfoDto> = ({
  address,
  brandName,
  businessName,
  businessRegistrationNumber,
  mobileHomepageUrl,
  pcHomepageUrl,
}) => {
  return (
    <MvnoInfoItemBody gap={3}>
      <MvnoInfoItemRow gap={3} numberOfRow={3}>
        <MvnoInfoItem
          fallback={{
            text: '입력 필요',
            color: 'error',
          }}
          title="법인명"
          content={businessName}
        />
        <MvnoInfoItem
          fallback={{
            text: '입력 필요',
            color: 'error',
          }}
          title="사업자등록번호"
          content={businessRegistrationNumber}
        />
      </MvnoInfoItemRow>
      <MvnoInfoItemRow gap={3}>
        <MvnoInfoItem
          fallback={{
            text: '입력 필요',
            color: 'error',
          }}
          title="주소"
          content={address}
        />
        <MvnoInfoItem
          fallback={{
            text: '입력 필요',
            color: 'error',
          }}
          title="홈페이지 (PC)"
          content={pcHomepageUrl}
        />
        <MvnoInfoItem
          fallback={{
            text: '없음',
          }}
          title="홈페이지 (모바일)"
          content={mobileHomepageUrl}
        />
      </MvnoInfoItemRow>
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoBasicDetail;
