import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteKey, routes as serviceRoutes } from '../../router/routes';

export default function RouteExcept({
  routes,
  children,
}: {
  routes: RouteKey[];
  children: ReactNode;
}) {
  const pathnames = routes.map((r) => serviceRoutes[r].path);
  const location = useLocation();
  const matched = pathnames.some((pathname) =>
    location.pathname.includes(pathname as string),
  );

  return matched ? null : <>{children}</>;
}
