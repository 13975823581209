import { FC } from 'react';
import {
  ExceedRateList,
  RateDetails,
} from '../../types/mobile-plan-operator-admin-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import TextFieldWithEndAdornment from '../../../../shared/components/custom-mui/TextFieldWithEndAdornment';
import NumericFormatComponent from '../../../../shared/components/custom-mui/NumericFormatComponent';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';
import { validate } from '../../utils/validate';

const MvnoInfoRateDetailsEdit: FC<{
  mno: Mno;
  rateDetails: RateDetails;
}> = ({ mno, rateDetails }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ExceedRateList>();
  const title = convertStringToMno(mno);
  const errorMessage = '10만원 이하로 설정해 주세요.';

  const handlePriceValidate = (value: number | null) => {
    if (value == null) return '값을 입력해주세요';
    return validate.isLessThan(value, 100000) || errorMessage;
  };

  return (
    <MvnoInfoItemBody gap={4}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemBody gap={7}>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name={`${mno}.dataRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.dataRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.dataRate}
                helperText={errors[mno]?.dataRate?.message}
                fullWidth
                label="데이터"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ mb"
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.voiceCallRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.voiceCallRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.voiceCallRate}
                helperText={errors[mno]?.voiceCallRate?.message}
                fullWidth
                label="음성"
                onWheel={(e) => e.stopPropagation()}
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 초"
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.mvoipCallRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.mvoipCallRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.mvoipCallRate}
                helperText={errors[mno]?.mvoipCallRate?.message}
                fullWidth
                label="영상"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 초"
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.smsRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.smsRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.smsRate}
                helperText={errors[mno]?.smsRate?.message}
                fullWidth
                label="SMS"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 건"
              />
            )}
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name={`${mno}.lmsRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.lmsRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.lmsRate}
                helperText={errors[mno]?.lmsRate?.message}
                fullWidth
                label="LMS"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 건"
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.mmsVideoRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.mmsVideoRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.mmsVideoRate}
                helperText={errors[mno]?.mmsVideoRate?.message}
                fullWidth
                label="MMS (영상)"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 건"
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.mmsPictureRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.mmsPictureRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.mmsPictureRate}
                helperText={errors[mno]?.mmsPictureRate?.message}
                fullWidth
                label="MMS (사진)"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 건"
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.mmsTextRate`}
            rules={{
              validate: handlePriceValidate,
            }}
            defaultValue={rateDetails.mmsTextRate}
            render={({ field }) => (
              <TextFieldWithEndAdornment
                InputLabelProps={{
                  required: false,
                }}
                {...field}
                error={!!errors[mno]?.mmsTextRate}
                helperText={errors[mno]?.mmsTextRate?.message}
                fullWidth
                label="MMS (텍스트)"
                InputProps={{
                  inputComponent: NumericFormatComponent,
                }}
                endAdornment="원/ 건"
              />
            )}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoExceedRateEdit: FC<ExceedRateList> = (exceedRate) => {
  return (
    <MvnoInfoItemBody gap={3}>
      {Object.entries(exceedRate).map(([key, rateDetails]) => (
        <MvnoInfoRateDetailsEdit
          key={key}
          mno={key as Mno}
          rateDetails={rateDetails}
        />
      ))}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoExceedRateEdit;
