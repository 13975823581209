import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  separator: ReactNode;
}

const Join = ({ children, separator }: Props) => {
  const childrenNodes = React.Children.toArray(children);
  const element = childrenNodes.flatMap((node, index) =>
    index < childrenNodes.length - 1 ? [node, separator] : node,
  );

  return <>{element}</>;
};

export default Join;
