import { useEffect, useState } from 'react';
import DaumPostcode, { Address } from 'react-daum-postcode';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Divider } from '@mui/material';

import { orderApiService } from '../../api/order';
import { handleApi } from '../../common/http_util';
import { MobilePlanOrder } from '../../entity/order';
import { OrderInformation, Title } from './components';
import { formatCardNumber } from '../../utils/valueFormatter';
import {
  CustomerInfoSection,
  PaymentInfoSection,
  OrderEditESimInfoSection,
} from './sections';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from '../../shared/components/Fallback';
import pbl from '../../pbl/pbl-service/pbl-service';
import { flattenDiff } from '../../common/object-diff';
import { pblPageviewOrderDetail } from '../../pbl/pbl-common-service/pbl-pageview-order-detail';
import { orderEditFormValidationResolver } from './validation/order-edit-validation';

const removeSensitiveInformation = (object: object) => {
  const {
    'detail.payerName': detailPayerName,
    'detail.recipientName': detailRecipientName,
    'detail.eid': detailEid,
    'detail.imei': detailImei,
    'detail.imei2': detailImei2,
    customerPhoneNumber,
    orderPhoneNumber,
    'detail.payerPhoneNumber': detailPayer_phone_number,
    'detail.recipientPhoneNumber': detailRecipient_phone_number,
    'detail.accountNumber': detailAccount_number,
    'detail.cardNumber': detailCard_number,
    'detail.cardExpiresMonth': detailCard_expires_month,
    'detail.cardExpiresYear': detailCard_expires_year,
    'detail.payerBirthday': detailPayer_birthday,
    'detail.licenseIssuedAt': detailLicense_issued_at,
    'detail.addressLevel1': detailAddress_level,
    'detail.addressLevel2': detailAddress_level2,
    'detail.postalCode': detailPostal_code,
    'detail.email': detailEmail,
    ...rest
  } = object as Record<string, unknown>;
  return rest;
};

export function OrderEdit() {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState<MobilePlanOrder>();
  const form = useForm<MobilePlanOrder>({
    resolver: orderEditFormValidationResolver(order?.orderType),
  });
  const { handleSubmit, reset, watch, setValue } = form;

  const [showAddressPopup, setShowAddressPopup] = useState(false);

  useEffect(() => {
    if (!order) return;
    pblPageviewOrderDetail('order_detail_edit', order);
  }, [order]);

  useEffect(() => {
    if (order?.isEsim) {
      setTimeout(() => {
        // @ts-ignore
        setValue('detail.hasUsim', '');
      }, 0);
    }
  }, [order]);

  const fetchOrder = async () => {
    if (!orderId) return;

    try {
      const order = await handleApi(
        () => orderApiService.getOrder(orderId),
        navigate,
      );

      if (!order) return;
      if (order.detail?.cardNumber != null) {
        order.detail.cardNumber = order.detail.cardNumber.replace(/\D/g, '');
      }

      if (order.detail) {
        order.detail.payerChecked = !!order.detail?.payerName;
      }

      setOrder(order);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    reset(order);
  }, [order]);

  const onSubmit = async (data: any) => {
    if (!orderId) return;
    data.detail.cardNumber = formatCardNumber(data.detail.cardNumber)?.replace(
      /\D/g,
      '',
    );
    const flattenDiffOrder = flattenDiff(order ?? {}, data);
    const diffOrderKeyList = Object.keys(flattenDiffOrder);
    const filteredFlattenDiffOrder =
      removeSensitiveInformation(flattenDiffOrder);

    pbl('click', 'button', 'here', {
      object: {
        id: 'save',
        section: 'top_menu',
      },
      eventProperties: {
        ...filteredFlattenDiffOrder,
        editedFields: diffOrderKeyList,
      },
    });
    try {
      const orderResponse = await handleApi(
        () => orderApiService.editOrder(orderId, data),
        navigate,
        (err) => {
          alert(err);
        },
      );

      navigate(`/orders/${orderResponse?.id}`);
    } catch (err) {
      alert(err);
      console.log(err);
    }
  };

  const openAddressPopup = () => {
    setShowAddressPopup(true);
  };

  const handleOnSelectAddress = (address: Address) => {
    setValue('detail.postalCode', address.zonecode);
    setValue('detail.addressLevel1', address.address);
    setShowAddressPopup(false);
  };

  const handleCancelEdit = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'cancel',
        section: 'top_menu',
      },
    });
    navigate(`/orders/${orderId}`);
  };

  console.log('order', order);

  return (
    <ErrorBoundary fallbackRender={Fallback}>
      <Container
        sx={{
          width: {
            xs: '100%',
            lg: '75%',
          },
          paddingBottom: '64px',
        }}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title title="온라인 가입 신청서" onCancel={handleCancelEdit} />

            <Divider />

            {/* TODO: RQ로 변경하고 suspense로 로딩 단계 처리 확실히 하기 */}
            <ErrorBoundary
              fallbackRender={({ error }) => <div>{error.message}</div>}
            >
              <OrderInformation order={order} />

              <CustomerInfoSection order={order} />

              <OrderEditESimInfoSection order={order} />

              <PaymentInfoSection
                order={order}
                openAddressPopup={openAddressPopup}
              />
            </ErrorBoundary>
          </form>
        </FormProvider>
      </Container>
      {showAddressPopup && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 9999,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <DaumPostcode onComplete={handleOnSelectAddress} autoClose={false} />
        </Box>
      )}
    </ErrorBoundary>
  );
}
