import { Box, Button, colors, Typography } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import SwitchEditableMvnoInfoHeader from './SwitchEditableMvnoInfoHeader';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { MvnoInfoPutFunction } from '../api/mvno-info-api-service';
import useConfirm from '../../../common/useConfirm';

interface Props<T extends FieldValues> {
  title: string;
  subTitle?: string;
  children: ReactNode;
  put?: MvnoInfoPutFunction<T>;
  defaultValues?: DefaultValues<T>;
  onCancelEdit?: () => void;
  onEditComplete?: () => void;
}

const SwitchEditableMvnoInfoEdit = <T extends FieldValues>({
  title,
  subTitle,
  children,
  put,
  defaultValues,
  onCancelEdit,
  onEditComplete,
}: Props<T>) => {
  const form = useForm<T>({
    defaultValues,
  });
  const confirm = useConfirm();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onCancelEditHandler = () => {
    onCancelEdit?.();
  };

  const onEditCompleteHandler = async (data: T) => {
    if (
      !(await confirm.open({
        title: '수정 내용을 반영할까요?',
        content: `고객에게도 수정된 내용으로 안내됩니다.\n수정한 내용이 맞는지 꼼꼼히 확인해 주세요.`,
        okText: '반영하기',
        cancelText: '취소',
      }))
    )
      return;
    await put?.(data);
    onEditComplete?.();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onEditCompleteHandler)}>
        <SwitchEditableMvnoInfoHeader
          title={title}
          subTitle={subTitle}
          actionArea={
            <Box
              sx={{
                display: 'flex',
                gap: 0.5,
              }}
            >
              <Button
                variant="text"
                color="inherit"
                onClick={onCancelEditHandler}
              >
                취소
              </Button>
              <LoadingButton
                variant="outlined"
                color="inherit"
                type="submit"
                loading={isSubmitting}
              >
                수정완료
              </LoadingButton>
            </Box>
          }
        />
        {children}
      </form>
    </FormProvider>
  );
};

export default SwitchEditableMvnoInfoEdit;
