export const camelToUnderscore = (key: string): string => {
  if (key === key.toString().toUpperCase()) return key;
  try {
    return key?.replace(/([A-Z])/g, '_$1').toLowerCase();
  } catch (error) {
    return key;
  }
};

const generateSnakeCase = (obj: unknown): object | undefined => {
  const target: Record<string, unknown> = {};
  if (obj == null || typeof obj !== 'object') {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) =>
      typeof item === 'object'
        ? generateSnakeCase(item)
        : camelToUnderscore(item as string),
    );
  }

  if (typeof obj === 'object') {
    Object.entries(obj).forEach(([key, value]) => {
      const _value =
        typeof value === 'object'
          ? generateSnakeCase(value)
          : (value as string);

      const newKey = camelToUnderscore(key);
      if (newKey) {
        target[newKey] = _value;
      }
    });
  }

  return target;
};

export default generateSnakeCase;
