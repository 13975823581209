import { Typography } from '@mui/material';
import { Header, PageContainer } from './styled';
import { FormProvider, useForm } from 'react-hook-form';
import PlanNoticeEditSection from './components/PlanNoticeEdit';
import { EditBottomButtonGroup } from './components/EditBottomButtonGroup';
import { useLoading } from '../../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import { planApiService } from '../../api/plan';
import { PlanManagementMethodType } from '../MvnoInfoPage/types/mobile-plan-operator-admin-types';

interface FormProps {
  planUnitNoticeTemplateIds: number[];
}

export default function PlanNoticeEdit({
  planId,
  mobilePlanOperatorId,
  planUnitNoticeTemplateIds,
  planManagementType,
}: {
  planId: number;
  mobilePlanOperatorId: number;
  planUnitNoticeTemplateIds: number[];
  planManagementType: PlanManagementMethodType;
}) {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const methods = useForm<FormProps>({
    defaultValues: {
      planUnitNoticeTemplateIds,
    },
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    trigger,
    formState: { isDirty, isValid },
  } = methods;
  const isManualPlanManagementType = planManagementType === 'MANUAL';

  const handleNoticeEditPlan = () => {
    handleSubmit(async (data: FormProps) => {
      try {
        setLoading(true);
        await planApiService.putPlanUnitPlanNotices({
          planId,
          templateIds: data.planUnitNoticeTemplateIds,
        });
        navigate(`/plans/${planId}`);
      } catch (error) {
        alert(error);
        throw error;
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <PageContainer>
      <Header>
        <Typography variant="h4">공지 설정</Typography>
      </Header>
      <FormProvider {...methods}>
        <form noValidate>
          {/* 요금제 공지 */}
          <PlanNoticeEditSection
            initialPlanUnitNoticeTemplateIds={planUnitNoticeTemplateIds}
            mobilePlanOperatorId={mobilePlanOperatorId}
          />

          <EditBottomButtonGroup
            onReserve={() => {}}
            onValidate={trigger}
            onOk={handleNoticeEditPlan}
            displayPlan={false}
            isShowReserveButton={isManualPlanManagementType}
            isDirty={isDirty}
            isValid={isValid}
            immediatelyButtonText="적용하기"
          />
        </form>
      </FormProvider>
    </PageContainer>
  );
}
