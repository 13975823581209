import {
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  FormContent,
  InputRow,
  Section,
  SectionHeader,
  SectionTitle,
} from '../styled';

import { ICreatePlanDto } from '../../../entity/plan';
import { useFormContext } from 'react-hook-form';

import { useEffect, useState } from 'react';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { PlanInfoValidators } from '../types';

interface PlanInfoSectionProps {
  isEdit: boolean;
  initialDiscountPeriod: number | null;
  initialDiscountFee: number | null;
  initialAgreementPeriod: number | null;
  initialOriginalFee: number | null;
  subTitle?: string;
  bottomContentArea?: React.ReactNode;
  validators: PlanInfoValidators;
}

const MAX_DISCOUNT_FEE_LENGTH = 6;

const MAX_FEE_AMOUNT = 999999;

const NumericFormatCustom = React.forwardRef<NumericFormatProps, any>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        thousandSeparator=","
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

const NumericFormatCustomPeriod = React.forwardRef<NumericFormatProps, any>(
  function NumericFormatCustomPeriod(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  },
);

export const PlanInfoSection = ({
  isEdit,
  validators,
  initialDiscountPeriod,
  initialDiscountFee,
  initialAgreementPeriod,
  initialOriginalFee,
  subTitle,
  bottomContentArea,
}: PlanInfoSectionProps) => {
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<ICreatePlanDto>();

  const [tmpDiscountFee, setTmpDiscountFee] = useState<number | null>(
    initialDiscountFee,
  );
  const [tmpOriginalFee, setTmpOriginalFee] = useState<number | null>(
    initialOriginalFee,
  );
  // NOTE: 약정기간은 요금제가 있을 때, 기본 0으로 설정되어야함
  const [tmpAgreementPeriod, setTmpAgreementPeriod] = useState<string>(
    isEdit ? `${initialAgreementPeriod ?? 0}` : '',
  );

  const [isFreeDiscount, setIsFreeDiscount] = useState(
    initialDiscountPeriod === 0 ? true : false,
  );

  const discountFee = watch('discountFee');
  const originalFee = watch('originalFee');

  const [tmpDiscountPeriod, setTmpDiscountPeriod] = useState<number | null>(
    initialDiscountPeriod ?? null,
  );

  const {
    onValidateDiscountFee,
    onValidateDiscountPeriod,
    onValidateOriginalFee,
  } = validators;

  const handleChangeDiscountType = (
    e: React.MouseEvent<HTMLElement>,
    value: string | null,
  ) => {
    if (value === '평생할인') {
      setValue('discountPeriod', 0, { shouldDirty: true });
      setIsFreeDiscount(true);
    } else {
      setValue('discountPeriod', tmpDiscountPeriod ?? null, {
        shouldDirty: true,
      });
      setIsFreeDiscount(false);
    }
  };

  useEffect(() => {
    if (discountFee !== null) {
      trigger('discountFee');
    }
  }, [discountFee, trigger]);

  const handleKeyDownDiscountAmount = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  return (
    <Section id={'plan-info-section'}>
      <SectionHeader flexDirection="row" mt={6} alignItems={'center'}>
        <Box flexDirection={'column'}>
          <SectionTitle variant="h5" mb={1}>
            요금 정보
          </SectionTitle>
          {subTitle && <Typography variant="body1">{subTitle}</Typography>}
        </Box>
        <ToggleButtonGroup
          onChange={(e, value) => {
            handleChangeDiscountType(e, value);
          }}
          value={isFreeDiscount ? '평생할인' : '기간할인'}
          exclusive
          style={{ height: '48px' }}
          aria-label="text alignment"
        >
          <ToggleButton value="기간할인" aria-label="left aligned">
            기간할인
          </ToggleButton>
          <ToggleButton value="평생할인" aria-label="centered">
            평생할인
          </ToggleButton>
        </ToggleButtonGroup>
      </SectionHeader>

      <FormContent>
        <InputRow>
          <TextField
            style={{ width: '265.3px' }}
            {...register('agreementPeriod')}
            onChange={(e) => {
              setTmpAgreementPeriod(String(e.target.value));
            }}
            onBlur={() =>
              setValue('agreementPeriod', Number(tmpAgreementPeriod), {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            label={'약정 기간'}
            fullWidth
            value={tmpAgreementPeriod}
            placeholder="숫자만 입력"
            InputProps={{
              inputComponent: NumericFormatCustomPeriod as any,
              endAdornment: <Typography variant="body1">일</Typography>,
              inputProps: {
                onKeyDown: handleKeyDownDiscountAmount,
              },
            }}
            error={!!errors.agreementPeriod}
            helperText={errors.agreementPeriod?.message}
            InputLabelProps={{ shrink: true }}
          />
        </InputRow>

        <InputRow>
          <TextField
            {...register('discountFee', {
              required: '할인 시 요금은 필수입니다',
              validate: (value) => {
                return onValidateDiscountFee({
                  originalFee,
                  discountFee: value,
                  discountPeriod: tmpDiscountPeriod,
                  isFreeDiscount,
                  maxAmount: MAX_FEE_AMOUNT,
                });
              },
            })}
            onChange={(e) => {
              setTmpDiscountFee(Number(e.target.value));
            }}
            onBlur={async () => {
              await setValue('discountFee', tmpDiscountFee, {
                shouldValidate: false,
                shouldDirty: true,
              });
              trigger(['discountFee', 'originalFee']);
            }}
            label={isFreeDiscount ? '할인 요금' : '할인 시 요금'}
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              endAdornment: <Typography variant="body1">원</Typography>,
              inputProps: {
                min: 0,
                onKeyDown: handleKeyDownDiscountAmount,
              },
            }}
            value={discountFee || ''}
            placeholder="숫자만 입력"
            error={!!errors.discountFee}
            helperText={errors.discountFee?.message}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            {...register('originalFee', {
              validate: (value) => {
                return onValidateOriginalFee({
                  originalFee: value,
                  discountFee,
                  discountPeriod: tmpDiscountPeriod,
                  isFreeDiscount,
                  maxAmount: MAX_DISCOUNT_FEE_LENGTH,
                });
              },
            })}
            label="할인 종료 후 요금"
            style={{ visibility: isFreeDiscount ? 'hidden' : 'visible' }}
            fullWidth
            value={originalFee || ''}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              endAdornment: <Typography variant="body1">원</Typography>,
              inputProps: {
                min: 0,
                onKeyDown: handleKeyDownDiscountAmount,
              },
            }}
            onChange={(e) => {
              setTmpOriginalFee(Number(e.target.value));
            }}
            onBlur={async () => {
              await setValue('originalFee', tmpOriginalFee, {
                shouldValidate: false,
                shouldDirty: true,
              });
              trigger(['discountFee', 'originalFee']);
            }}
            placeholder="숫자만 입력"
            error={!!errors.originalFee}
            helperText={errors.originalFee?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label="할인 기간"
            fullWidth
            type="number"
            value={tmpDiscountPeriod || ''}
            style={{ visibility: !isFreeDiscount ? 'visible' : 'hidden' }}
            placeholder="숫자만 입력"
            InputLabelProps={{ shrink: true }}
            {...register('discountPeriod', {
              required: !isFreeDiscount ? '할인 기간은 필수입니다' : false,
              validate: (value) => {
                return onValidateDiscountPeriod({
                  originalFee,
                  discountFee,
                  discountPeriod: value,
                  isFreeDiscount,
                });
              },
            })}
            onChange={(e) => {
              const value = e.target.value;
              setTmpDiscountPeriod(Number(value));
            }}
            onBlur={() =>
              setValue('discountPeriod', tmpDiscountPeriod, {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            error={!isFreeDiscount && !!errors.discountPeriod}
            helperText={errors.discountPeriod?.message}
            InputProps={{
              endAdornment: (
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  개월
                </Typography>
              ),
              inputProps: {
                min: 1,
                max: 120,
                onKeyDown: (e) => e.key === '-' && e.preventDefault(),
              },
            }}
          />
        </InputRow>
        {/* TODO: 요금제 할인코드 추후 구현예정  */}
        {/* <BottomBlock>
          <span>
          <Typography variant="body2">요금제 할인코드</Typography>
          <Typography variant="body2" color={colors.grey[500]}>
          (선택)
          </Typography>
          </span>
          <Button variant="contained">할인코드 생성</Button>
          </BottomBlock> */}
      </FormContent>
      {bottomContentArea}
    </Section>
  );
};
