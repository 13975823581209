import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useState } from 'react';
import ImpressionLogTracker from '../../pbl/impression-log-tracker/ImpressionLogTracker';

interface IExposeConfirmDialog {
  title: string;
  open: boolean;
  onExpose: (date: DateTime | null) => void;
  onClose: () => void;
  objectId: string;
}

export const ExposeConfirmDialog = ({
  title,
  open,
  onClose,
  onExpose,
  objectId,
}: IExposeConfirmDialog) => {
  const [exposeDate, setExposeDate] = useState<DateTime | null>(
    DateTime.now().startOf('day').plus({ day: 1 }),
  );

  return (
    <ImpressionLogTracker
      logObjectType="modal"
      logOptions={{
        object: {
          id: objectId,
        },
      }}
    >
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: '410px' }}>
          {title}
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <DateTimePicker
            sx={{ width: '100%' }}
            label="노출 시작 일시"
            value={exposeDate}
            minutesStep={10}
            onChange={(newValue) => setExposeDate(newValue)}
            disablePast
            slotProps={{
              textField: {
                error: false,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            취소
          </Button>
          <Button
            onClick={() => {
              onExpose(exposeDate);
            }}
            color="primary"
            variant="contained"
          >
            노출 예약
          </Button>
        </DialogActions>
      </Dialog>
    </ImpressionLogTracker>
  );
};
