import { AxiosResponse } from 'axios';
import { moyoOnlyApi } from '../common/http_util';
import { ApiResponse } from '../entity/api';
import { MoyoOnlyValidateFeeResponse } from '../entity/moyo-only';
import { ICreatePlanDto } from '../entity/plan';

class MoyoOnlyApi {
  validateMoyoOnlyPlanFee({
    data,
  }: {
    data: ICreatePlanDto;
  }): Promise<AxiosResponse<ApiResponse<MoyoOnlyValidateFeeResponse>>> {
    return moyoOnlyApi.post('/validate', data);
  }
}

export const moyoOnlyApiService = new MoyoOnlyApi();
