/**
 * 영문/숫자/특수문자 중
 * 2종류를 포함한다면 10자 이상
 * 3종류를 포함한다면 8자 이상
 */

type Returns = {
  isValid: boolean;
  message?: string;
};

export function validatePassword(value: string): Returns {
  if (!value) return { isValid: false, message: '비밀번호를 입력해주세요.' };

  const hasLetters = /[a-zA-Z]/.test(value);
  const hasNumbers = /[0-9]/.test(value);
  const hasSpecials = /[!@#$%^&*(),.?":{}|<>]/.test(value);

  const typeCount = [hasLetters, hasNumbers, hasSpecials].filter(
    Boolean,
  ).length;
  const length = value.length;

  if (typeCount === 2 && length >= 10) {
    return {
      isValid: true,
    };
  } else if (typeCount === 3 && length >= 8) {
    return {
      isValid: true,
    };
  }

  let message = '';
  if (typeCount < 2) {
    message = '영문, 숫자, 특수문자 중 2가지 이상 포함해야 합니다.';
  } else if (typeCount === 2 && length < 10) {
    message = '2종류의 문자를 사용할 경우 최소 10자 이상 입력해야 합니다.';
  } else if (typeCount === 3 && length < 8) {
    message = '3종류의 문자를 사용할 경우 최소 8자 이상 입력해야 합니다.';
  }

  return {
    isValid: false,
    message,
  };
}
