import LoginPage from '../components/LoginPage';
import OrderListPage from '../components/OrderListPage';
import { OrderDetail } from '../components/OrderDetail/OrderDetail';
import { OrderEdit } from '../components/OrderEdit/OrderEdit';
import OrderCallListPage from '../components/OrderCallListPage';
import OrderInternetListPage from '../components/OrderInternetListPage';
import CompanyRoute from '../modules/CompanyRoute';
import OrderInternetDetailPage from '../components/OrderInternetDetailPage';
import PlanListPage from '../components/PlanListPage';
import PlanDetailPage from '../components/PlanDetailPage';
import OrderProgressDetailPage from '../components/OrderProgressDetailPage';
import OrderSelfListPage from '../components/OrderSelfListPage';
import AuthSettingsPage from '../components/AuthSettingsPage/AuthSettingsPage';
import AllOrderListPage from '../components/OrderListPage/AllOrderListPage';
import AccountSettingsPage from '../components/AccountSettingsPage/AccountSettingsPage';
import AccountDeletingPage from '../components/AccountDeletingPage/AccountDeletingPage';
import InternetBenefitConsultListPage from '../components/InternetConsult/InternetConsultList/InternetConsultListPage/InternetConsultListPage';
import InternetConsultDetailPage from '../components/InternetConsult/InternetConsultDetail/InternetConsultDetailPage';
import { Permission, Role } from '../types/AuthType';
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { NavigationPermission } from './useNavigationPermission';
import { EmptyPage } from '../components/EmptyPage';
import PlanEditPage from '../components/PlanEditPage';
import MvnoInfoPage from '../components/MvnoInfoPage';
import PrivacyPolicyPage from '../components/PrivacyPolicyPage';
import ChangePasswordPage from '../components/ChangePasswordPage';
import PlanNoticeEditPage from '../components/PlanEditPage/PlanNoticeEditPage';
import IssueOtpPage from '../components/IssueOtpPage';

export interface RouteConfig<T extends string = string> {
  name: string;
  objectId: T;
  path: string | undefined;
  element: React.ReactNode;
  parentId?: T;
  isNavItem: boolean;
  isRouteItem: boolean;
  requiredAuth: boolean;
  // NOTE: requiredPermissions는 서버에서 지정한 유저의 permissions을 의미함
  requiredPermissions: Permission[];
  requiredRoles: Role[];
  // NOTE: navigationPermissions는 company등 여러 정보의 결합으로 만들어진 것으로 이후 서버에서 관리해야 함
  navigationPermissionsAny: NavigationPermission[];
  navigationPermissionsAll: NavigationPermission[];
  icon?: React.ReactNode;
  divide?: boolean;
  innerItem?: React.ReactNode;
  outlink?: string;
}

/**
 * TODO: 나중에 routes의 명칭을 변경하고 route는 별도로 빼내기
 * 이건 menu나 pages 로 변경하면 좀 나으려나 고민도 됨
 */
export type RouteKey =
  | 'landing'
  | 'orders'
  | 'orders_all'
  | 'login'
  | 'orders_auto'
  | 'orders_rocket'
  | 'orders_outlink_self'
  | 'orders_self_complete'
  | 'orders_baro'
  | 'call_orders'
  | 'internet'
  | 'internet_orders'
  | 'internet_fixed_offers'
  | 'plans'
  | 'plans_list'
  | 'plans_moyoonly_list'
  | 'plans_moyoonly_create'
  | 'mvno_info'
  | 'mvno_info_by_id'
  | 'guide'
  | 'order_detail'
  | 'order_internet_detail'
  | 'order_edit'
  | 'order_edit_internet'
  | 'order_progress_detail'
  | 'auth_settings'
  | 'account_settings'
  | 'account_delete'
  | 'plan_detail'
  | 'plan_detail_with_source'
  | 'internet_consult_detail'
  | 'iptv_bundle_consult_detail'
  | 'plan_edit'
  | 'plan_notice_edit'
  | 'privacy_policy'
  | 'change_password'
  | 'plan_detail_moyoonly'
  | 'otp';

export type RouteConfigMap = {
  [K in RouteKey]: RouteConfig<RouteKey>;
};

/**
 * 요금제 접수로직에 애매한 부분이 있어서 남겨둠.
 * selfActive와 outlinkSelfActive가 false이면 rootpath로 가정해서 /로 이동하게함
 * 아닌경우 undefined를 했음(메뉴를 보여줬다는 얘기)
 * collapse는 이미 다른쪽에서 처리되고 있으니 이 부분에 대해서만 나중에 챙겨보기
 */
export const routes: RouteConfigMap = {
  landing: {
    name: '랜딩',
    objectId: 'landing',
    path: '/',
    element: <EmptyPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: false,
    requiredPermissions: [],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  orders: {
    name: '요금제 접수',
    objectId: 'orders',
    path: undefined,
    element: null,
    isNavItem: true,
    isRouteItem: false,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
    icon: <SimCardOutlinedIcon />,
  },
  orders_all: {
    name: '전체',
    objectId: 'orders_all',
    path: '/orders/all',
    element: (
      <CompanyRoute>
        <AllOrderListPage />
      </CompanyRoute>
    ),
    parentId: 'orders',
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  login: {
    name: '로그인',
    objectId: 'login',
    path: '/login',
    element: <LoginPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: false,
    requiredPermissions: [],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  orders_auto: {
    name: '모요 자동 처리 중',
    objectId: 'orders_auto',
    path: '/orders/moyo-processing',
    parentId: 'orders',
    element: (
      <OrderListPage
        navigation="orders_auto"
        title="모요 자동 처리 중"
        showActivateState
        autoActivateStates={[
          '검증_진행중',
          '검증_대기',
          '개통_진행중',
          '개통_대기',
          '배송_대상',
          '원통_대기',
        ]}
      />
    ),
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [
      'MOYO_ADMIN',
      'SELF_MOYO_ACTIVE',
      'DELETE_PERSONAL_INFO_NOT_ALLOWED',
    ],
  },
  orders_rocket: {
    name: '간편신청 접수',
    objectId: 'orders_rocket',
    path: '/orders/register',
    parentId: 'orders',
    element: (
      <OrderListPage
        navigation="orders_rocket"
        title="간편신청 접수"
        orderTypes={['register']}
        autoActivateStates={[
          '검증_완료',
          '자동_검증_대상아님',
          '개통_시도_완료',
          '배송_처리_완료',
          'KT_바로배송_검증완료_개통가능',
          'KT_바로배송_검증완료_개통불가능',
        ]}
      />
    ),
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: ['SELF', 'MOYO_ADMIN'],
    navigationPermissionsAll: [],
  },
  orders_outlink_self: {
    name: '셀프개통 접수',
    objectId: 'orders_outlink_self',
    path: '/orders/outlinkSelf',
    parentId: 'orders',
    element: (
      <OrderListPage
        navigation="orders_outlink_self"
        title="셀프개통 접수"
        orderTypes={['outlink_self']}
      />
    ),
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: ['MOYO_ADMIN', 'OUTLINK_SELF'],
  },
  orders_self_complete: {
    name: '셀프개통 완료',
    objectId: 'orders_self_complete',
    path: '/orders/self',
    parentId: 'orders',
    element: <OrderSelfListPage />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: ['MOYO_ADMIN'],
    navigationPermissionsAll: [],
  },
  orders_baro: {
    name: '바로 배송 중',
    objectId: 'orders_baro',
    path: '/orders/kt-baro',
    parentId: 'orders',
    element: (
      <OrderListPage
        navigation="orders_baro"
        title="바로 배송 중"
        showActivateState
        autoActivateStates={['KT_바로배송중']}
      />
    ),
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: ['MOYO_ADMIN', 'BARO_ACTIVE'],
  },
  call_orders: {
    name: '전화 상담 접수',
    objectId: 'call_orders',
    path: '/orders/call',
    element: <OrderCallListPage />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: ['CALL_ACTIVE'],
    navigationPermissionsAll: [],
    icon: <HeadphonesOutlinedIcon />,
  },
  internet: {
    name: '인터넷 접수',
    objectId: 'internet',
    path: '/orders/internet',
    element: <OrderInternetListPage />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
    icon: <LanguageOutlinedIcon />,
  },
  internet_orders: {
    name: '일반 인터넷 접수',
    objectId: 'internet_orders',
    path: '/orders/internet',
    element: <OrderInternetListPage />,
    parentId: 'internet',
    isNavItem: false,
    isRouteItem: false,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  // NOTE: 현재 미사용
  internet_fixed_offers: {
    name: '인터넷 정찰제 접수',
    objectId: 'internet_fixed_offers',
    path: '/internet/benefit/consults',
    element: <InternetBenefitConsultListPage />,
    parentId: 'internet',
    isNavItem: false,
    isRouteItem: false,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  plans: {
    name: '요금제 관리',
    objectId: 'plans',
    path: undefined,
    element: null,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
    icon: <BallotOutlinedIcon />,
  },

  plans_list: {
    name: '요금제 목록',
    objectId: 'plans_list',
    path: '/plans',
    element: <PlanListPage isMoyoOnly={false} />,
    parentId: 'plans',
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: ['PLAN_EDIT'],
    navigationPermissionsAll: [],
  },
  plan_edit: {
    name: '요금제 등록',
    objectId: 'plan_edit',
    path: '/plan-edit',
    element: <PlanEditPage isMoyoOnly={false} />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: ['PLAN_EDIT'],
    navigationPermissionsAll: [],
  },
  plan_notice_edit: {
    name: '요금제 등록',
    objectId: 'plan_notice_edit',
    path: '/plan-notice-edit',
    element: <PlanNoticeEditPage />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: ['PLAN_EDIT'],
    navigationPermissionsAll: [],
  },
  //TODO: 일반 요금제 컴포넌트 분리해서 적용하기
  plans_moyoonly_list: {
    name: '모요온리 목록',
    objectId: 'plans_moyoonly_list',
    path: '/plans/moyo-only',
    element: <PlanListPage isMoyoOnly />,
    parentId: 'plans',
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: ['PLAN_EDIT'],
    navigationPermissionsAll: [],
  },
  plan_detail_moyoonly: {
    name: '모요온리 상세',
    objectId: 'plan_detail_moyoonly',
    path: '/plans/moyo-only/:planId',
    element: <PlanDetailPage isMoyoOnly />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  //TODO: 일반 요금제 컴포넌트 분리해서 적용하기
  plans_moyoonly_create: {
    name: '모요ONLY 요금제 생성',
    objectId: 'plans_moyoonly_create',
    path: '/plan-edit/moyo-only',
    element: <PlanEditPage isMoyoOnly />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: ['PLAN_EDIT'],
    navigationPermissionsAll: [],
  },
  mvno_info: {
    name: '통신사 정보',
    objectId: 'mvno_info',
    path: '/mvno-info',
    element: <MvnoInfoPage />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
    icon: <ContactEmergencyIcon />,
  },
  mvno_info_by_id: {
    name: '통신사 정보 어드민 접근',
    objectId: 'mvno_info_by_id',
    path: '/mvno-info/:operationId',
    element: <MvnoInfoPage />,
    isNavItem: true,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: ['MOYO_ADMIN'],
    icon: <ContactEmergencyIcon />,
  },
  guide: {
    name: '가이드',
    objectId: 'guide',
    path: undefined,
    element: null,
    isNavItem: true,
    isRouteItem: false,
    requiredAuth: true,
    requiredPermissions: [],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
    icon: <AutoStoriesOutlinedIcon />,
    outlink: 'https://moyoplan.notion.site/a7d3b759619347bca44be3c8f9751dae',
  },
  order_detail: {
    name: '개통 상세',
    objectId: 'order_detail',
    path: '/orders/:orderId',
    element: <OrderDetail />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  order_internet_detail: {
    name: '인터넷 개통 상세',
    objectId: 'order_internet_detail',
    path: '/orders/internet/:orderId',
    element: <OrderInternetDetailPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  order_edit: {
    name: '개통 수정',
    objectId: 'order_edit',
    path: '/orders/:orderId/edit',
    element: <OrderEdit />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_CHANGE'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  order_edit_internet: {
    name: '인터넷 개통 수정',
    objectId: 'order_edit_internet',
    path: '/orders/internet/:orderId/edit',
    element: <OrderEdit />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  order_progress_detail: {
    name: '개통 진행 상세',
    objectId: 'order_progress_detail',
    path: '/orders/progress/:progressId',
    element: <OrderProgressDetailPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_ORDER_REGISTER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  auth_settings: {
    name: '권한 설정',
    objectId: 'auth_settings',
    path: '/settings/auth',
    element: <AuthSettingsPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: [],
    requiredRoles: ['ROLE_ADMIN'],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  account_settings: {
    name: '계정 설정',
    objectId: 'account_settings',
    path: '/settings/account',
    element: <AccountSettingsPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: [],
    requiredRoles: ['ROLE_ADMIN'],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  account_delete: {
    name: '계정 삭제',
    objectId: 'account_delete',
    path: '/settings/account/delete',
    element: <AccountDeletingPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: [],
    requiredRoles: ['ROLE_ADMIN'],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  plan_detail: {
    name: '요금제 상세',
    objectId: 'plan_detail',
    path: '/plans/:planId',
    element: <PlanDetailPage isMoyoOnly={false} />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  plan_detail_with_source: {
    name: '요금제 상세 (출처 포함)',
    objectId: 'plan_detail_with_source',
    path: '/plans/:from/:planId',
    element: <PlanDetailPage isMoyoOnly={false} />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_MOBILE_PLAN_ORDER_MANAGE'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  internet_consult_detail: {
    name: '인터넷 혜택 상담 상세',
    objectId: 'internet_consult_detail',
    path: '/internet/benefit/consults/:consultId',
    element: <InternetConsultDetailPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  iptv_bundle_consult_detail: {
    name: 'IPTV 번들 혜택 상담 상세',
    objectId: 'iptv_bundle_consult_detail',
    path: '/internet/benefit/iptv-bundle/consults/:consultId',
    element: <InternetConsultDetailPage iptvBundle />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: true,
    requiredPermissions: ['PARTNERS_INTERNET_ORDER'],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  privacy_policy: {
    name: '개인정보처리방침',
    objectId: 'privacy_policy',
    path: '/privacy-policy',
    element: <PrivacyPolicyPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: false,
    requiredPermissions: [],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  change_password: {
    name: '비밀번호 변경',
    objectId: 'change_password',
    path: '/change-password',
    element: <ChangePasswordPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: false,
    requiredPermissions: [],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
  otp: {
    name: 'OTP 인증',
    objectId: 'otp',
    path: '/otp',
    element: <IssueOtpPage />,
    isNavItem: false,
    isRouteItem: true,
    requiredAuth: false,
    requiredPermissions: [],
    requiredRoles: [],
    navigationPermissionsAny: [],
    navigationPermissionsAll: [],
  },
};
