export const MOYO_ONLY_PLAN_NAME_PREFIX = '[모요only]';
export const MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_ONE = '[모요Only]';
export const MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_TWO = '모요only';

export const planNameFormatter = {
  removeMoyoOnlyPrefix: (planName: string) => {
    if (planName.startsWith(MOYO_ONLY_PLAN_NAME_PREFIX)) {
      return planName.replace(MOYO_ONLY_PLAN_NAME_PREFIX, '').trim();
    }

    if (planName.startsWith(MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_TWO)) {
      return planName
        .replace(MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_TWO, '')
        .trim();
    }

    if (planName.startsWith(MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_ONE)) {
      return planName
        .replace(MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_ONE, '')
        .trim();
    }

    return planName;
  },
  appendMoyoOnlyPrefix: (planName: string) => {
    //이미 [모요only] 및 이상한 모요온리 prefix가 있는 경우
    if (
      planName.includes(MOYO_ONLY_PLAN_NAME_PREFIX) ||
      planName.includes(MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_ONE) ||
      planName.includes(MOYO_ONLY_PLAN_NAME_PREFIX_WRONG_TYPE_TWO)
    ) {
      return planName;
    }

    //생성 및 이름 수정시 요금제 이름에 [모요only] prefix 추가
    return `${MOYO_ONLY_PLAN_NAME_PREFIX} ${planName.trim()}`;
  },
};
