import { createContext, useContext, useEffect, useState } from 'react';
import { CookieConstants } from '../common/constants';
import { getCookie } from '../common/cookie';
import { useAuth } from './UserContext';
import { internetOrderApiService } from '../api/internet-order';
import { InternetOrderStatus } from '../entity/internet-order';

type InternetOptionsContextType = {
  statusList: Array<InternetOrderStatus>;
};

const InternetOptionsContext = createContext<InternetOptionsContextType>({
  statusList: [],
});

const getLocalStatusList = () => {
  try {
    // FIXME: cookie를 사용하지 않고 localstorage를 사용하도록 임시 우회
    // 431 max header 에러의 원인처럼 보여져서 임시로 수정함
    // const statusCookies = getCookie(CookieConstants.INTERNET_STATUS_OPTIONS) ?? '[]';
    const statusCookies =
      localStorage.getItem(CookieConstants.INTERNET_STATUS_OPTIONS) ?? '[]';
    return JSON.parse(
      JSON.stringify(statusCookies),
    ) as Array<InternetOrderStatus>;
  } catch (error) {
    console.error('json parse error-orderlist', error);
    return [];
  }
};

export function InternetOptionsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useAuth();
  const [statusList, setStatusList] = useState<Array<InternetOrderStatus>>([]);

  const fetchStatusList = async () => {
    const localStatusList = getLocalStatusList();

    if (Array.isArray(localStatusList) && localStatusList.length > 0) {
      setStatusList(localStatusList);
    }

    try {
      const res = await internetOrderApiService.getOrderStatusOptions();
      const remoteStatusList = res.data.result;

      if (remoteStatusList?.statusList) {
        // FIXME: cookie를 사용하지 않고 localstorage를 사용하도록 임시 우회
        // 431 max header 에러의 원인처럼 보여져서 임시로 수정함
        localStorage.setItem(
          CookieConstants.INTERNET_STATUS_OPTIONS,
          JSON.stringify(remoteStatusList.statusList),
        );
        // setCookie(
        //   CookieConstants.INTERNET_STATUS_OPTIONS,
        //   JSON.stringify(remoteStatusList.statusList),
        //   {
        //     path: '/',
        //   },
        // );

        setStatusList(remoteStatusList.statusList ?? []);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchStatusList();
  }, [user]);

  return (
    <InternetOptionsContext.Provider value={{ statusList }}>
      {children}
    </InternetOptionsContext.Provider>
  );
}

export function useInternetOptions() {
  return useContext(InternetOptionsContext);
}
