import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import MvnoInfoSignupSupport from './Section/MvnoInfoSignupSupport/MvnoInfoSignupSupport';
import MvnoInfoBasic from './Section/MvnoInfoBasic/MvnoInfoBasic';
import MvnoInfoCustomerCenter from './Section/MvnoInfoCustomerCenter/MvnoInfoCustomerCenter';
import MvnoInfoOperationPolicyByMoyo from './Section/MvnoInfoOperationPolicyByMoyo/MvnoInfoOperationPolicyByMoyo';
import MvnoInfoMnoOperationPolicy from './Section/MvnoInfoMnoOperationPolicy/MvnoInfoMnoOperationPolicy';
import MvnoInfoUsimSupport from './Section/MvnoInfoUsimSupport/MvnoInfoUsimSupport';
import MvnoInfoExceedRate from './Section/MvnoInfoExceedRate/MvnoInfoExceedRate';
import {
  queryClient,
  useMobilePlanOperatorAdmin,
} from './hooks/useMobilePlanOperatorAdmin';
import { QueryClientProvider } from '@tanstack/react-query';
import { useAuth } from '../../context/UserContext';
import { useParams } from 'react-router-dom';
import { env } from '../../utils/env';
import Join from '../../shared/join';
import pbl from '../../pbl/pbl-service/pbl-service';
import ImpressionLogTracker from '../../pbl/impression-log-tracker/ImpressionLogTracker';
import { flattenDiff } from '../../common/object-diff';

const MvnoInfo: FC<{ operationId: number }> = ({ operationId }) => {
  const { data } = useMobilePlanOperatorAdmin(operationId);

  const handleMvnoPageOpen = () => {
    pbl('click', 'button', 'here', {
      object: {
        id: 'moyo_screen',
        name: '모요화면보기',
      },
    });
    const openUrl = env('REACT_APP_BASE_URL');
    window.open(
      openUrl +
        `/mvnos/redirect-by-id/${data?.basicInfo.mobilePlanOperatorMetaId}`,
    );
  };

  useEffect(() => {
    pbl('pageview', 'none', 'mvno_info', {
      eventProperties: {
        mobilePlanOperatorId: operationId,
      },
    });
  }, []);

  const onDiff = (before: object, after: object, objectId: string) => {
    const diff = flattenDiff(before, after);
    pbl('click', 'button', 'here', {
      object: {
        id: `${objectId}_completed`,
      },
      eventProperties: {
        diff,
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        padding: 5,
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="h4" my={2}>
          통신사 정보
        </Typography>
        <Button onClick={handleMvnoPageOpen} variant="text" size="large">
          모요 화면 보기
        </Button>
      </Box>
      {data && (
        <Box
          sx={{
            width: '860px',
            minWidth: 860,
            margin: 'auto',
          }}
        >
          <ImpressionLogTracker
            logObjectType="section"
            logOptions={{
              object: {
                id: 'mvno_information',
              },
              eventProperties: {
                brandName: data.basicInfo.brandName,
                applicationMethodList: data.applicationMethodList,
              },
            }}
            trackingOneTime
          >
            <MvnoInfoSignupSupport
              applicationMethodList={data.applicationMethodList}
              isMoyoPartner={data.operatorStatus.isMoyoPartner}
              brandName={data.basicInfo.brandName}
              mobilePlanOperatorMetaId={data.basicInfo.mobilePlanOperatorMetaId}
              logoUrl={data.basicInfo.logoUrl}
            />
          </ImpressionLogTracker>
          <Stack flexDirection={'column'} gap={7}>
            <Join separator={<Divider />}>
              <ImpressionLogTracker
                logObjectType="section"
                logOptions={{
                  object: {
                    id: 'basic_information',
                  },
                  eventProperties: {
                    businessName: data.basicInfo.businessName,
                    businessRegistrationNumber:
                      data.basicInfo.businessRegistrationNumber,
                    address: data.basicInfo.address,
                    pcHomepageUrl: data.basicInfo.pcHomepageUrl,
                    mobileHomepageUrl: data.basicInfo.mobileHomepageUrl,
                  },
                }}
              >
                <MvnoInfoBasic
                  basicInfo={data.basicInfo}
                  mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                  onDiff={onDiff}
                />
              </ImpressionLogTracker>
              <ImpressionLogTracker
                logObjectType="section"
                logOptions={{
                  object: {
                    id: 'call_center_information',
                  },
                  eventProperties: {
                    customerServiceContactList: data.customerServiceContactList,
                    operatingHoursInfoList: data.operatingHoursInfoList,
                  },
                }}
              >
                <MvnoInfoCustomerCenter
                  mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                  customerCenterInfo={{
                    customerServiceContactList: data.customerServiceContactList,
                    operatingHoursInfoList: data.operatingHoursInfoList,
                  }}
                  onDiff={onDiff}
                />
              </ImpressionLogTracker>
              {data.mnoOperationPolicyByMoyoList && (
                <ImpressionLogTracker
                  logObjectType="section"
                  logOptions={{
                    object: {
                      id: 'mno_policy_1',
                    },
                    eventProperties: {
                      mnoOperationPolicyByMoyoList:
                        data.mnoOperationPolicyByMoyoList,
                    },
                  }}
                >
                  <MvnoInfoOperationPolicyByMoyo
                    mnoOperationPolicyByMoyoList={
                      data.mnoOperationPolicyByMoyoList
                    }
                    mobilePlanOperatorId={
                      data.basicInfo.mobilePlanOperatorMetaId
                    }
                    onDiff={onDiff}
                  />
                </ImpressionLogTracker>
              )}
              <ImpressionLogTracker
                logObjectType="section"
                logOptions={{
                  object: {
                    id: 'mno_policy_2',
                  },
                  eventProperties: {
                    mnoOperationPolicyByOperatorList:
                      data.mnoOperationPolicyByOperatorList,
                  },
                }}
              >
                <MvnoInfoMnoOperationPolicy
                  mnoOperationPolicyByOperatorList={
                    data.mnoOperationPolicyByOperatorList
                  }
                  mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                  onDiff={onDiff}
                />
              </ImpressionLogTracker>
              <ImpressionLogTracker
                logObjectType="section"
                logOptions={{
                  object: {
                    id: 'mno_usim_policy',
                  },
                  eventProperties: {
                    usimInfoList: {
                      lgu: data.usimInfoList?.['LGU'],
                      kt: data.usimInfoList?.['KT'],
                      skt: data.usimInfoList?.['SKT'],
                    },
                  },
                }}
              >
                <MvnoInfoUsimSupport
                  usimInfo={data.usimInfoList}
                  mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                  onDiff={onDiff}
                />
              </ImpressionLogTracker>
              <ImpressionLogTracker
                logObjectType="section"
                logOptions={{
                  object: {
                    id: 'over_provided_rate',
                  },
                  eventProperties: {
                    exceedRateList: data.exceedRateList,
                  },
                }}
              >
                <MvnoInfoExceedRate
                  exceedRate={data.exceedRateList}
                  mobilePlanOperatorId={data.basicInfo.mobilePlanOperatorMetaId}
                  onDiff={onDiff}
                />
              </ImpressionLogTracker>
            </Join>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

const MvnoInfoPage: FC = () => {
  const { user } = useAuth();
  const { operationId: operationIdParams } = useParams();
  const operationId = operationIdParams ? parseInt(operationIdParams) : null;
  const userOperatorId = user?.company?.mobilePlanOperatorId;

  return (
    <QueryClientProvider client={queryClient}>
      {operationId ? (
        <MvnoInfo operationId={operationId} />
      ) : userOperatorId ? (
        <MvnoInfo operationId={userOperatorId} />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {
            // 계정에 mobilePlanOperatorId가 없는 경우에 렌더링 됩니다
          }
          <Typography variant="body1">
            페이지 접근에 실패 했습니다. 모요에 문의해주세요
          </Typography>
        </Box>
      )}
    </QueryClientProvider>
  );
};

export default MvnoInfoPage;
