import { BootstrapDialog, BootstrapDialogTitle } from './Dialog';
import { Button, DialogActions, DialogContent } from '@mui/material';

import { Box } from '@mui/system';
import ImpressionLogTracker from '../../../pbl/impression-log-tracker/ImpressionLogTracker';
import pbl from '../../../pbl/pbl-service/pbl-service';

type DeleteReserveDialogProps = {
  open: boolean;
  isShowReserveButton: boolean;
  onReserve: () => void;
  onClose: () => void;
  onDisplay: () => void;
};

const DisplaySettingDialog = ({
  open,
  isShowReserveButton,
  onReserve,
  onClose,
  onDisplay,
}: DeleteReserveDialogProps) => {
  const onReserveHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'display_activation_setting_reservation',
      },
    });
    onReserve();
  };

  const onDisplayHandler = () => {
    pbl('click', 'modal_button', 'here', {
      object: {
        id: 'display_activation_setting_activate',
      },
    });
    onDisplay();
  };

  return (
    <ImpressionLogTracker
      logObjectType="modal"
      logOptions={{
        object: {
          id: 'display_activation_setting_modal',
        },
      }}
    >
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={onClose}
        style={{ padding: `8px` }}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          요금제 노출
        </BootstrapDialogTitle>
        <DialogContent>
          <Box width={'100%'} pt={0} pb={2}>
            요금제를 모요에 노출시킬까요?
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end', padding: `8px` }}>
          {isShowReserveButton && (
            <Button color={'info'} variant={'text'} onClick={onReserveHandler}>
              노출 예약
            </Button>
          )}
          <Button
            color={'info'}
            variant={'contained'}
            onClick={onDisplayHandler}
          >
            지금 노출
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </ImpressionLogTracker>
  );
};

export default DisplaySettingDialog;
