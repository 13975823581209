import { GridColDef } from '@mui/x-data-grid';
import { Chip, Link } from '@mui/material';
import { numberCommaFormatter } from '../../utils/valueFormatter';

import { PlanMetaAdmin, PlanState, ReservationStatus } from '../../entity/plan';
import { PlanManagementMethodType } from '../MvnoInfoPage/types/mobile-plan-operator-admin-types';
import pbl from '../../pbl/pbl-service/pbl-service';

export const ColumnList = ({ isMoyoOnly }: { isMoyoOnly: boolean }) => {
  const columns: GridColDef<PlanMetaAdmin>[] = [
    {
      field: 'state',
      headerAlign: 'left',
      headerName: '상태',
      filterable: false,
      align: 'left',
      renderCell: (params) => {
        const text = params.row.display ? '노출중' : '미노출';
        const color = params.row.display ? 'info' : 'default';
        return <Chip color={color} label={text} size="small" />;
      },
    },
    {
      field: 'planManagementType',
      headerAlign: 'left',
      filterable: false,
      headerName: '관리',
      align: 'left',
      renderCell: (params) => {
        switch (params.row.planManagementType) {
          case 'SCRAPING':
            return '스크래핑';
          case 'MANUAL':
            return '수동 관리';
          case 'API':
            return 'API';
          default:
            return '미지정';
        }
      },
    },
    {
      field: 'mvno',
      filterable: false,
      headerAlign: 'left',
      headerName: '통신사 이름',
      align: 'left',
      width: 150,
    },
    {
      field: 'id',
      headerAlign: 'left',
      headerName: '요금제 ID',
      align: 'left',
      filterable: false,
      renderCell: (params) => {
        return <span>{params.row.id}</span>;
      },
    },
    {
      field: 'name',
      headerAlign: 'left',
      headerName: '요금제 이름',
      align: 'left',
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.name}</span>
            <StatusChip
              planManagementType={params.row.planManagementType}
              reservationStatus={params.row.reservationStatus}
              status={params.row.state}
            />
          </div>
        );
      },
    },
    {
      field: 'mno',
      headerAlign: 'left',
      headerName: '통신망',
      filterable: false,
      align: 'left',
    },

    {
      field: 'fee',
      headerAlign: 'left',
      headerName: '할인 요금',
      filterable: false,
      type: 'number',
      align: 'left',
      renderCell: (params) => {
        if (params.value == null) return '';
        return `${numberCommaFormatter(params.row.fee)}원`;
      },
    },
    {
      field: 'feeAfterDiscount',
      headerAlign: 'left',
      headerName: '이후 요금',
      filterable: false,
      type: 'number',
      align: 'left',
      renderCell: (params) => {
        // NOTE: 평생할인의 경우 할인 종료 요금은 노출하지 않는다
        if (!params.row.numberOfMonthsForDiscount) {
          return '';
        }
        if (params.value == null) return '';
        return `${numberCommaFormatter(params.value)}원`;
      },
    },
    {
      field: 'numberOfMonthsForDiscount',
      headerAlign: 'center',
      headerName: '할인기간',
      align: 'center',
      filterable: false,
      renderCell: (params) => {
        if (!params.row.numberOfMonthsForDiscount) {
          return '평생';
        }

        return (
          <>
            {params.row.numberOfMonthsForDiscount &&
              `${params.row.numberOfMonthsForDiscount}개월`}
          </>
        );
      },
    },

    {
      field: 'updatedAt',
      headerName: '업데이트',
      headerAlign: 'left',
      align: 'left',
      width: 150,
      filterable: false,
      renderCell: (params) => {
        const formattedDate = params.row.updatedAt.slice(2, 16);
        return formattedDate;
      },
    },
    {
      field: 'detailButton',
      headerName: '상세',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const urlByMoyoOnly = isMoyoOnly ? '/plans/moyo-only' : '/plans';
        return (
          <span
            onClick={() => {
              pbl('click', 'button', 'here', {
                object: {
                  id: params.row.id.toString(),
                  position: params.api.getRowIndexRelativeToVisibleRows(
                    params.row.id,
                  ),
                  name: '요금제 상세',
                },
                eventProperties: {
                  ...params.row,
                },
              });
            }}
          >
            <Link
              href={`${urlByMoyoOnly}/${params.row.id}`}
              target="_blank"
              rel="noopener"
              variant="button"
              color="primary"
              underline="none"
            >
              보기
            </Link>
          </span>
        );
      },
    },
  ];
  return columns;
};

const StatusChip = ({
  planManagementType,
  reservationStatus,
  status,
}: {
  planManagementType: PlanManagementMethodType;
  reservationStatus: ReservationStatus;
  status: PlanState;
}) => {
  const sx = { marginLeft: '8px' };
  if (reservationStatus === ReservationStatus.OPEN_RESERVED) {
    return (
      <Chip
        size="small"
        variant="outlined"
        sx={sx}
        color="success"
        label="노출예약"
      />
    );
  }
  if (reservationStatus === ReservationStatus.CLOSE_RESERVED) {
    return (
      <Chip
        size="small"
        variant="outlined"
        sx={sx}
        color="warning"
        label="종료예약"
      />
    );
  }
  if (
    reservationStatus === ReservationStatus.CHANGE_RESERVED &&
    planManagementType !== 'API'
  ) {
    return (
      <Chip
        size="small"
        variant="outlined"
        sx={sx}
        color="secondary"
        label="수정예약"
      />
    );
  }
  if (status === 'READY' && planManagementType === 'SCRAPING') {
    return (
      <Chip
        size="small"
        variant="outlined"
        sx={sx}
        color="default"
        label="진행대기"
      />
    );
  }
  return <></>;
};
