import { useFormContext } from 'react-hook-form';
import { ICreatePlanDto } from '../../../entity/plan';
import { PlanMetaV2 } from '../../../entity/plan-v2';
import { ConvertedTwelveMonthFeeContent } from './ConvertedTwelveMonthFeeContent';
import { useEffect, useMemo, useState } from 'react';
import { convertTwelveMonthFee } from '../util/convertTwelveMonthFee';

/**
 *
 * @description 요금제 수정/등록 페이지에서 변경되는 값을 반영하기 위해 watch로 값을 주입한 컴포넌트입니다.
 */
export function ConvertedTwelveMonthFeeContentFormAdapter({
  similarPlan,
}: {
  similarPlan: PlanMetaV2 | null;
}) {
  const { watch, clearErrors, trigger } = useFormContext<ICreatePlanDto>();
  const originalFee = watch('originalFee');
  const discountFee = watch('discountFee');
  const discountPeriod = watch('discountPeriod');

  const [validateState, setValidateState] = useState<
    'error' | 'success' | 'default'
  >('default');

  const convertedTwelveMonthFee = useMemo(
    () =>
      convertTwelveMonthFee({
        originalFee,
        discountFee,
        discountPeriod,
        isFreeDiscount: discountPeriod === 0,
      }),
    [discountFee, discountPeriod, originalFee],
  );

  useEffect(() => {
    if (!clearErrors) return;

    if (validateState === 'success') {
      clearErrors();
    }
  }, [validateState, clearErrors]);

  useEffect(() => {
    if (!trigger) return;
    if (similarPlan?.twMonthFee && convertedTwelveMonthFee) {
      //12개월 환산금액이 1,000원 이상 저렴한 조건
      const isSuccessPlanFeeValidation =
        similarPlan.twMonthFee >= convertedTwelveMonthFee + 1000;

      if (isSuccessPlanFeeValidation) {
        setValidateState('success');
        return;
      }

      trigger(['originalFee', 'discountFee', 'discountPeriod']);
      setValidateState('error');
      return;
    }
  }, [
    discountFee,
    discountPeriod,
    originalFee,
    convertedTwelveMonthFee,
    similarPlan,
    trigger,
  ]);

  return (
    <ConvertedTwelveMonthFeeContent
      variant={validateState}
      similarPlan={similarPlan}
      convertedTwelveMonthFee={convertedTwelveMonthFee}
    />
  );
}
