import {
  Button,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { authApiService } from '../../api/auth';
import { CookieConstants } from '../../common/constants';
import { setCookie, setToken } from '../../common/cookie';
import { useAuth } from '../../context/UserContext';
import { useSecureSession } from '../../hooks/useSecureSession';
import { AxiosError } from 'axios';
import { PARTNERS_ADMIN_API_ERROR_CODE } from '../../entity/api';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useState } from 'react';

const ImageBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  img: {
    width: '50%',
    mixBlendMode: theme.palette.mode === 'light' ? 'unset' : 'plus-lighter',
  },
}));

type FormInputs = {
  userId: string;
  password: string;
  otp: string;
};

export default function LoginPage() {
  const state = useLocation();
  const redirectUrl = new URLSearchParams(state.search).get('redirect-url');
  const { setUserInfo } = useAuth();
  const { register, handleSubmit, control } = useForm<FormInputs>({
    defaultValues: {
      userId: '',
      password: '',
      otp: '',
    },
  });
  const navigate = useNavigate();
  const { initializeSession } = useSecureSession();
  const [showOtp, setShowOtp] = useState(false);

  const handleLogin = async (data: any) => {
    try {
      // Status List 제거
      setCookie(CookieConstants.STATUS_OPTIONS, '');
      setCookie(CookieConstants.INTERNET_STATUS_OPTIONS, '');

      const res = await authApiService.login(
        data.userId,
        data.password,
        data.otp,
      );

      if (res.data.errorCode === 'ADMIN_ACCOUNT_NEEDS_OTP') {
        setShowOtp(true);
        alert(res.data.error);
        return;
      }

      const user = res.data.result;
      if (user) {
        // NOTE: 이전에 로그인된 세션이 있는 경우, 유저에게 해당 사실을 알린다.
        // https://themoyo.slack.com/archives/C064G84SV62/p1736406692236269
        // https://themoyo.slack.com/archives/C07EJFY2X3P/p1736753763368409
        if (user.previousSessionTerminated) {
          alert(
            '안전한 서비스 이용을 위해 한 번에 하나의 기기에서만 로그인할 수 있어요. \n기존에 로그인했던 기기는 자동으로 로그아웃됩니다.',
          );
        }

        const expires = new Date();
        expires.setTime(expires.getTime() + 12 * 60 * 60 * 1000);

        setToken(user.token, {
          path: '/',
          expires,
        });
        await setUserInfo({
          id: user.id,
          username: user.username,
        });
        initializeSession();

        navigate(decodeURIComponent(redirectUrl ?? '/'));
      }
    } catch (err: any) {
      /**
       * NOTE(hu.kang)
       * CREDENTIAL_EXPIRED - 비밀번호 만료인 경우
       * INVALID_PASSWORD_PATTERN - 비밀번호 유효성 검사 실패인 경우
       */
      if (err instanceof AxiosError) {
        if (
          (
            [
              'CREDENTIAL_EXPIRED',
              'INVALID_PASSWORD_PATTERN',
            ] as PARTNERS_ADMIN_API_ERROR_CODE[]
          ).includes(err.response?.data.errorCode ?? '')
        ) {
          alert(err.response?.data.error);
          navigate('/change-password');
          return;
        }
      }

      const errorMessage =
        err?.response?.data?.error ?? '알 수 없는 오류가 발생했습니다.';
      alert(errorMessage);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Grid
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Grid item xs={10} md={8} lg={6} xl={5}>
            <Paper
              component="form"
              elevation={5}
              sx={{
                padding: '28px',
              }}
              onSubmit={handleSubmit(handleLogin)}
            >
              <ImageBox>
                <img src="/moyo_partners.png" alt="모요 파트너스 로고" />
              </ImageBox>
              <TextField
                {...register('userId', {
                  required: true,
                })}
                fullWidth
                id="userId"
                label="아이디"
                variant="outlined"
                margin="normal"
              />
              <TextField
                {...register('password', {
                  required: true,
                })}
                fullWidth
                id="password"
                label="비밀번호"
                variant="outlined"
                margin="normal"
                type="password"
              />
              {showOtp ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    mt: 4,
                    mb: 4,
                  }}
                >
                  <Typography>OTP</Typography>
                  <Controller
                    control={control}
                    name="otp"
                    render={({ field }) => (
                      <MuiOtpInput {...field} length={6} />
                    )}
                  />
                </Box>
              ) : null}
              <Button
                sx={{ mt: 1 }}
                fullWidth
                variant="contained"
                size="large"
                type="submit"
              >
                로그인
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
