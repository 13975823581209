import { DataSpeedUnit, ICreatePlanDto, QosUnit } from '../../entity/plan';

import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { mapPlanDetailToCreatePlanDto } from '../../helpers/plan.helper';
import { useAuth } from '../../context/UserContext';
import { planApiService } from '../../api/plan';
import pbl from '../../pbl/pbl-service/pbl-service';
import { PlanV2 } from '../../entity/plan-v2';
import PlanEdit from './PlanEdit';
import { usePlanActionsAndPropsBySection } from './util/usePlanActionsAndPropsBySection';
import { planInfoPrefixService } from './logic/plan-info-prefix-service';

const initValues: ICreatePlanDto = {
  mobilePlanOperatorId: 0, // 통신사
  name: '', // 요금제 이름
  code: '', // 요금제 코드
  signupUrl: '', // 파트너사 가입 URL
  mobileSignupUrl: '', // 파트너사 모바일 가입 URL
  reserveTime: '', // 예약 시간
  isMoyoOnly: false, // 모요온리 여부

  specialGroupName: 'none', // 특별 그룹 이름
  network: '', // 네트워크 유형
  mno: '', // mno
  basicData: null, // 월 데이터 제공량
  basicDataUnit: DataSpeedUnit.GB, // 월 데이터 제공량 단위
  dailyData: null, // 일 데이터 제공량
  dailyDataUnit: DataSpeedUnit.GB, // 일 데이터 제공량 단위
  qos: null, // 데이터 소진 시 속도
  qosUnit: QosUnit.MBPS, // 데이터 소진 시 속도 단위
  message: null, // 문자 메시지 제공량
  voice: null, // 음성통화 제공량 (분)

  agreementPeriod: null, // 약정 기간
  discountFee: null, // 할인 가격
  originalFee: null, // 기본 가격
  discountPeriod: null, // 할인 기간

  dataSharingSupported: null, // 데이터 쉐어링 지원 여부
  microPaymentSupported: null, // 소액 결제 지원 여부
  internetCombineSupported: null, // 인터넷 결합 지원 여부
  additionalCallStatus: 'NOT_PROVIDED',

  tetheringStatus: 'NOT_PROVIDED',
};

interface PlanEditPageProps {
  isMoyoOnly: boolean;
}

export default function PlanEditPage({ isMoyoOnly }: PlanEditPageProps) {
  const { user } = useAuth();

  const [searchParams] = useSearchParams();
  const [initCreatePlanDto, setInitCreatePlanDto] = useState<ICreatePlanDto>();
  const [planDetail, setPlanDetail] = useState<PlanV2>();
  const planId = searchParams.get('planId');
  const isEdit = planId !== null;
  const operatorId = user?.company?.mobilePlanOperatorId;

  const formType = isEdit ? '수정' : '등록';
  const pageTitle = isMoyoOnly ? `모요온리 ${formType}` : `요금제 ${formType}`;

  const { handleComplete, handleValidateMoyoOnlyFee, planInfo } =
    usePlanActionsAndPropsBySection(isMoyoOnly);

  useEffect(() => {
    if (!initCreatePlanDto) return;
    if (!isEdit) {
      pbl('pageview', 'none', 'plan_create', {});
    } else if (initCreatePlanDto) {
      pbl('pageview', 'none', 'plan_edit', {
        eventProperties: {
          ...planDetail,
        },
      });
    }
  }, [isEdit, initCreatePlanDto]);

  useEffect(() => {
    if (isEdit) return;
    // 파트너사가 직접등록 시 해당 파트너사의 통신사 자동으로 채워줌
    setInitCreatePlanDto({
      ...initValues,
      mobilePlanOperatorId: operatorId ?? 0,
      isMoyoOnly,
    });
  }, [isEdit, operatorId]);

  useEffect(() => {
    if (!planId) return;
    void (async () => {
      const planDetailResponse = await planApiService.getPlanDetail(
        Number(planId),
      );
      const _planDetail = planDetailResponse.data.result;
      if (!_planDetail) {
        alert('요금제 정보를 조회하는데 실패했습니다.');
        return;
      }

      setPlanDetail(planDetailResponse.data.result);

      const mappedValues = mapPlanDetailToCreatePlanDto(_planDetail);

      setInitCreatePlanDto(planInfoPrefixService.removePrefix(mappedValues));
    })();
  }, []);

  if (!user || !initCreatePlanDto) return <></>;

  return (
    <PlanEdit
      pageTitle={pageTitle}
      createPlanDto={initCreatePlanDto}
      isEdit={isEdit}
      isMoyoOnly={isMoyoOnly}
      planId={planDetail?.meta.id}
      displayPlan={!!planDetail?.adminPlanMeta.display}
      planManagementType={planDetail?.meta.planManagementType}
      mobilePlanOperatorId={initCreatePlanDto?.mobilePlanOperatorId}
      planInfo={planInfo}
      onComplete={handleComplete}
      onValidateBeforePlanAction={handleValidateMoyoOnlyFee}
    />
  );
}
