import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { handleApi } from '../../../common/http_util';
import { orderApiService } from '../../../api/order';

// TODO: 주문 상세도 react query를 사용해서 invalidate 되게 하면 onSuccess를 지우기
export function useOrderPlanUpdate(onSuccess: () => void) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const planUpdateMutation = useMutation({
    mutationFn: ({
      orderId,
      planId,
      planName,
    }: {
      orderId: string;
      planId: number;
      planName: string;
    }) =>
      handleApi(
        () => orderApiService.updateOrder(orderId, planId, planName),
        navigate,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['plan', 'list'] });
      onSuccess();
    },
  });

  return {
    planUpdateMutation,
  };
}
