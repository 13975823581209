import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  FormContent,
  InputRow,
  InputWithToggleBox,
  Section,
  SectionHeader,
  SectionTitle,
} from '../styled';
import { InfoOutlined } from '@mui/icons-material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  DataSpeedUnit,
  ICreatePlanDto,
  QosUnit,
  SpecialGroupNameSelectList,
} from '../../../entity/plan';
import { NetworkList } from '../../../entity/network';
import { Mvnos } from '../../../entity/mvno';
import { useOptions } from '../../../context/OptionsContext';
import { isMoyoAdmin } from '../../../common/moyoActivationCollection';
import { useCallback, useEffect, useState } from 'react';
import { planApiService } from '../../../api/plan';
import { useAuth } from '../../../context/UserContext';
import { Mno } from '../../../entity/mno';

interface PlanSpecificSectionProps {
  isEdit: boolean;
}

const FREE_AMOUNT = 9999;

export const PlanSpecificSection = ({ isEdit }: PlanSpecificSectionProps) => {
  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext<ICreatePlanDto>();

  const { adminUser } = useOptions();
  const { user } = useAuth();

  const [availableMnos, setAvailableMnos] = useState<
    { label: string; value: Mno }[]
  >([
    { label: 'SKT', value: 'SKT' },
    { label: 'KT', value: 'KT' },
    { label: 'LGU', value: 'LGU' },
  ]);

  const validatePlanSpecAmount = (value: number) => {
    if (value > FREE_AMOUNT) {
      return `10,000 미만만 입력 가능해요.`;
    }
    return true;
  };

  const fetchAvailableMnos = useCallback(
    async (operatorId?: number) => {
      // 모요 어드민인 경우 모든 통신망을 선택할 수 있게함
      const mobilePlanOperatorId =
        isMoyoAdmin(adminUser) && operatorId
          ? operatorId
          : user?.company?.mobilePlanOperatorId ?? 0;
      const res = await planApiService.getPlanOperatorStatus(
        mobilePlanOperatorId,
      );

      setAvailableMnos(
        res.data.result?.supportingNetworkProviderList.map((network) => ({
          label: network,
          value: network,
        })) ?? [],
      );
    },
    [adminUser, user?.company?.mobilePlanOperatorId],
  );

  const handleBlurAmount =
    (name: keyof ICreatePlanDto) => (e: React.FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (typeof value === 'undefined' || value === '') {
        return;
      }

      setValue(name, Number(value), {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

  useEffect(() => {
    fetchAvailableMnos();
  }, [fetchAvailableMnos]);

  return (
    <Section>
      <SectionHeader>
        <SectionTitle variant="h5" mt={4}>
          요금제 스펙
        </SectionTitle>
        <Typography variant="body1">
          무제한인 경우, 999혹은 {FREE_AMOUNT}를 입력해 주세요. 제공하지 않으면
          0을 입력해 주세요.
        </Typography>
      </SectionHeader>
      {isEdit ? (
        <Alert
          style={{ margin: '16px 0' }}
          variant="outlined"
          icon={<InfoOutlined fontSize="inherit" />}
          severity="info"
        >
          스펙 정보는 요금제를 등록한 이후에는 수정할 수 없어요. 입력한 값이
          맞는지 꼼꼼히 확인해 주세요
        </Alert>
      ) : (
        <Alert
          style={{ margin: '16px 0' }}
          icon={<InfoOutlined fontSize="inherit" />}
          severity="info"
        >
          스펙 정보는 요금제를 등록한 이후에는 수정할 수 없어요. 입력한 값이
          맞는지 꼼꼼히 확인해 주세요
        </Alert>
      )}

      <FormContent>
        {isMoyoAdmin(adminUser) && (
          <InputRow>
            <FormControl fullWidth>
              <InputLabel id="mobilePlanOperator">통신사</InputLabel>
              <Controller
                name="mobilePlanOperatorId"
                control={control}
                defaultValue={undefined}
                rules={{
                  required: '통신사는 필수입니다',
                  validate: (value) => {
                    if (!value || value === 0) {
                      return '통신사는 필수입니다';
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    error={!!errors.mobilePlanOperatorId}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      fetchAvailableMnos(e.target.value as number);
                    }}
                    disabled={isEdit}
                    label="통신사"
                    required={!isEdit}
                    style={{ width: '265.3px' }}
                  >
                    <MenuItem value={0}>선택</MenuItem>
                    {Mvnos.map((group, index) => (
                      <MenuItem key={index} value={group.operatorId}>
                        {group.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </InputRow>
        )}

        <InputRow>
          <FormControl fullWidth>
            <InputLabel id="specialGroupName">요금제 종류</InputLabel>
            <Controller
              name="specialGroupName"
              control={control}
              defaultValue=""
              rules={{ required: '요금제 종류는 필수입니다' }}
              render={({ field }) => (
                <Select
                  {...field}
                  required={!isEdit}
                  error={!!errors.specialGroupName}
                  disabled={isEdit}
                  label="요금제 종류"
                >
                  {SpecialGroupNameSelectList.map((group, index) => (
                    <MenuItem key={index} value={group.key}>
                      {group.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="mno">통신망</InputLabel>
            <Controller
              name="mno"
              control={control}
              defaultValue=""
              rules={{ required: '통신망은 필수입니다' }}
              render={({ field }) => (
                <Select
                  {...field}
                  error={!!errors.mno}
                  disabled={isEdit}
                  required
                  label="통신망"
                >
                  {availableMnos.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="network">통신 기술</InputLabel>
            <Controller
              name="network"
              control={control}
              defaultValue=""
              rules={{ required: '통신 기술은 필수입니다' }}
              render={({ field }) => (
                <Select
                  disabled={isEdit}
                  {...field}
                  error={!!errors.network}
                  required
                  label="통신 기술"
                >
                  {NetworkList.map((group, index) => (
                    <MenuItem key={index} value={group.value}>
                      {group.value}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </InputRow>

        <InputRow>
          <FormControl fullWidth>
            <InputWithToggleBox>
              <TextField
                label="월 데이터 제공량"
                placeholder="숫자만 입력"
                {...register('basicData', {
                  required: '월 데이터 제공량은 필수입니다',
                  validate: (value) => validatePlanSpecAmount(value ?? 0),
                })}
                InputLabelProps={{ shrink: true }}
                error={!!errors.basicData}
                helperText={errors.basicData?.message}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: FREE_AMOUNT,
                    onKeyDown: (e) => e.key === '-' && e.preventDefault(),
                  },
                  'aria-required': true,
                }}
                disabled={isEdit}
                onBlur={handleBlurAmount('basicData')}
              />
              <Controller
                name="basicDataUnit"
                control={control}
                rules={{ required: isEdit ? false : '단위는 필수입니다' }}
                render={({ field, fieldState }) => (
                  <ToggleButtonGroup
                    {...field}
                    exclusive
                    disabled={isEdit}
                    aria-label="basicDataUnit"
                    onChange={(event, value) => field.onChange(value)}
                  >
                    <ToggleButton value={DataSpeedUnit.GB} aria-label="gb">
                      GB
                    </ToggleButton>
                    <ToggleButton value={DataSpeedUnit.MB} aria-label="mb">
                      MB
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
              {errors.basicDataUnit && (
                <Typography color="error">
                  {errors.basicDataUnit.message}
                </Typography>
              )}
            </InputWithToggleBox>
          </FormControl>
          <FormControl fullWidth disabled={isEdit}>
            <InputWithToggleBox>
              <TextField
                label="일 데이터 제공량"
                placeholder="숫자만 입력"
                type="number"
                InputLabelProps={{ shrink: true }}
                disabled={isEdit}
                {...register('dailyData', {
                  required: '일 데이터 제공량은 필수입니다',
                  validate: (value) => validatePlanSpecAmount(value ?? 0),
                })}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: FREE_AMOUNT,
                    onKeyDown: (e) => e.key === '-' && e.preventDefault(),
                  },
                  'aria-required': true,
                }}
                error={!!errors.dailyData}
                helperText={errors.dailyData?.message}
                fullWidth
                onBlur={handleBlurAmount('dailyData')}
              />
              <Controller
                name="dailyDataUnit"
                control={control}
                rules={{ required: '단위는 필수입니다' }}
                render={({ field }) => (
                  <ToggleButtonGroup
                    {...field}
                    disabled={isEdit}
                    exclusive
                    aria-label="dailyDataUnit"
                    onChange={(event, value) => field.onChange(value)}
                  >
                    <ToggleButton value={DataSpeedUnit.GB} aria-label="gb">
                      GB
                    </ToggleButton>
                    <ToggleButton value={DataSpeedUnit.MB} aria-label="mb">
                      MB
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </InputWithToggleBox>
          </FormControl>
          <FormControl fullWidth>
            <InputWithToggleBox>
              <TextField
                label="소진 시 속도"
                {...register('qos', {
                  required: '소진 시 속도는 필수입니다',
                  validate: (value) => validatePlanSpecAmount(value ?? 0),
                })}
                placeholder="숫자만 입력"
                InputLabelProps={{ shrink: true }}
                type="number"
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: FREE_AMOUNT,
                    onKeyDown: (e) => e.key === '-' && e.preventDefault(),
                  },
                  'aria-required': true,
                }}
                disabled={isEdit}
                error={!!errors.qos}
                helperText={errors.qos?.message}
                onBlur={handleBlurAmount('qos')}
              />

              <Controller
                name="qosUnit"
                control={control}
                rules={{ required: '단위는 필수입니다' }}
                render={({ field }) => (
                  <ToggleButtonGroup
                    {...field}
                    exclusive
                    aria-label="text alignment"
                    disabled={isEdit}
                  >
                    <ToggleButton
                      value={QosUnit.MBPS}
                      aria-label="left aligned"
                    >
                      MBPS
                    </ToggleButton>
                    <ToggleButton value={QosUnit.KBPS} aria-label="centered">
                      KBPS
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </InputWithToggleBox>
          </FormControl>
        </InputRow>

        <InputRow>
          <TextField
            label="문자 제공량"
            placeholder="숫자만 입력"
            style={{ width: '265.3px' }}
            InputLabelProps={{ shrink: true }}
            type="number"
            disabled={isEdit}
            {...register('message', {
              required: '문자 제공량은 필수입니다',
              validate: (value) => validatePlanSpecAmount(value ?? 0),
            })}
            error={!!errors.message}
            helperText={errors.message?.message}
            InputProps={{
              endAdornment: <Typography variant="body1">개</Typography>,
              inputProps: {
                min: 0,
                max: FREE_AMOUNT,
                onKeyDown: (e) => e.key === '-' && e.preventDefault(),
              },
            }}
            onBlur={handleBlurAmount('message')}
          />
          <TextField
            label="통화 제공량"
            placeholder="숫자만 입력"
            style={{ width: '265.3px' }}
            InputLabelProps={{ shrink: true }}
            type="number"
            disabled={isEdit}
            {...register('voice', {
              required: '통화 제공량은 필수입니다',
              validate: (value) => validatePlanSpecAmount(value ?? 0),
            })}
            error={!!errors.voice}
            helperText={errors.voice?.message}
            InputProps={{
              endAdornment: <Typography variant="body1">분</Typography>,
              inputProps: {
                min: 0,
                max: FREE_AMOUNT,
                onKeyDown: (e) => e.key === '-' && e.preventDefault(),
              },
            }}
            onBlur={handleBlurAmount('voice')}
          />
        </InputRow>
      </FormContent>
    </Section>
  );
};
