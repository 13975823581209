import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import type {
  MnoOperationPolicyByMoyoList,
  OperationPolicy,
} from '../../types/mobile-plan-operator-admin-types';
import { Controller, useFormContext } from 'react-hook-form';
import CustomMvnoInfoToggleButtonGroup from '../Common/CustomMvnoInfoToggleButtonGroup';
import NumericFormatComponent from '../../../../shared/components/custom-mui/NumericFormatComponent';
import { MvnoInfoItemBody, MvnoInfoItemRow } from '../Common/MvnoInfoItemFrame';
import { Mno } from '../../../../entity/mno';
import { convertStringToMno } from '../../../../common/order-util';
import { PaymentDateByMnoService } from './payment-service';

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      overflow: 'auto',
    },
  },
};

const MnoInfoOperationPolicyEdit: FC<{
  mno: Mno;
  operationPolicy: OperationPolicy;
}> = ({ mno, operationPolicy }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<MnoOperationPolicyByMoyoList>();
  const title = convertStringToMno(mno);
  const paymentDateByMnoService = new PaymentDateByMnoService(mno);
  const cardPaymentDates = paymentDateByMnoService.getCardPaymentDates();
  const accountPaymentDates = paymentDateByMnoService.getAccountPaymentDates();

  return (
    <MvnoInfoItemBody gap={3}>
      <Box>
        <Typography variant="body1" color="text.primary" fontWeight={700}>
          {title}망
        </Typography>
      </Box>
      <MvnoInfoItemBody gap={7}>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            defaultValue={operationPolicy.postActivation}
            name={`${mno}.postActivation`}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="신규가입 개통 방식"
                items={[
                  {
                    label: '선개통',
                    value: false,
                  },
                  {
                    label: '후개통',
                    value: true,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={operationPolicy.dailyDiscount}
            name={`${mno}.dailyDiscount`}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="요금제 할인기간 계산 방법"
                items={[
                  {
                    label: '일할',
                    value: true,
                  },
                  {
                    label: '월할',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={operationPolicy.allowPaymentByOther}
            name={`${mno}.allowPaymentByOther`}
            render={({ field }) => (
              <CustomMvnoInfoToggleButtonGroup
                label="타인납부"
                items={[
                  {
                    label: '지원',
                    value: true,
                  },
                  {
                    label: '미지원',
                    value: false,
                  },
                ]}
                toggleButtonGroupProps={{ ...field }}
              />
            )}
          />
        </MvnoInfoItemRow>
        <MvnoInfoItemRow gap={3}>
          <Controller
            control={control}
            name={`${mno}.registerChangeFee`}
            defaultValue={operationPolicy.registerChangeFee}
            rules={{
              required: '번호이동 수수료를 입력해주세요.',
            }}
            render={({ field }) => (
              <TextField
                InputLabelProps={{
                  required: false,
                }}
                error={!!errors[mno]?.registerChangeFee?.message}
                helperText={errors[mno]?.registerChangeFee?.message}
                {...field}
                fullWidth
                inputMode="numeric"
                label="번호이동 수수료"
                InputProps={{
                  endAdornment: '원',
                  inputComponent: NumericFormatComponent,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`${mno}.billingDateList.card.billingDay`}
            defaultValue={operationPolicy.billingDateList?.card.billingDay}
            rules={{
              required: '카드 결제일을 입력해주세요.',
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="card-billingDay" required={false}>
                  카드 결제일
                </InputLabel>
                <Select
                  label="카드 결제일"
                  MenuProps={menuProps}
                  error={
                    !!errors[mno]?.billingDateList?.card?.billingDay?.message
                  }
                  {...field}
                >
                  {cardPaymentDates.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[mno]?.billingDateList?.card?.billingDay?.message && (
                  <FormHelperText error>
                    {errors[mno]?.billingDateList?.card?.billingDay?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name={`${mno}.billingDateList.account.billingDay`}
            defaultValue={operationPolicy.billingDateList?.account.billingDay}
            rules={{
              required: '자동이체 결제일을 입력해주세요.',
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="account-billingDay" required={false}>
                  자동이체 결제일
                </InputLabel>
                <Select
                  MenuProps={menuProps}
                  label="자동이체 결제일"
                  error={
                    !!errors[mno]?.billingDateList?.account?.billingDay?.message
                  }
                  {...field}
                >
                  {accountPaymentDates.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[mno]?.billingDateList?.account?.billingDay?.message && (
                  <FormHelperText error>
                    {errors[mno]?.billingDateList?.account?.billingDay?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </MvnoInfoItemRow>
      </MvnoInfoItemBody>
    </MvnoInfoItemBody>
  );
};

const MvnoInfoOperationPolicyByMoyoEdit: FC<MnoOperationPolicyByMoyoList> = (
  mnoOperationPolicyByMoyoList,
) => {
  return (
    <MvnoInfoItemBody gap={6}>
      {Object.entries(mnoOperationPolicyByMoyoList).map(
        ([key, mnoOperationPolicyByMoyo]) => (
          <MnoInfoOperationPolicyEdit
            operationPolicy={mnoOperationPolicyByMoyo}
            key={key}
            mno={key as Mno}
          />
        ),
      )}
    </MvnoInfoItemBody>
  );
};

export default MvnoInfoOperationPolicyByMoyoEdit;
