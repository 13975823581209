import { useNavigate } from 'react-router-dom';
import { MoyoOnlyValidateFeeResponse } from '../../../entity/moyo-only';
import { ICreatePlanDto } from '../../../entity/plan';
import { useState } from 'react';
import { PlanMetaV2 } from '../../../entity/plan-v2';
import { moyoOnlyApiService } from '../../../api/moyo-only';
import { onCompleteResponse } from '../PlanEdit';
import { UseFormSetError } from 'react-hook-form';

export function usePlanActions() {
  const [similarPlan, setSimilarPlan] = useState<PlanMetaV2 | null>(null);
  const navigate = useNavigate();

  const handleValidateError = (
    response: MoyoOnlyValidateFeeResponse | undefined,
    setError: UseFormSetError<ICreatePlanDto>,
  ) => {
    if (response?.similarPlan) {
      setSimilarPlan(response.similarPlan);
      setError('originalFee', {});
      setError('discountFee', {});
      setError('discountPeriod', {});
      //에러 발생 시 해당 Input 위치로 이동
      window.location.href = '#plan-info-section';
      return;
    }

    setSimilarPlan(null);
  };

  const handleValidateMoyoOnlyFee = async (
    data: ICreatePlanDto,
    setError: UseFormSetError<ICreatePlanDto>,
  ) => {
    const response = await moyoOnlyApiService.validateMoyoOnlyPlanFee({ data });
    if (!response.data.result) return false;

    const isValidateSuccess = response.data.result.success;
    if (!isValidateSuccess) {
      handleValidateError(response.data.result, setError);
    }
    return isValidateSuccess;
  };

  const handleComplete = (
    response: onCompleteResponse,
    isMoyoOnly: boolean,
  ) => {
    const planId = response?.data?.result?.id;
    const errorMessage = response?.data?.error;

    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    const url = isMoyoOnly ? '/plans/moyo-only' : '/plans';
    navigate(`${url}/${planId}`);
  };

  return { handleValidateMoyoOnlyFee, handleComplete, similarPlan };
}
