import { Mno } from '../../../../entity/mno';
import {
  ACCOUNT_PAYMENT_DATE_SELECT_ITEMS,
  CARD_PAYMENT_DATE_SELECT_ITEMS,
} from './constants';
import { PaymentDateSelectItem } from './type';

export class PaymentDateByMnoService {
  constructor(private mno: Mno) {}

  getCardPaymentDates(): PaymentDateSelectItem[] {
    if (!this.mno) return [];
    return CARD_PAYMENT_DATE_SELECT_ITEMS[this.mno];
  }

  getAccountPaymentDates(): PaymentDateSelectItem[] {
    if (!this.mno) return [];
    return ACCOUNT_PAYMENT_DATE_SELECT_ITEMS[this.mno];
  }
}
